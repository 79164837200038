const initial_state = {
  requestCount: 0,
};

export const loaderReducer = (state = initial_state, action) => {
  if (action.type.indexOf('no-loader') >= 0) return state;

  if (
    (action.type.indexOf('FETCH') >= 0 ||
      action.type.indexOf('PATCH') >= 0 ||
      action.type.indexOf('POST') >= 0 ||
      action.type.indexOf('DELETE') >= 0 ||
      action.type.indexOf('PASSWORD_RESET') >= 0 ||
      action.type.indexOf('UPLOAD') >= 0) &&
    action.payload
  ) {
    let count = state.requestCount;
    if (action.payload.toLowerCase() === 'fetching') count++;

    if (action.payload.toLowerCase() === 'error' || action.payload.toLowerCase() === 'done')
      count = count > 0 ? --count : 0;

    return { ...state, requestCount: count };
  }

  let count = state.requestCount;
  if (action.type.indexOf('pending') >= 0) {
    count++;
  } else if (action.type.indexOf('fulfilled') >= 0 || action.type.indexOf('rejected') >= 0) {
    count = count > 0 ? --count : 0;
  }

  return { ...state, requestCount: count };
};
