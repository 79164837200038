import React, { useEffect, useState } from 'react';
import './SMSLoggerLayout.scss';
import { NavLink, Route, Switch } from 'react-router-dom';
import { SMSLoggerRoutes } from '../../routes/SMSLoggerRoutes';
import { Card, CardBody, Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import PasswordInput from '../../components/Form/PasswordInput/PasswordInput';
import axios from 'axios';
import NetworkService from '../../utils/network.service';
import SnackbarUtils from '../../utils/SnackbarUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setSMSLoggerAccessToken } from '../../redux/smsLogger/smsLogger.action';
import { RootState } from '../../redux/Store';
import { setLoginPostStatus } from '../../features/auth/auth.slice';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';

const SMSLoggerLayout = (): JSX.Element => {
  // STATE
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);

  // REDUX
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state: RootState) => state.smsLoggerReducer);

  const handleOnPasswordChange = e => {
    setPassword(e.target.value);
    if (showError) setShowError(false);
  };

  const checkPassword = () => {
    if (password) {
      dispatch(setLoginPostStatus(FETCH_STATUS.PENDING));
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/login/`, {
          username: 'sms-logger@telcloud.io',
          password: password,
        })
        .then(response => {
          dispatch(setLoginPostStatus(FETCH_STATUS.FULFILLED));
          localStorage.setItem('sms-logger-token', response.data.access_token);
          NetworkService.setupDefaultHeaders(response.data.access_token);
          dispatch(setSMSLoggerAccessToken(response.data.access_token));
          SnackbarUtils.success('You\'re logged in!');
        })
        .catch(error => {
          dispatch(setLoginPostStatus(FETCH_STATUS.REJECTED));
          SnackbarUtils.error(
            (error && error.response && error.response.data && error.response.data.message) || 'Error during login!'
          );
        });
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('sms-logger-token') || null;
    if (accessToken) {
      NetworkService.setupDefaultHeaders(accessToken);
      dispatch(setSMSLoggerAccessToken(accessToken));
    }
  }, []);

  return (
    <main className={'sms-logger-container'}>
      <Row>
        <Col>
          <Card className={'mx-3 my-2'}>
            <CardBody>
              <NavLink
                className={'sms-logger-link'}
                to='/sms-logger'
                isActive={match => {
                  return !!match?.isExact;
                }}
              >
                Dashboard
              </NavLink>
              <NavLink
                className={'ml-2 sms-logger-link'}
                to='/sms-logger/sims'
                isActive={match => {
                  if (match?.url.includes('/sms-logger/sims')) {
                    return true;
                  }
                  return !!match?.isExact;
                }}
              >
                SIMs
              </NavLink>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {accessToken ? (
        <Switch>
          {SMSLoggerRoutes.map(
            (
              prop: {
                path: string;
                name: string;
                icon: string;
                component: React.ComponentType;
              },
              key
            ) => {
              return <Route path={prop.path} component={prop.component} key={key} />;
            }
          )}
        </Switch>
      ) : (
        <Row>
          <Col>
            <Card className={'mx-3 my-2'} style={{ minHeight: '80vh' }}>
              <CardBody className={'d-flex justify-content-center align-items-center h-100'}>
                <div className={'h-100'}>
                  <FormGroup>
                    <Label>Enter the password to view the content</Label>

                    <PasswordInput invalid={showError} onChange={handleOnPasswordChange} autoComplete='new-password' />
                    {showError && <FormFeedback>Wrong password</FormFeedback>}
                  </FormGroup>
                  <button type='button' className='btn btn-block btn-primary' onClick={checkPassword}>
                    Check password
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </main>
  );
};
export default SMSLoggerLayout;
