import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { PowerCycleDetailsType, PowerCycleListItemType } from '../../types/power-cycle/power-cycle.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import {
  deletePowerCycleDetails,
  getPowerCycleDetails,
  getPowerCycleDevicesList,
  getPowerCyclesList,
  getPowerCycleTypesList,
  patchPowerCycleChannels,
  patchPowerCycleDetails,
  postPowerCycleChannelCommand,
  postPowerCycleDetails,
  postPowerCycles,
  postPowerCycleStatus,
} from './powerCycles.action';
import snackbarUtils from '../../utils/SnackbarUtils';
import { PowerCycleTypeType } from '../../types/power-cycle/power-cycle-type/power-cycle-type.type';

export interface PowerCyclesState {
  powerCyclesList: null | PaginationType<PowerCycleListItemType>;
  powerCyclesListFetchStatus: FETCH_STATUS;
  powerCycleTypesList: null | Array<Pick<PowerCycleTypeType, 'name' | 'id' | 'numberOfChannels'>>;
  powerCycleTypesListFetchStatus: FETCH_STATUS;
  powerCyclesRouteParams: RouteParamsType;
  powerCycleDetails: null | PowerCycleDetailsType;
  powerCycleDetailsFetchStatus: FETCH_STATUS;
  powerCycleDetailsPostStatus: FETCH_STATUS;
  powerCycleDetailsPatchStatus: FETCH_STATUS;
  powerCycleDetailsDeleteStatus: FETCH_STATUS;
  powerCyclesPostStatus: FETCH_STATUS;
  powerCyclesBulk: null | Array<{ sn: string; password: string }>;
  powerCycleChannelsPatchStatus: FETCH_STATUS;
  powerCycleDevicesList: null | {
    atas: Array<{ id: string; name: string }>;
    acs_atas: Array<{ id: string; name: string }>;
    routers: Array<{ id: string; name: string }>;
    dialers: Array<{ id: string; name: string }>;
    batteries: Array<{ id: string; name: string }>;
  };
  powerCycleDevicesListFetchStatus: FETCH_STATUS;
  powerCycleChannelCommandPostStatus: FETCH_STATUS;
  powerCycleStatusPostStatus: FETCH_STATUS;
}

export const initialPowerCyclesRouteParams = {
  page: 1,
  limit: 10,
  order: '-power_cycle.id',
  warehouse_id: '',
  q: '',
  equipment_status: 'all',
  in_service: 'all',
  country_id: '',
  country_category: '',
};

const INIT_STATE: PowerCyclesState = {
  powerCyclesRouteParams: initialPowerCyclesRouteParams,
  powerCyclesList: null,
  powerCyclesListFetchStatus: FETCH_STATUS.NULL,
  powerCycleDetails: null,
  powerCycleDetailsFetchStatus: FETCH_STATUS.NULL,
  powerCycleDetailsPostStatus: FETCH_STATUS.NULL,
  powerCycleDetailsPatchStatus: FETCH_STATUS.NULL,
  powerCycleDetailsDeleteStatus: FETCH_STATUS.NULL,
  powerCyclesPostStatus: FETCH_STATUS.NULL,
  powerCyclesBulk: null,
  powerCycleChannelsPatchStatus: FETCH_STATUS.NULL,
  powerCycleDevicesList: null,
  powerCycleDevicesListFetchStatus: FETCH_STATUS.NULL,
  powerCycleChannelCommandPostStatus: FETCH_STATUS.NULL,
  powerCycleStatusPostStatus: FETCH_STATUS.NULL,
  powerCycleTypesList: null,
  powerCycleTypesListFetchStatus: FETCH_STATUS.NULL,
};

export const powerCyclesSlice = createSlice({
  name: 'powerCycles',
  initialState: INIT_STATE,
  reducers: {
    setPowerCyclesList: (state, action) => {
      state.powerCyclesList = action.payload;
    },
    setPowerCyclesListFetchStatus: (state, action) => {
      state.powerCyclesListFetchStatus = action.payload;
    },
    setPowerCycleDetails: (state, action) => {
      state.powerCycleDetails = action.payload;
    },
    setPowerCycleDetailsFetchStatus: (state, action) => {
      state.powerCycleDetailsFetchStatus = action.payload;
    },
    setPowerCycleDetailsPostStatus: (state, action) => {
      state.powerCycleDetailsPostStatus = action.payload;
    },
    setPowerCycleChannelsPatchStatus: (state, action) => {
      state.powerCycleChannelsPatchStatus = action.payload;
    },
    setPowerCycleDetailsPatchStatus: (state, action) => {
      state.powerCycleDetailsPatchStatus = action.payload;
    },
    setPowerCycleDetailsDeleteStatus: (state, action) => {
      state.powerCycleDetailsDeleteStatus = action.payload;
    },
    setPowerCyclesBulk: (state, action) => {
      state.powerCyclesBulk = action.payload;
    },
    setPowerCyclesPostStatus: (state, action) => {
      state.powerCyclesPostStatus = action.payload;
    },
    setPowerCycleDevicesList: (state, action) => {
      state.powerCycleDevicesList = action.payload;
    },
    setPowerCycleDevicesListFetchStatus: (state, action) => {
      state.powerCycleDevicesListFetchStatus = action.payload;
    },
    setPowerCycleChannelCommandPostStatus: (state, action) => {
      state.powerCycleChannelCommandPostStatus = action.payload;
    },
    setPowerCycleStatusPostStatus: (state, action) => {
      state.powerCycleStatusPostStatus = action.payload;
    },
    setPowerCycleTypesList: (state, action) => {
      state.powerCycleTypesList = action.payload;
    },
    setPowerCycleTypesListFetchStatus: (state, action) => {
      state.powerCycleTypesListFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'powerCycles') {
        state.powerCyclesRouteParams = { ...state.powerCyclesRouteParams, ...payload.data };
      }
    });

    // CREATE POWER_CYCLES DETAILS
    builder.addCase(postPowerCycles.pending, state => {
      state.powerCyclesPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postPowerCycles.rejected, (state, action) => {
      state.powerCyclesPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postPowerCycles.fulfilled, state => {
      state.powerCyclesPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PowerCycles created successfully');
    });

    // CREATE POWER_CYCLES DETAILS
    builder.addCase(postPowerCycleDetails.pending, state => {
      state.powerCycleDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postPowerCycleDetails.rejected, (state, action) => {
      state.powerCycleDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postPowerCycleDetails.fulfilled, state => {
      state.powerCycleDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PowerCycle created successfully');
    });

    // GET POWER_CYCLES LIST
    builder.addCase(getPowerCyclesList.pending, state => {
      state.powerCyclesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPowerCyclesList.rejected, (state, action) => {
      state.powerCyclesListFetchStatus = FETCH_STATUS.REJECTED;
      state.powerCyclesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPowerCyclesList.fulfilled, (state, action) => {
      state.powerCyclesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.powerCyclesList = action.payload;
    });

    // GET POWER_CYCLES DETAILS
    builder.addCase(getPowerCycleDetails.pending, state => {
      state.powerCycleDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPowerCycleDetails.rejected, (state, action) => {
      state.powerCycleDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.powerCycleDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPowerCycleDetails.fulfilled, (state, action) => {
      state.powerCycleDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.powerCycleDetails = action.payload;
    });

    // PATCH POWER_CYCLES DETAILS
    builder.addCase(patchPowerCycleDetails.pending, state => {
      state.powerCycleDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchPowerCycleDetails.rejected, (state, action) => {
      state.powerCycleDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchPowerCycleDetails.fulfilled, state => {
      state.powerCycleDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PowerCycle updated successfully');
    });

    // PATCH POWER_CYCLES CHANNELS DETAILS
    builder.addCase(patchPowerCycleChannels.pending, state => {
      state.powerCycleChannelsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchPowerCycleChannels.rejected, (state, action) => {
      state.powerCycleChannelsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchPowerCycleChannels.fulfilled, state => {
      state.powerCycleChannelsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PowerCycle channels updated successfully');
    });

    // DELETE POWER_CYCLES DETAILS
    builder.addCase(deletePowerCycleDetails.pending, state => {
      state.powerCycleDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deletePowerCycleDetails.rejected, (state, action) => {
      state.powerCycleDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deletePowerCycleDetails.fulfilled, state => {
      state.powerCycleDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PowerCycle deleted successfully');
    });

    // GET POWER_CYCLES DEVICES
    builder.addCase(getPowerCycleDevicesList.pending, state => {
      state.powerCycleDevicesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPowerCycleDevicesList.rejected, (state, action) => {
      state.powerCycleDevicesListFetchStatus = FETCH_STATUS.REJECTED;
      state.powerCycleDevicesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPowerCycleDevicesList.fulfilled, (state, action) => {
      state.powerCycleDevicesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.powerCycleDevicesList = action.payload;
    });

    // POST POWER_CYCLES CHANNEL COMMAND
    builder.addCase(postPowerCycleChannelCommand.pending, state => {
      state.powerCycleChannelCommandPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postPowerCycleChannelCommand.rejected, (state, action) => {
      state.powerCycleChannelCommandPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postPowerCycleChannelCommand.fulfilled, state => {
      state.powerCycleChannelCommandPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PowerCycle channel command sent successfully');
    });

    // POST POWER_CYCLES STATUS
    builder.addCase(postPowerCycleStatus.pending, state => {
      state.powerCycleStatusPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postPowerCycleStatus.rejected, (state, action) => {
      state.powerCycleStatusPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postPowerCycleStatus.fulfilled, state => {
      state.powerCycleStatusPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PowerCycle status updated successfully');
    });

    // GET POWER_CYCLES TYPES LIST
    builder.addCase(getPowerCycleTypesList.pending, state => {
      state.powerCycleTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPowerCycleTypesList.rejected, (state, action) => {
      state.powerCycleTypesListFetchStatus = FETCH_STATUS.REJECTED;
      state.powerCycleTypesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPowerCycleTypesList.fulfilled, (state, action) => {
      state.powerCycleTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.powerCycleTypesList = action.payload;
    });
  },
});

export const {
  setPowerCycleDetailsDeleteStatus,
  setPowerCycleDetailsFetchStatus,
  setPowerCycleDetailsPatchStatus,
  setPowerCycleDetailsPostStatus,
  setPowerCyclesBulk,
  setPowerCyclesListFetchStatus,
  setPowerCyclesPostStatus,
  setPowerCycleDetails,
  setPowerCyclesList,
  setPowerCycleChannelsPatchStatus,
  setPowerCycleDevicesListFetchStatus,
  setPowerCycleDevicesList,
  setPowerCycleChannelCommandPostStatus,
  setPowerCycleStatusPostStatus,
  setPowerCycleTypesListFetchStatus,
  setPowerCycleTypesList,
} = powerCyclesSlice.actions;

export default powerCyclesSlice.reducer;
