import {
  FETCH_SERVICE_TYPES_DETAILS_STATUS,
  FETCH_SERVICE_TYPES_LIST_STATUS,
  SET_SERVICE_TYPES_DETAILS,
  SET_SERVICE_TYPES_LIST,
  SET_ROUTE_PARAMS,
  SET_SERVICE_TYPES_OVERRIDE,
  FETCH_SERVICE_TYPES_OVERRIDE_STATUS,
  UPLOAD_SERVICE_TYPES_OVERRIDE_STATUS,
} from '../constants';

export const initialServiceTypesRouteParams = {
  page: 1,
  limit: 10,
  order: '-tc_service_type.id',
  q: '',
  category: '',
};

const INIT_STATE = {
  serviceTypesList: [],
  serviceTypesListFetchStatus: null,
  serviceTypesDetails: null,
  serviceTypesDetailsFetchStatus: null,
  routeParams: initialServiceTypesRouteParams,
  serviceTypesOverrides: null,
  serviceTypesOverridesFetchStatus: null,
  serviceTypesOverridesUploadStatus: null,
};

export const serviceTypesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SERVICE_TYPES_LIST:
      return { ...state, serviceTypesList: action.payload };
    case FETCH_SERVICE_TYPES_LIST_STATUS:
      return { ...state, serviceTypesListFetchStatus: action.payload };
    case SET_SERVICE_TYPES_DETAILS:
      return { ...state, serviceTypesDetails: action.payload };
    case FETCH_SERVICE_TYPES_DETAILS_STATUS:
      return { ...state, serviceTypesDetailsFetchStatus: action.payload };
    case SET_SERVICE_TYPES_OVERRIDE:
      return { ...state, serviceTypesOverrides: action.payload };
    case FETCH_SERVICE_TYPES_OVERRIDE_STATUS:
      return { ...state, serviceTypesOverridesFetchStatus: action.payload };
    case UPLOAD_SERVICE_TYPES_OVERRIDE_STATUS:
      return { ...state, serviceTypesOverridesUploadStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'serviceTypes') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
