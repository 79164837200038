import { createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  getActiveDIDList,
  getAvailableDIDList,
  getAvailablePBXs,
  getRateCentersList,
  getStatesList,
  postBuyDIDs,
  postBYOPBX,
} from './didManager.action';
import { PBXType } from '../../types/pbx/pbx.type';

export interface DIDManagerState {
  statesList: null | Array<{ name: string; options: Array<{ name: string; id: string | number }> }>;
  statesListFetchStatus: FETCH_STATUS;
  rateCentersList: null | Array<{ name: string; id: string }>;
  rateCentersListFetchStatus: FETCH_STATUS;
  availableDIDList: null | Array<{ did: string }>;
  availableDIDListFetchStatus: FETCH_STATUS;
  activeDIDList: null | Array<{ did: string }>;
  activeDIDListFetchStatus: FETCH_STATUS;
  buyDidsPostStatus: FETCH_STATUS;
  byoPBXPostStatus: FETCH_STATUS;
  availablePBXsList: null | Array<
    Pick<PBXType, 'id' | 'description' | 'sip_server1'> & {
      system_pbx_location: { id: number };
      telco_instance: { id: number };
      pbx_system_instance: { id: number };
      default?: boolean;
    }
  >;
  availablePBXsListFetchStatus: FETCH_STATUS;
}

const INIT_STATE: DIDManagerState = {
  statesList: null,
  statesListFetchStatus: FETCH_STATUS.NULL,
  rateCentersList: null,
  rateCentersListFetchStatus: FETCH_STATUS.NULL,
  availableDIDList: null,
  availableDIDListFetchStatus: FETCH_STATUS.NULL,
  buyDidsPostStatus: FETCH_STATUS.NULL,
  byoPBXPostStatus: FETCH_STATUS.NULL,
  availablePBXsList: null,
  availablePBXsListFetchStatus: FETCH_STATUS.NULL,
  activeDIDListFetchStatus: FETCH_STATUS.NULL,
  activeDIDList: null,
};

const didManagerSlice = createSlice({
  name: 'did-manager',
  initialState: INIT_STATE,
  reducers: {
    setStatesList: (state, action) => {
      state.statesList = action.payload;
    },
    setStatesListFetchStatus: (state, action) => {
      state.statesListFetchStatus = action.payload;
    },
    setRateCentersList: (state, action) => {
      state.rateCentersList = action.payload;
    },
    setRateCentersListFetchStatus: (state, action) => {
      state.rateCentersListFetchStatus = action.payload;
    },
    setAvailableDIDList: (state, action) => {
      state.availableDIDList = action.payload;
    },
    setAvailableDIDListFetchStatus: (state, action) => {
      state.availableDIDListFetchStatus = action.payload;
    },
    setBuyDidsPostStatus: (state, action) => {
      state.buyDidsPostStatus = action.payload;
    },
    setBYOPBXPostStatus: (state, action) => {
      state.byoPBXPostStatus = action.payload;
    },
    setAvailablePBXsList: (state, action) => {
      state.availablePBXsList = action.payload;
    },
    setAvailablePBXsListFetchStatus: (state, action) => {
      state.availablePBXsListFetchStatus = action.payload;
    },
    setActiveDIDList: (state, action) => {
      state.activeDIDList = action.payload;
    },
    setActiveDIDListFetchStatus: (state, action) => {
      state.activeDIDListFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    // GET STATES LIST
    builder.addCase(getStatesList.pending, state => {
      state.statesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getStatesList.rejected, (state, action) => {
      state.statesListFetchStatus = FETCH_STATUS.REJECTED;
      state.statesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getStatesList.fulfilled, (state, action) => {
      state.statesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.statesList = action.payload;
    });

    // GET RATE CENTERS LIST
    builder.addCase(getRateCentersList.pending, state => {
      state.rateCentersListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRateCentersList.rejected, (state, action) => {
      state.rateCentersListFetchStatus = FETCH_STATUS.REJECTED;
      state.rateCentersList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getRateCentersList.fulfilled, (state, action) => {
      state.rateCentersListFetchStatus = FETCH_STATUS.FULFILLED;
      state.rateCentersList = action.payload;
    });

    // GET AVAILABLE DID LIST
    builder.addCase(getAvailableDIDList.pending, state => {
      state.availableDIDListFetchStatus = FETCH_STATUS.PENDING;
      state.activeDIDListFetchStatus = FETCH_STATUS.NULL;
      state.activeDIDList = null;
    });
    builder.addCase(getAvailableDIDList.rejected, (state, action) => {
      state.availableDIDListFetchStatus = FETCH_STATUS.REJECTED;
      state.availableDIDList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getAvailableDIDList.fulfilled, (state, action) => {
      state.availableDIDListFetchStatus = FETCH_STATUS.FULFILLED;
      state.availableDIDList = action.payload;
    });

    // BUY DID
    builder.addCase(postBuyDIDs.pending, state => {
      state.buyDidsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postBuyDIDs.rejected, (state, action) => {
      state.buyDidsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postBuyDIDs.fulfilled, state => {
      state.buyDidsPostStatus = FETCH_STATUS.FULFILLED;
    });

    // BYO PBX
    builder.addCase(postBYOPBX.pending, state => {
      state.byoPBXPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postBYOPBX.rejected, (state, action) => {
      state.byoPBXPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postBYOPBX.fulfilled, state => {
      state.byoPBXPostStatus = FETCH_STATUS.FULFILLED;
    });

    // GET AVAILABLE PBXs LIST
    builder.addCase(getAvailablePBXs.pending, state => {
      state.availablePBXsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getAvailablePBXs.rejected, (state, action) => {
      state.availablePBXsListFetchStatus = FETCH_STATUS.REJECTED;
      state.availablePBXsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getAvailablePBXs.fulfilled, (state, action) => {
      state.availablePBXsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.availablePBXsList = action.payload;
    });

    // GET ACTIVE DID LIST
    builder.addCase(getActiveDIDList.pending, state => {
      state.activeDIDListFetchStatus = FETCH_STATUS.PENDING;
      state.availableDIDListFetchStatus = FETCH_STATUS.NULL;
      state.availableDIDList = null;
    });
    builder.addCase(getActiveDIDList.rejected, (state, action) => {
      state.activeDIDListFetchStatus = FETCH_STATUS.REJECTED;
      state.activeDIDList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getActiveDIDList.fulfilled, (state, action) => {
      state.activeDIDListFetchStatus = FETCH_STATUS.FULFILLED;
      state.activeDIDList = action.payload;
    });
  },
});

export const {
  setAvailableDIDListFetchStatus,
  setStatesListFetchStatus,
  setRateCentersListFetchStatus,
  setRateCentersList,
  setBuyDidsPostStatus,
  setAvailableDIDList,
  setStatesList,
  setBYOPBXPostStatus,
  setAvailablePBXsListFetchStatus,
  setAvailablePBXsList,
  setActiveDIDListFetchStatus,
  setActiveDIDList,
} = didManagerSlice.actions;

export default didManagerSlice.reducer;
