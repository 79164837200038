import {
  FETCH_LOCATION_CONNECTIVITY_DATA_STATUS,
  FETCH_LOCATION_EQUIPMENT_LIST_STATUS,
  FETCH_LOCATION_ORDERS_LIST_STATUS,
  FETCH_LOCATION_SERVICES_LIST_STATUS,
  FETCH_LOCATION_TICKETS_LIST_STATUS,
  FETCH_LOCATION_URL_IMAGE,
  FETCH_LOCATIONS_DETAILS_STATUS,
  FETCH_LOCATIONS_LIST_STATUS,
  FETCH_LOCATIONS_TELCO_STATUS,
  POST_LOCATION_VALIDATION_STATUS,
  POST_LOCATIONS_DETAILS_STATUS,
  SET_LOCATION_CONNECTIVITY_DATA,
  SET_LOCATION_EQUIPMENT_LIST,
  SET_LOCATION_ORDERS_LIST,
  SET_LOCATION_SERVICES_LIST,
  SET_LOCATION_TICKETS_LIST,
  SET_LOCATION_URL_IMAGE,
  SET_LOCATIONS_BULK,
  SET_LOCATIONS_DETAILS,
  SET_LOCATIONS_LIST,
  SET_LOCATIONS_TELCO,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialLocationRouteParams = {
  page: 1,
  limit: 10,
  order: '-location.id',
  q: '',
  country_id: '',
  country_category: '',
  status: '',
};

const INIT_STATE = {
  locationList: [],
  locationListFetchStatus: null,
  locationsDetails: null,
  locationsDetailsFetchStatus: null,
  locationsDetailsPostStatus: null,
  imageURL: '',
  imageURLFetchStatus: null,
  locationsBulk: null,
  routeParams: initialLocationRouteParams,
  locationsServicesList: [],
  locationsServicesListFetchStatus: null,
  locationTelco: null,
  locationsTelcoFetchStatus: null,
  locationsEquipmentList: [],
  locationsEquipmentListFetchStatus: null,
  locationsConnectivityData: [],
  locationsConnectivityDataFetchStatus: null,
  locationsTicketsList: [],
  locationsTicketsListFetchStatus: null,
  locationsOrdersList: [],
  locationsOrdersListFetchStatus: null,
};

export const locationsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_LOCATIONS_DETAILS_STATUS:
      return { ...state, locationsDetailsPostStatus: action.payload };
    case SET_LOCATIONS_LIST:
      return { ...state, locationList: action.payload };
    case FETCH_LOCATIONS_LIST_STATUS:
      return { ...state, locationListFetchStatus: action.payload };
    case SET_LOCATIONS_DETAILS:
      return { ...state, locationsDetails: action.payload };
    case FETCH_LOCATIONS_DETAILS_STATUS:
      return { ...state, locationsDetailsFetchStatus: action.payload };
    case SET_LOCATION_URL_IMAGE:
      return { ...state, imageURL: action.payload };
    case FETCH_LOCATION_URL_IMAGE:
      return { ...state, imageURLFetchStatus: action.payload };
    case SET_LOCATION_SERVICES_LIST:
      return { ...state, locationsServicesList: action.payload };
    case FETCH_LOCATION_SERVICES_LIST_STATUS:
      return { ...state, locationsServicesListFetchStatus: action.payload };

    case SET_LOCATION_CONNECTIVITY_DATA:
      return { ...state, locationsConnectivityData: action.payload };
    case FETCH_LOCATION_CONNECTIVITY_DATA_STATUS:
      return { ...state, locationsConnectivityDataFetchStatus: action.payload };
    case SET_LOCATION_TICKETS_LIST:
      return { ...state, locationsTicketsList: action.payload };
    case FETCH_LOCATION_TICKETS_LIST_STATUS:
      return { ...state, locationsTicketsListFetchStatus: action.payload };
    case SET_LOCATION_ORDERS_LIST:
      return { ...state, locationsOrdersList: action.payload };
    case FETCH_LOCATION_ORDERS_LIST_STATUS:
      return { ...state, locationsOrdersListFetchStatus: action.payload };

    case POST_LOCATION_VALIDATION_STATUS:
      return { ...state, locationsValidationFetchStatus: action.payload };
    case SET_LOCATIONS_BULK:
      return { ...state, locationsBulk: action.payload };
    case SET_LOCATIONS_TELCO:
      return { ...state, locationTelco: action.payload };
    case FETCH_LOCATIONS_TELCO_STATUS:
      return { ...state, locationsTelcoFetchStatus: action.payload };
    case SET_LOCATION_EQUIPMENT_LIST:
      return { ...state, locationsEquipmentList: action.payload };
    case FETCH_LOCATION_EQUIPMENT_LIST_STATUS:
      return { ...state, locationsEquipmentListFetchStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'locations') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
