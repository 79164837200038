import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteResultType } from '../../types/utils/delete-result.type';
import { changeModalState, setModalContent } from '../../redux/utils/utils.action';

export const deleteAddressDetails = createAsyncThunk<DeleteResultType, { id: number }>(
  'addresses/deleteAddressDetails',
  async (payload, thunkAPI) => {
    try {
      const response = await axios.delete<DeleteResultType>(`${process.env.REACT_APP_API_URL}/addresses/${payload.id}`);
      thunkAPI.dispatch(changeModalState(false));
      thunkAPI.dispatch(setModalContent(null));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting address!');
    }
  }
);
