import {
  DELETE_ATAS_DETAILS_STATUS,
  FETCH_ATAS_ACS_DETAILS_STATUS,
  FETCH_ATAS_ACS_ADVANCED_SETTINGS_STATUS,
  FETCH_ATAS_DETAILS_STATUS,
  FETCH_ATAS_LIST_STATUS,
  FETCH_FACTORY_RESET_ATA_STATUS,
  FETCH_REBOOT_ATA_STATUS,
  FETCH_UPDATE_ATA_STATUS,
  PATCH_ATAS_ACS_DETAILS_STATUS,
  PATCH_ATAS_DETAILS_STATUS,
  POST_ATAS_ACS_DETAILS_STATUS,
  POST_ATAS_ACS_ADVANCED_SETTINGS_DETAILS_STATUS,
  POST_ATAS_BULK_DETAILS_STATUS,
  POST_ATAS_DETAILS_STATUS,
  SET_ATA_BULK,
  SET_ATAS_ACS_DETAILS,
  SET_ATAS_ACS_ADVANCED_SETTINGS,
  SET_ATAS_DETAILS,
  SET_ATAS_LIST,
  FETCH_ATAS_ACS_ADVANCED_PORT_SETTINGS_STATUS,
  SET_ATAS_ACS_ADVANCED_PORT_SETTINGS,
  POST_ATAS_ACS_ADVANCED_PORT_SETTINGS_DETAILS_STATUS,
  RESET_ATAS_ACS_ADVANCED_PORT_SETTINGS_DETAILS_STATUS,
  RESET_ATAS_ACS_ADVANCED_SETTINGS_DETAILS_STATUS,
  FETCH_UPDATE_ALL_GS_ATAS_CONFINGS_STATUS,
  FETCH_ATA_ACS_CONTACT_IDS_STATUS,
  SET_ATA_ACS_CONTACT_IDS,
  FETCH_ATA_CONFIG_FILE_STATUS,
} from '../constants/';
import { RequestStatus } from '../../utils/RequestStatus';
import SnackbarUtils from '../../utils/SnackbarUtils';
import axios from 'axios';
import { getATAListPath } from '../../routes/Router';
import { additionalQueryParams, history } from '../../utils/utilities';
import { changeModalState, setModalContent } from '../utils/utils.action';
import { ATA_TYPES } from '../../utils/constants';
import { AppDispatch } from '../Store';

export const getATAList = params => {
  return function (dispatch, getState) {
    dispatch(setATAListFetchStatus(RequestStatus.status.FETCHING));
    let additionalParams = additionalQueryParams(
      getState().sharedReducer.selectedCompany,
      getState().sharedReducer.selectedLocation,
      getState().sharedReducer.includeSuborgs
    );
    let mergedParams = { ...params, ...additionalParams };
    let queryString = Object.keys(mergedParams || {})
      .map(key => key + '=' + mergedParams[key])
      .join('&');
    let url = `${process.env.REACT_APP_API_URL}/atas${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        if (JSON.stringify(response.data.items) !== JSON.stringify(getState().atasReducer.atasList.items)) {
          dispatch(setATAList(response.data));
        }
        dispatch(setATAListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setATAListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting ata list!'
        );
      });
  };
};

export function setATAList(payload) {
  return {
    type: SET_ATAS_LIST,
    payload,
  };
}

export function setATAListFetchStatus(payload) {
  return {
    type: FETCH_ATAS_LIST_STATUS,
    payload,
  };
}

export const getATADetails = id => {
  return function (dispatch, getState) {
    dispatch(setATADetailsFetchStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/atas/${id}/`;
    axios
      .get(url)
      .then(response => {
        dispatch(setATADetails(response.data));
        dispatch(setATADetailsFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting ata details!'
        );
        dispatch(setATADetailsFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setATADetails(payload) {
  return {
    type: SET_ATAS_DETAILS,
    payload,
  };
}

export function setATADetailsFetchStatus(payload) {
  return {
    type: FETCH_ATAS_DETAILS_STATUS,
    payload,
  };
}

export const getATAACSContactIDs = (id: number) => {
  return function (dispatch: AppDispatch) {
    dispatch(setATAACSContactIDsFetchStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/contact-id/list?ata=${id}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setATAACSContactIDs(response.data));
        dispatch(setATAACSContactIDsFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting ata contact ids details!'
        );
        dispatch(setATAACSContactIDsFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setATAACSContactIDs(payload) {
  return {
    type: SET_ATA_ACS_CONTACT_IDS,
    payload,
  };
}

export function setATAACSContactIDsFetchStatus(payload) {
  return {
    type: FETCH_ATA_ACS_CONTACT_IDS_STATUS,
    payload,
  };
}

export const patchATADetails = (id, data, disableRedirect = false, editURL = '') => {
  return function (dispatch, getState) {
    dispatch(setATADetailsPatchStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/atas/${id}${editURL}`;
    axios
      .patch(url, data)
      .then(data => {
        if (disableRedirect) {
          dispatch(getATADetails(id));
        } else {
          history.push(`${getATAListPath}`);
        }

        dispatch(setATADetailsPatchStatus(RequestStatus.status.DONE));
        SnackbarUtils.success('ATA has been changed!');
      })
      .catch(error => {
        dispatch(setATADetailsPatchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during changing ATA!'
        );
      });
  };
};

export function setATADetailsPatchStatus(payload) {
  return {
    type: PATCH_ATAS_DETAILS_STATUS,
    payload,
  };
}

export const postATADetails = data => {
  return function (dispatch, getState) {
    dispatch(setATADetailsPostStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/atas/`;
    axios
      .post(url, data)
      .then(response => {
        dispatch(setATADetailsPostStatus(RequestStatus.status.DONE));
        // dispatch(getUserData());
        history.push(getATAListPath);
        SnackbarUtils.success('ATA has been added!');
      })
      .catch(error => {
        dispatch(setATADetailsPostStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during creating ATA!'
        );
      });
  };
};

export function setATADetailsPostStatus(payload) {
  return {
    type: POST_ATAS_DETAILS_STATUS,
    payload,
  };
}

export const deleteATADetails = (id, redirect = false) => {
  return function (dispatch, getState) {
    dispatch(setDeleteATADetailsFetchStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/atas/${id}`;
    axios
      .delete(url)
      .then(response => {
        dispatch(setDeleteATADetailsFetchStatus(RequestStatus.status.DONE));
        SnackbarUtils.success('ATA has been removed');
        if (redirect) history.push(getATAListPath);
        else dispatch(getATAList(getState().atasReducer.routeParams));
        dispatch(changeModalState(false));
        dispatch(setModalContent(null));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during removing ATA!'
        );
        dispatch(setDeleteATADetailsFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setDeleteATADetailsFetchStatus(payload) {
  return {
    type: DELETE_ATAS_DETAILS_STATUS,
    payload,
  };
}

export const updateATAStatus = (id: string, type: string) => {
  return function (dispatch, getState) {
    dispatch(setUpdateATAFetchStatus(RequestStatus.status.FETCHING));
    const url =
      type == ATA_TYPES.GS
        ? `${process.env.REACT_APP_API_URL}/atas/${id}/current_status`
        : type == ATA_TYPES.ACS
        ? `${process.env.REACT_APP_API_URL}/acs/${id}/current_status`
        : null;
    if (!url) {
      SnackbarUtils.error('Error during refreshing ATA status!');
      dispatch(setUpdateATAFetchStatus(RequestStatus.status.ERROR));
    } else {
      axios
        .post(url, undefined)
        .then(response => {
          SnackbarUtils.success('ATA status update started. Come back later, this may take a few minutes');
          dispatch(setUpdateATAFetchStatus(RequestStatus.status.DONE));
        })
        .catch(error => {
          SnackbarUtils.error(
            (error && error.response && error.response.data && error.response.data.message) ||
              'Error during refreshing ATA status!'
          );
          dispatch(setUpdateATAFetchStatus(RequestStatus.status.ERROR));
        });
    }
  };
};

export function setUpdateATAFetchStatus(payload) {
  return {
    type: FETCH_UPDATE_ATA_STATUS,
    payload,
  };
}

export const factoryResetATA = (id, type) => {
  return function (dispatch, getState) {
    dispatch(setFactoryResetAtaFetchStatus(RequestStatus.status.FETCHING));
    let url;
    if (type === ATA_TYPES.GS) url = `${process.env.REACT_APP_API_URL}/atas/${id}/reset`;
    if (type === ATA_TYPES.ACS) url = `${process.env.REACT_APP_API_URL}/acs/${id}/reset`;
    axios
      .post(url)
      .then(response => {
        dispatch(setFactoryResetAtaFetchStatus(RequestStatus.status.DONE));
        SnackbarUtils.success('ATA has been reverted to factory settings');
        dispatch(getATAList(getState().atasReducer.routeParams));
        dispatch(changeModalState(false));
        dispatch(setModalContent(null));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during reverted factory settings!'
        );
        dispatch(setFactoryResetAtaFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setFactoryResetAtaFetchStatus(payload) {
  return {
    type: FETCH_FACTORY_RESET_ATA_STATUS,
    payload,
  };
}

export const rebootATA = (id: number, type: string, isList: boolean) => {
  return function (dispatch, getState) {
    dispatch(setRebootAtaFetchStatus(RequestStatus.status.FETCHING));
    let url;
    if (type === ATA_TYPES.GS) url = `${process.env.REACT_APP_API_URL}/atas/${id}/reboot`;
    if (type === ATA_TYPES.ACS) url = `${process.env.REACT_APP_API_URL}/acs/${id}/reboot`;
    axios
      .post(url)
      .then(response => {
        if (isList) dispatch(getATAList(getState().atasReducer.routeParams));
        if (type === ATA_TYPES.ACS) {
          dispatch(getATAACSDetails(id));
        }
        if (type === ATA_TYPES.GS) {
          dispatch(getATADetails(id));
        }
        dispatch(setRebootAtaFetchStatus(RequestStatus.status.DONE));
        SnackbarUtils.success('ATA has been rebooted');
        dispatch(changeModalState(false));
        dispatch(setModalContent(null));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) || 'Error during rebooting!'
        );
        dispatch(setRebootAtaFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setRebootAtaFetchStatus(payload) {
  return {
    type: FETCH_REBOOT_ATA_STATUS,
    payload,
  };
}

export function setATABulk(payload) {
  return {
    type: SET_ATA_BULK,
    payload,
  };
}

export const postATABulkDetails = (data, type) => {
  return function (dispatch, getState) {
    dispatch(setATABulkDetailsPostStatus(RequestStatus.status.FETCHING));
    let url;
    if (type === ATA_TYPES.ACS) url = `${process.env.REACT_APP_API_URL}/acs/bulk`;
    if (type === ATA_TYPES.GS) url = `${process.env.REACT_APP_API_URL}/atas/bulk`;
    axios
      .post(url, data)
      .then(() => {
        dispatch(setATABulkDetailsPostStatus(RequestStatus.status.DONE));
        history.push(getATAListPath);
      })
      .catch(error => {
        dispatch(setATABulkDetailsPostStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during creating ATA!'
        );
      });
  };
};

export function setATABulkDetailsPostStatus(payload) {
  return {
    type: POST_ATAS_BULK_DETAILS_STATUS,
    payload,
  };
}

export const postATAACSDetails = data => {
  return function (dispatch, getState) {
    dispatch(setATAACSDetailsPostStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/acs`;
    axios
      .post(url, data)
      .then(response => {
        dispatch(setATAACSDetailsPostStatus(RequestStatus.status.DONE));
        history.push(getATAListPath);
        SnackbarUtils.success('ATA has been added!');
      })
      .catch(error => {
        dispatch(setATAACSDetailsPostStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during creating ATA!'
        );
      });
  };
};

export function setATAACSDetailsPostStatus(payload) {
  return {
    type: POST_ATAS_ACS_DETAILS_STATUS,
    payload,
  };
}

export const patchATAACSDetails = (id, data, disableRedirect = false, editURL = '') => {
  return function (dispatch, getState) {
    dispatch(setATAACSDetailsPatchStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/acs/${id}${editURL}`;
    axios
      .patch(url, data)
      .then(data => {
        dispatch(setATAACSDetailsPatchStatus(RequestStatus.status.DONE));
        SnackbarUtils.success('ATA has been changed!');
        if (disableRedirect) {
          dispatch(getATAACSDetails(id));
          dispatch(getATAACSAdvancedSettings(id, {}));
          return;
        }
        const params = getState().atasReducer.routeParams;
        const qs = Object.keys(getState().atasReducer.routeParams)
          .map(key => key + '=' + params[key])
          .join('&');
        history.push(`${getATAListPath}?${qs}`);
      })
      .catch(error => {
        dispatch(setATAACSDetailsPatchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during changing ATA!'
        );
      });
  };
};

export function setATAACSDetailsPatchStatus(payload) {
  return {
    type: PATCH_ATAS_ACS_DETAILS_STATUS,
    payload,
  };
}

export const getATAACSDetails = id => {
  return function (dispatch, getState) {
    dispatch(setATAACSDetailsFetchStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/acs/${id}/`;
    axios
      .get(url)
      .then(response => {
        dispatch(setATAACSDetails(response.data));
        dispatch(setATAACSDetailsFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting ata details!'
        );
        dispatch(setATAACSDetailsFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setATAACSDetails(payload) {
  return {
    type: SET_ATAS_ACS_DETAILS,
    payload,
  };
}

export function setATAACSDetailsFetchStatus(payload) {
  return {
    type: FETCH_ATAS_ACS_DETAILS_STATUS,
    payload,
  };
}

export const deleteATAACSDetails = (id, redirect = false) => {
  return function (dispatch, getState) {
    dispatch(setDeleteATAACSDetailsFetchStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/acs/${id}`;
    axios
      .delete(url)
      .then(response => {
        dispatch(setDeleteATAACSDetailsFetchStatus(RequestStatus.status.DONE));
        SnackbarUtils.success('ATA has been removed');
        if (redirect) history.push(getATAListPath);
        else dispatch(getATAList(getState().atasReducer.routeParams));
        dispatch(changeModalState(false));
        dispatch(setModalContent(null));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during removing ATA!'
        );
        dispatch(setDeleteATAACSDetailsFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setDeleteATAACSDetailsFetchStatus(payload) {
  return {
    type: DELETE_ATAS_DETAILS_STATUS,
    payload,
  };
}

export const getATAACSAdvancedSettings = (id, params) => {
  return function (dispatch, getState) {
    dispatch(setATAACSAdvancedSettingsFetchStatus(RequestStatus.status.FETCHING));
    const additionalParams = additionalQueryParams(
      getState().sharedReducer.selectedCompany,
      getState().sharedReducer.selectedLocation,
      getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams || {})
      .map(key => key + '=' + mergedParams[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/acs/${id}/config/${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setATAACSAdvancedSettings(response.data));
        dispatch(setATAACSAdvancedSettingsFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setATAACSAdvancedSettingsFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting ACS ATA config!'
        );
      });
  };
};

export function setATAACSAdvancedSettings(payload) {
  return {
    type: SET_ATAS_ACS_ADVANCED_SETTINGS,
    payload,
  };
}

export function setATAACSAdvancedSettingsFetchStatus(payload) {
  return {
    type: FETCH_ATAS_ACS_ADVANCED_SETTINGS_STATUS,
    payload,
  };
}

export const patchATAACSAdvancedSettings = (id, data) => {
  return function (dispatch, getState) {
    dispatch(setATAACSAdvancedSettingsPostStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/acs/${id}/config/`;
    axios
      .patch(url, data)
      .then(response => {
        dispatch(setATAACSAdvancedSettingsPostStatus(RequestStatus.status.DONE));
        SnackbarUtils.success('ACS ATA Advanced Settings has been updated!');
      })
      .catch(error => {
        dispatch(setATAACSAdvancedSettingsPostStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during saving ACS ATA config!'
        );
      });
  };
};

export function setATAACSAdvancedSettingsPostStatus(payload) {
  return {
    type: POST_ATAS_ACS_ADVANCED_SETTINGS_DETAILS_STATUS,
    payload,
  };
}

export const getATAACSAdvancedPortSettings = (id, params) => {
  return function (dispatch, getState) {
    dispatch(setATAACSAdvancedPortSettingsFetchStatus(RequestStatus.status.FETCHING));
    let additionalParams = additionalQueryParams(
      getState().sharedReducer.selectedCompany,
      getState().sharedReducer.selectedLocation,
      getState().sharedReducer.includeSuborgs
    );
    let mergedParams = { ...params, ...additionalParams };
    let queryString = Object.keys(mergedParams || {})
      .map(key => key + '=' + mergedParams[key])
      .join('&');
    let url = `${process.env.REACT_APP_API_URL}/acs/${id}/portConfig/${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setATAACSAdvancedPortSettings(response.data));
        dispatch(setATAACSAdvancedPortSettingsFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setATAACSAdvancedPortSettingsFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting ACS ATA Advanced settings list!'
        );
      });
  };
};

export function setATAACSAdvancedPortSettings(payload) {
  return {
    type: SET_ATAS_ACS_ADVANCED_PORT_SETTINGS,
    payload,
  };
}

export function setATAACSAdvancedPortSettingsFetchStatus(payload) {
  return {
    type: FETCH_ATAS_ACS_ADVANCED_PORT_SETTINGS_STATUS,
    payload,
  };
}

export const resetATAACSAdvancedPortSettings = (id: number, ataID: number, data) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setATAACSAdvancedPortSettingsResetStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/acs/${ataID}/${id}/reset/portConfig`;
    axios
      .patch(url, data)
      .then(() => {
        dispatch(setATAACSAdvancedPortSettingsResetStatus(RequestStatus.status.DONE));
        dispatch(getATAACSAdvancedPortSettings(id, {}));
        SnackbarUtils.success('ACS ATA Advanced Port Settings has been reset!');
      })
      .catch(error => {
        dispatch(setATAACSAdvancedPortSettingsResetStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during reset ACS ATA port config!'
        );
      });
  };
};

export function setATAACSAdvancedPortSettingsResetStatus(payload) {
  return {
    type: RESET_ATAS_ACS_ADVANCED_PORT_SETTINGS_DETAILS_STATUS,
    payload,
  };
}

export const resetATAACSAdvancedSettings = (id: number, data) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setATAACSAdvancedSettingsResetStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/acs/${id}/reset/config`;
    axios
      .patch(url, data)
      .then(() => {
        dispatch(setATAACSAdvancedSettingsResetStatus(RequestStatus.status.DONE));
        dispatch(getATAACSAdvancedSettings(id, {}));
        SnackbarUtils.success('ACS ATA Advanced Settings has been reset!');
      })
      .catch(error => {
        dispatch(setATAACSAdvancedSettingsResetStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during reset ACS ATA config!'
        );
      });
  };
};

export function setATAACSAdvancedSettingsResetStatus(payload) {
  return {
    type: RESET_ATAS_ACS_ADVANCED_SETTINGS_DETAILS_STATUS,
    payload,
  };
}

export const patchATAACSAdvancedPortSettings = (id: number, ataID: number, data) => {
  return function (dispatch) {
    dispatch(setATAACSAdvancedPortSettingsPostStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/acs/${ataID}/${id}/portConfig/`;
    axios
      .patch(url, data)
      .then(() => {
        dispatch(setATAACSAdvancedPortSettingsPostStatus(RequestStatus.status.DONE));
        dispatch(getATAACSAdvancedPortSettings(id, {}));
        SnackbarUtils.success('ACS ATA Advanced Port Settings has been updated!');
      })
      .catch(error => {
        dispatch(setATAACSAdvancedPortSettingsPostStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during saving ACS ATA port config!'
        );
      });
  };
};

export function setATAACSAdvancedPortSettingsPostStatus(payload) {
  return {
    type: POST_ATAS_ACS_ADVANCED_PORT_SETTINGS_DETAILS_STATUS,
    payload,
  };
}

export const updateAllGSATASConfigs = () => {
  return function (dispatch) {
    dispatch(setUpdateAllGSATAConfigsFetchStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/atas/updateAllXMLs`;
    axios
      .get(url)
      .then(() => {
        dispatch(setUpdateAllGSATAConfigsFetchStatus(RequestStatus.status.DONE));
        SnackbarUtils.success(
          'Update all GS ATA configs has been started. Come back later, this may take a few minutes.'
        );
      })
      .catch(error => {
        dispatch(setUpdateAllGSATAConfigsFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during update all GS ATA configs!'
        );
      });
  };
};

export function setUpdateAllGSATAConfigsFetchStatus(payload) {
  return {
    type: FETCH_UPDATE_ALL_GS_ATAS_CONFINGS_STATUS,
    payload,
  };
}

export const getATAConfigFile = (id: number, sn: string, type: string) => {
  return function (dispatch: AppDispatch) {
    dispatch(setATAConfigFileFetchStatus(RequestStatus.status.FETCHING));
    let url = '',
      mimeType = '';

    if (type === ATA_TYPES.ACS) {
      url = `${process.env.REACT_APP_API_URL}/acs/${id}/file`;
      mimeType = 'octet/stream';
    }

    if (type === ATA_TYPES.GS) {
      url = `${process.env.REACT_APP_API_URL}/atas/${id}/file`;
      mimeType = 'text/xml';
    }

    axios
      .get(url)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', sn.toUpperCase());
        document.body.appendChild(link);
        link.click();
        dispatch(setATAConfigFileFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting ata config file!'
        );
        dispatch(setATAConfigFileFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setATAConfigFileFetchStatus(payload) {
  return {
    type: FETCH_ATA_CONFIG_FILE_STATUS,
    payload,
  };
}
