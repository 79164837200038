import {
  FETCH_CHECK_DOMAIN_STATUS,
  SET_CACHED_FULL_COUNTRIES_LIST,
  SET_CACHED_FULL_GROUPED_COUNTRIES_LIST,
  SET_DOMAIN_DATA,
  SET_FULL_ASYNC_TASK_TYPES_LIST,
  SET_FULL_ATA_BASE_TEMPLATE_LIST,
  SET_FULL_ATA_ORG_TEMPLATE_LIST,
  SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST,
  SET_FULL_ATA_TYPE_LIST,
  SET_FULL_ATAS_ACS_PROFILE_LIST,
  SET_FULL_AUDIT_ENTITY_TYPES,
  SET_FULL_BUCKETS_LIST,
  SET_FULL_COUNTRIES_LIST,
  SET_FULL_COUNTRY_CATEGORIES_LIST,
  SET_FULL_DATA_PLAN_TYPE_LIST,
  SET_FULL_DIALER_TYPES_LIST,
  SET_FULL_DID_LIST,
  SET_FULL_ENCLOSURE_TYPES_LIST,
  SET_FULL_EQUIPMENT_GROUP_LIST,
  SET_FULL_EXTERNAL_PROVIDER_LIST,
  SET_FULL_EXTERNAL_SYSTEM_LIST,
  SET_FULL_GROUPED_COUNTRIES_LIST,
  SET_FULL_ISSUES_TRACKER_CODES_LIST,
  SET_FULL_LINE_TYPES_LIST,
  SET_FULL_LOCATION_LIST,
  SET_FULL_ORGANIZATION_LIST,
  SET_FULL_PBX_LIST,
  SET_FULL_PROFILE_LIST,
  SET_FULL_ROUTER_LIST,
  SET_FULL_ROUTER_PRODUCT_NAME_LIST,
  SET_FULL_SERVICE_LIST,
  SET_FULL_SERVICE_TYPE_CATEGORY_LIST,
  SET_FULL_SERVICE_TYPE_LIST,
  SET_FULL_SIM_CARRIER_LIST,
  SET_FULL_SIM_LIST,
  SET_FULL_TC_BLOCK_LIST,
  SET_FULL_VERIFIED_EMAILS_LIST,
  SET_FULL_VERIFIED_NUMBERS_LIST,
  SET_FULL_WAREHOUSE_LIST,
  SET_INCLUDE_SUBORGS,
  SET_JOT_FORM_URL,
  SET_SELECTED_COMPANY,
  SET_SELECTED_COUNTRY,
  SET_SELECTED_LOCATION,
  SET_SELECTED_USER_ACCESS,
  SET_TIMEZONE,
} from '../constants';

const INIT_STATE = {
  fullOrganizationList: [],
  selectedCompany: null,
  selectedUserAccess: null,
  selectedLocation: null,
  includeSuborgs: null,
  domainChecked: null,
  domainData: null,
  timezone: null,
  fullLocationList: [],
  fullATATypeList: [],
  fullATAOrgTemplateList: [],
  fullATABaseTemplateList: [],
  fullServiceTypeList: [],
  fullServiceTypeCategoryList: [],
  fullRouterProductNameList: [],
  fullSIMCarrierList: [],
  fullServiceList: [],
  fullEquipmentGroupList: [],
  fullPBXList: [],
  fullATAOrganizationTemplateList: [],
  fullDIDList: [],
  fullAsyncTaskTypesList: [],
  fullCountryCategoriesList: [],
  fullRouterList: [],
  fullDialerTypesList: [],
  fullEnclosureTypesList: [],
  fullWarehouseList: [],
  fullATAACSProfileList: [],
  fullTCBlockList: [],
  fullLineTypesList: [],
  fullSimList: [],
  fullDataPlanTypeList: [],
  fullVerifiedNumbersList: [],
  fullVerifiedEmailsList: [],
  fullIssuesTrackerCodes: null,
  fullAuditEntityTypes: [],
  fullExternalSystemList: [],
  fullExternalProviderList: [],
  fullBucketsList: [],
  jotFormURL: '',
  selectedCountry: null,
  fullProfileList: [],

  // Countries
  fullCountriesList: [],
  fullGroupedCountriesList: [],
  cachedCountriesByOrganizationID: {},
  cachedGroupedCountriesByOrganizationID: {},
};

export const sharedReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_JOT_FORM_URL:
      return { ...state, jotFormURL: action.payload };
    case SET_FULL_COUNTRY_CATEGORIES_LIST:
      return { ...state, fullCountryCategoriesList: action.payload };
    case SET_FULL_ORGANIZATION_LIST:
      return { ...state, fullOrganizationList: action.payload };
    case SET_FULL_ROUTER_LIST:
      return { ...state, fullRouterList: action.payload };
    case SET_SELECTED_COMPANY:
      return { ...state, selectedCompany: action.payload };
    case SET_SELECTED_USER_ACCESS:
      return { ...state, selectedUserAccess: action.payload };
    case SET_SELECTED_LOCATION:
      return { ...state, selectedLocation: action.payload };
    case SET_SELECTED_COUNTRY:
      return { ...state, selectedCountry: action.payload };
    case SET_FULL_COUNTRIES_LIST:
      return { ...state, fullCountriesList: action.payload };
    case SET_FULL_GROUPED_COUNTRIES_LIST:
      return { ...state, fullGroupedCountriesList: action.payload };
    case SET_INCLUDE_SUBORGS:
      return { ...state, includeSuborgs: action.payload };
    case FETCH_CHECK_DOMAIN_STATUS:
      return { ...state, domainChecked: action.payload };
    case SET_DOMAIN_DATA:
      return { ...state, domainData: action.payload };
    case SET_FULL_LOCATION_LIST:
      return { ...state, fullLocationList: action.payload };
    case SET_FULL_ATA_TYPE_LIST:
      return { ...state, fullATATypeList: action.payload };
    case SET_FULL_ATA_ORG_TEMPLATE_LIST:
      return { ...state, fullATAOrgTemplateList: action.payload };
    case SET_FULL_ATA_BASE_TEMPLATE_LIST:
      return { ...state, fullATABaseTemplateList: action.payload };
    case SET_FULL_SERVICE_TYPE_LIST:
      return { ...state, fullServiceTypeList: action.payload };
    case SET_FULL_SERVICE_TYPE_CATEGORY_LIST:
      return { ...state, fullServiceTypeCategoryList: action.payload };
    case SET_FULL_EQUIPMENT_GROUP_LIST:
      return { ...state, fullEquipmentGroupList: action.payload };
    case SET_FULL_PBX_LIST:
      return { ...state, fullPBXList: action.payload };
    case SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST:
      return { ...state, fullATAOrganizationTemplateList: action.payload };
    case SET_FULL_DID_LIST:
      return { ...state, fullDIDList: action.payload };
    case SET_FULL_DIALER_TYPES_LIST:
      return { ...state, fullDialerTypesList: action.payload };
    case SET_FULL_SERVICE_LIST:
      return { ...state, fullServiceList: action.payload };
    case SET_FULL_ASYNC_TASK_TYPES_LIST:
      return { ...state, fullAsyncTaskTypesList: action.payload };
    case SET_FULL_ENCLOSURE_TYPES_LIST:
      return { ...state, fullEnclosureTypesList: action.payload };
    case SET_FULL_WAREHOUSE_LIST:
      return { ...state, fullWarehouseList: action.payload };
    case SET_FULL_ATAS_ACS_PROFILE_LIST:
      return { ...state, fullATAACSProfileList: action.payload };
    case SET_FULL_TC_BLOCK_LIST:
      return { ...state, fullTCBlockList: action.payload };
    case SET_FULL_LINE_TYPES_LIST:
      return { ...state, fullLineTypesList: action.payload };
    case SET_FULL_SIM_LIST:
      return { ...state, fullSimList: action.payload };
    case SET_FULL_DATA_PLAN_TYPE_LIST:
      return { ...state, fullDataPlanTypeList: action.payload };
    case SET_FULL_ROUTER_PRODUCT_NAME_LIST:
      return { ...state, fullRouterProductNameList: action.payload };
    case SET_FULL_SIM_CARRIER_LIST:
      return { ...state, fullSIMCarrierList: action.payload };
    case SET_FULL_VERIFIED_NUMBERS_LIST:
      return { ...state, fullVerifiedNumbersList: action.payload };
    case SET_FULL_VERIFIED_EMAILS_LIST:
      return { ...state, fullVerifiedEmailsList: action.payload };
    case SET_FULL_ISSUES_TRACKER_CODES_LIST:
      return { ...state, fullIssuesTrackerCodes: action.payload };
    case SET_FULL_AUDIT_ENTITY_TYPES:
      return { ...state, fullAuditEntityTypes: action.payload };
    case SET_FULL_EXTERNAL_SYSTEM_LIST:
      return { ...state, fullExternalSystemList: action.payload };
    case SET_FULL_EXTERNAL_PROVIDER_LIST:
      return { ...state, fullExternalProviderList: action.payload };
    case SET_FULL_BUCKETS_LIST:
      return { ...state, fullBucketsList: action.payload };
    case SET_FULL_PROFILE_LIST:
      return { ...state, fullProfileList: action.payload };
    case SET_CACHED_FULL_COUNTRIES_LIST:
      return {
        ...state,
        cachedCountriesByOrganizationID: state?.cachedCountriesByOrganizationID
          ? { ...state.cachedCountriesByOrganizationID, ...action.payload }
          : { ...action.payload },
      };
    case SET_CACHED_FULL_GROUPED_COUNTRIES_LIST:
      return {
        ...state,
        cachedGroupedCountriesByOrganizationID: state?.cachedGroupedCountriesByOrganizationID
          ? { ...state.cachedGroupedCountriesByOrganizationID, ...action.payload }
          : { ...action.payload },
      };
    case SET_TIMEZONE:
      return { ...state, timezone: action.payload };
    default:
      return state;
  }
};
