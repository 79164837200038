import { createSlice } from '@reduxjs/toolkit';
import { UserType } from '../../types/user/user.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { getCurrentUser, logout, postForgotPassword, postLogin, postResetPassword } from './auth.action';
import { agreementType } from '../../utils/constants';
import { history } from '../../utils/utilities';
import { AGREEMENT_TYPE } from '../../types/enums/agreement-type.enum';
import NetworkService from '../../utils/network.service';
import snackbarUtils from '../../utils/SnackbarUtils';

export interface AuthState {
  user: null | UserType;
  userFetchStatus: FETCH_STATUS;
  tokenChecked: boolean;
  loginPostStatus: FETCH_STATUS;
  forgotPasswordPostStatus: FETCH_STATUS;
  resetPasswordPostStatus: FETCH_STATUS;
  redirectFetchStatus: FETCH_STATUS;
  userAccessList: Array<{ organization: { name: string; id: number } }>;
  logoutStatus: FETCH_STATUS;
}

const INIT_STATE: AuthState = {
  user: null,
  userFetchStatus: FETCH_STATUS.NULL,
  tokenChecked: false,
  loginPostStatus: FETCH_STATUS.NULL,
  forgotPasswordPostStatus: FETCH_STATUS.NULL,
  resetPasswordPostStatus: FETCH_STATUS.NULL,
  redirectFetchStatus: FETCH_STATUS.NULL,
  userAccessList: [],
  logoutStatus: FETCH_STATUS.NULL,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INIT_STATE,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserFetchStatus: (state, action) => {
      state.userFetchStatus = action.payload;
    },
    setTokenChecked: (state, action) => {
      state.tokenChecked = action.payload;
    },
    setLoginPostStatus: (state, action) => {
      state.loginPostStatus = action.payload;
    },
    setForgotPasswordPostStatus: (state, action) => {
      state.forgotPasswordPostStatus = action.payload;
    },
    setResetPasswordPostStatus: (state, action) => {
      state.resetPasswordPostStatus = action.payload;
    },
    setRedirectFetchStatus: (state, action) => {
      state.redirectFetchStatus = action.payload;
    },
    setUserAccessList: (state, action) => {
      state.userAccessList = action.payload;
    },
  },
  extraReducers: builder => {
    // GET CURRENT USER
    builder.addCase(getCurrentUser.pending, state => {
      state.userFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
      if (action.payload.init) {
        state.tokenChecked = true;
      }

      setTimeout(() => {
        if (
          !action.payload.init &&
          window.location.pathname.indexOf('/msa') !== -1 &&
          action.payload.user.agreements
            ?.map(a => a.type)
            .includes(<AGREEMENT_TYPE>agreementType.MASTER_SERVICES_AGREEMENT)
        ) {
          history.push('/');
        }

        if (window.location.pathname.indexOf('/authentication') !== -1) {
          history.push('/');
        }
      });

      state.userFetchStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.user = null;
      state.userFetchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });

    // POST LOGIN
    builder.addCase(postLogin.pending, state => {
      state.loginPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postLogin.fulfilled, (state, action) => {
      localStorage.setItem('token', action.payload.access_token);
      NetworkService.setupDefaultHeaders(action.payload.access_token);
      state.user = action.payload.user;
      state.tokenChecked = true;
      state.loginPostStatus = FETCH_STATUS.FULFILLED;
    });

    builder.addCase(postLogin.rejected, (state, action) => {
      state.user = null;
      state.loginPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });

    // POST FORGOT PASSWORD
    builder.addCase(postForgotPassword.pending, state => {
      state.forgotPasswordPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postForgotPassword.fulfilled, state => {
      snackbarUtils.success('Check your e-mail for reset password link');
      state.forgotPasswordPostStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(postForgotPassword.rejected, (state, action) => {
      state.forgotPasswordPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });

    // POST RESET PASSWORD
    builder.addCase(postResetPassword.pending, state => {
      state.resetPasswordPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postResetPassword.fulfilled, state => {
      snackbarUtils.success('Password has been changed. Now you can log in using your new password');
      state.resetPasswordPostStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(postResetPassword.rejected, (state, action) => {
      state.resetPasswordPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });

    // LOGOUT
    builder.addCase(logout.fulfilled, state => {
      state.user = null;
      state.tokenChecked = false;
      state.userFetchStatus = FETCH_STATUS.NULL;
      state.loginPostStatus = FETCH_STATUS.NULL;
      state.forgotPasswordPostStatus = FETCH_STATUS.NULL;
      state.resetPasswordPostStatus = FETCH_STATUS.NULL;
      state.redirectFetchStatus = FETCH_STATUS.NULL;
      state.userAccessList = [];
    });
  },
});

export const {
  setUser,
  setForgotPasswordPostStatus,
  setLoginPostStatus,
  setResetPasswordPostStatus,
  setTokenChecked,
  setRedirectFetchStatus,
  setUserAccessList,
} = authSlice.actions;
export default authSlice.reducer;
