import { FETCH_DIALER_LOGS_LIST_STATUS, SET_DIALER_LOGS_LIST, SET_ROUTE_PARAMS } from '../constants';

export const initialDialerLogsRouteParams = {
  page: 1,
  limit: 10,
  order: '-dialer_log.id',
  q: '',
};

const INIT_STATE = {
  dialerLogsList: [],
  dialerLogsListFetchStatus: null,
  routeParams: initialDialerLogsRouteParams,
};

export const dialerLogsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DIALER_LOGS_LIST:
      return { ...state, dialerLogsList: action.payload };
    case FETCH_DIALER_LOGS_LIST_STATUS:
      return { ...state, dialerLogsListFetchStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'dialerLogs') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
