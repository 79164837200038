import {
  FETCH_DATA_PLAN_DETAILS_STATUS,
  FETCH_DATA_PLAN_LIST_STATUS,
  SET_DATA_PLAN_DETAILS,
  SET_DATA_PLAN_LIST,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialDataPlansRouteParams = {
  page: 1,
  limit: 10,
  order: '-data_plan.id',
  data_plan_type_id: '',
  q: '',
};

const INIT_STATE = {
  dataPlansList: [],
  dataPlansListFetchStatus: null,
  dataPlansDetails: null,
  dataPlansDetailsFetchStatus: null,
  routeParams: initialDataPlansRouteParams,
};

export const dataPlansReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DATA_PLAN_LIST:
      return { ...state, dataPlansList: action.payload };
    case FETCH_DATA_PLAN_LIST_STATUS:
      return { ...state, dataPlansListFetchStatus: action.payload };
    case SET_DATA_PLAN_DETAILS:
      return { ...state, dataPlansDetails: action.payload };
    case FETCH_DATA_PLAN_DETAILS_STATUS:
      return { ...state, dataPlansDetailsFetchStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'dataPlans') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
