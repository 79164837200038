import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { BaseCDRListItemType } from '../../types/cdr/base-cdr.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import { getCDRMonitorLink, getCDRsList } from './cdrs.action';

export interface CDRsState {
  cdrsRouteParams: RouteParamsType;
  cdrsList: null | PaginationType<BaseCDRListItemType>;
  cdrsListFetchStatus: FETCH_STATUS;
  cdrMonitorLinkFetchStatus: FETCH_STATUS;
}

export const initialCDRsRouteParams = {
  page: 1,
  limit: 10,
  order: '-cdr.timeStart',
  q: '',
  call_result: 'All',
  start_date: '',
  end_date: '',
};

const INIT_STATE: CDRsState = {
  cdrsRouteParams: initialCDRsRouteParams,
  cdrsList: null,
  cdrsListFetchStatus: FETCH_STATUS.NULL,
  cdrMonitorLinkFetchStatus: FETCH_STATUS.NULL,
};

export const cdrsSlice = createSlice({
  name: 'cdrs',
  initialState: INIT_STATE,
  reducers: {
    setCDRsList: (state, action) => {
      state.cdrsList = action.payload;
    },
    setCDRsListFetchStatus: (state, action) => {
      state.cdrsListFetchStatus = action.payload;
    },
    setCDRMonitorLinkFetchStatus: (state, action) => {
      state.cdrMonitorLinkFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'cdrs') {
        state.cdrsRouteParams = { ...state.cdrsRouteParams, ...payload.data };
      }
    });
    // GET CDRS LIST
    builder.addCase(getCDRsList.pending, state => {
      state.cdrsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getCDRsList.rejected, (state, action) => {
      state.cdrsListFetchStatus = FETCH_STATUS.REJECTED;
      state.cdrsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getCDRsList.fulfilled, (state, action) => {
      state.cdrsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.cdrsList = action.payload;
    });

    // GET CDR MONITOR LINK
    builder.addCase(getCDRMonitorLink.pending, state => {
      state.cdrMonitorLinkFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getCDRMonitorLink.rejected, (state, action) => {
      state.cdrMonitorLinkFetchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getCDRMonitorLink.fulfilled, state => {
      state.cdrMonitorLinkFetchStatus = FETCH_STATUS.FULFILLED;
    });
  },
});

export const { setCDRsList, setCDRsListFetchStatus, setCDRMonitorLinkFetchStatus } = cdrsSlice.actions;
export default cdrsSlice.reducer;
