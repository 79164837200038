import { io, Socket } from 'socket.io-client';

class SocketService {
  private readonly socket: Socket = io(`${process.env.REACT_APP_API_URL}/auth`, {
    autoConnect: false,
    transports: ['websocket'],
  });

  connectWithAuthToken(token: string) {
    this.socket.io.opts.query = { token };
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }
}

export const socketService = new SocketService();
