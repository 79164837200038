import { lazy } from 'react';

const EquipmentGroupPublic = lazy(() => import('../views/Public/EquipmentGroupPublic/EquipmentGroupPublic'));
const LocationPublic = lazy(() => import('../views/Public/LocationPublic/LocationPublic'));

const publicRoutes = [
  {
    path: '/public/equipment-group/:hash',
    name: 'Equipment Group Preview',
    icon: 'mdi mdi-account-key',
    component: EquipmentGroupPublic,
  },
  {
    path: '/public/router/:hash',
    name: 'Equipment Group Preview (router)',
    icon: 'mdi mdi-account-key',
    component: EquipmentGroupPublic,
  },
  {
    path: '/public/battery/:hash',
    name: 'Equipment Group Preview (battery)',
    icon: 'mdi mdi-account-key',
    component: EquipmentGroupPublic,
  },
  {
    path: '/public/atas/:hash',
    name: 'Equipment Group Preview (ata)',
    icon: 'mdi mdi-account-key',
    component: EquipmentGroupPublic,
  },
  {
    path: '/public/dialer/:hash',
    name: 'Equipment Group Preview (dialers)',
    icon: 'mdi mdi-account-key',
    component: EquipmentGroupPublic,
  },
  {
    path: '/public/acs/:hash',
    name: 'Equipment Group Preview (ata acs)',
    icon: 'mdi mdi-account-key',
    component: EquipmentGroupPublic,
  },
  {
    path: '/public/enclosures/:hash',
    name: 'Equipment Group Preview (enclosures)',
    icon: 'mdi mdi-account-key',
    component: EquipmentGroupPublic,
  },
  {
    path: '/public/power-cycles/:hash',
    name: 'Equipment Group Preview (power-cycle)',
    icon: 'mdi mdi-account-key',
    component: EquipmentGroupPublic,
  },
  {
    path: '/public/tc-sim/:hash',
    name: 'Equipment Group Preview (tc-sim)',
    icon: 'mdi mdi-account-key',
    component: EquipmentGroupPublic,
  },
  {
    path: '/public/location/:hash',
    name: 'Location Preview',
    icon: 'mdi mdi-account-key',
    component: LocationPublic,
  },
];

export default publicRoutes;
