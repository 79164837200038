import React, { useMemo } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from '../Spinner/Spinner';

const style = { zIndex: 1000000000, willChange: 'opacity' };

const Loader = props => {
  const SpinnerCmp = useMemo(() => <Spinner />, []);
  return (
    <Backdrop className='classes.backdrop' style={style} open={props.showLoader}>
      {SpinnerCmp}
    </Backdrop>
  );
};

export default React.memo(Loader);
Loader.whyDidYouRender = true;
