import {
  FETCH_ENCLOSURES_DETAILS_STATUS,
  FETCH_ENCLOSURES_LIST_STATUS,
  SET_ENCLOSURES_BULK,
  SET_ENCLOSURES_DETAILS,
  SET_ENCLOSURES_LIST,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialEnclosureRouteParams = {
  page: 1,
  limit: 10,
  order: '-enclosure.id',
  q: '',
  equipment_status: 'all',
  warehouse_id: '',
  in_service: 'all',
  country_id: '',
  country_category: '',
};

const INIT_STATE = {
  enclosuresList: [],
  enclosuresListFetchStatus: null,
  enclosuresDetails: null,
  enclosuresDetailsFetchStatus: null,
  enclosuresBulk: null,
  routeParams: initialEnclosureRouteParams,
};

export const enclosuresReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ENCLOSURES_LIST:
      return { ...state, enclosuresList: action.payload };
    case FETCH_ENCLOSURES_LIST_STATUS:
      return { ...state, enclosuresListFetchStatus: action.payload };
    case SET_ENCLOSURES_DETAILS:
      return { ...state, enclosuresDetails: action.payload };
    case FETCH_ENCLOSURES_DETAILS_STATUS:
      return { ...state, enclosuresDetailsFetchStatus: action.payload };
    case SET_ENCLOSURES_BULK:
      return { ...state, enclosuresBulk: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'enclosures') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
