import {
  FETCH_PORTING_DETAILS_STATUS,
  FETCH_PORTING_LIST_STATUS,
  SET_PORTING_DETAILS,
  SET_PORTING_LIST,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialPortingRouteParams = {
  page: 1,
  limit: 10,
  order: '-port_request.id',
  q: '',
};

const INIT_STATE = {
  portingList: [],
  portingListFetchStatus: null,
  portingDetails: null,
  portingDetailsFetchStatus: null,
  routeParams: initialPortingRouteParams,
};

export const portingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PORTING_LIST:
      return { ...state, portingList: action.payload };
    case FETCH_PORTING_LIST_STATUS:
      return { ...state, portingListFetchStatus: action.payload };
    case SET_PORTING_DETAILS:
      return { ...state, portingDetails: action.payload };
    case FETCH_PORTING_DETAILS_STATUS:
      return { ...state, portingDetailsFetchStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'porting') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
