import { createSlice } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import { BucketListItemType, BucketType } from '../../types/bucket/bucket.type';
import { SecureAlarmDIDListItemType } from '../../types/SecureAlarmDID/secureAlarmDID.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deleteBucketDetails,
  getBucketDetails,
  getBucketsList,
  getSecureAlarmDIDsList,
  patchBucketDetails,
  postBucketDetails,
  postSecureAlarmDIDs,
} from './secureAlarm.action';

export interface NotificationsState {
  secureAlarmDIDsList: null | PaginationType<SecureAlarmDIDListItemType>;
  secureAlarmDIDsListFetchStatus: FETCH_STATUS;
  secureAlarmDIDsRouteParams: RouteParamsType;
  secureAlarmDIDDetailsBulkPostStatus: FETCH_STATUS;
  secureAlarmDIDsBulk: null | Array<{ did: string; country_code: string }>;
  secureAlarmDIDsBulkPostStatus: FETCH_STATUS;

  bucketsList: null | PaginationType<BucketListItemType>;
  bucketsListFetchStatus: FETCH_STATUS;
  bucketsRouteParams: RouteParamsType;
  bucketDetails: null | BucketType;
  bucketDetailsFetchStatus: FETCH_STATUS;
  bucketDetailsPatchStatus: FETCH_STATUS;
  bucketDetailsPostStatus: FETCH_STATUS;
  bucketDetailsDeleteStatus: FETCH_STATUS;
}

export const initialBucketsRouteParams = {
  page: 1,
  limit: 10,
  order: '-id',
  q: '',
};

export const initialDIDsRouteParams = {
  page: 1,
  limit: 10,
  order: '-id',
  q: '',
  available: 'all',
  bucketId: '',
};

const INIT_STATE: NotificationsState = {
  secureAlarmDIDsRouteParams: initialDIDsRouteParams,
  secureAlarmDIDsList: null,
  secureAlarmDIDsListFetchStatus: FETCH_STATUS.NULL,
  secureAlarmDIDDetailsBulkPostStatus: FETCH_STATUS.NULL,
  secureAlarmDIDsBulk: null,
  secureAlarmDIDsBulkPostStatus: FETCH_STATUS.NULL,

  bucketsRouteParams: initialBucketsRouteParams,
  bucketsList: null,
  bucketsListFetchStatus: FETCH_STATUS.NULL,
  bucketDetails: null,
  bucketDetailsFetchStatus: FETCH_STATUS.NULL,
  bucketDetailsPatchStatus: FETCH_STATUS.NULL,
  bucketDetailsPostStatus: FETCH_STATUS.NULL,
  bucketDetailsDeleteStatus: FETCH_STATUS.NULL,
};

export const secureAlarmSlice = createSlice({
  name: 'secureAlarm',
  initialState: INIT_STATE,
  reducers: {
    setSecureAlarmDIDsList: (state, action) => {
      state.secureAlarmDIDsList = action.payload;
    },
    setSecureAlarmDIDsListFetchStatus: (state, action) => {
      state.secureAlarmDIDsListFetchStatus = action.payload;
    },
    setSecureAlarmDIDsBulk: (state, action) => {
      state.secureAlarmDIDsBulk = action.payload;
    },
    setSecureAlarmDIDsBulkPostStatus: (state, action) => {
      state.secureAlarmDIDsBulkPostStatus = action.payload;
    },

    setBucketsList: (state, action) => {
      state.bucketsList = action.payload;
    },
    setBucketsListFetchStatus: (state, action) => {
      state.bucketsListFetchStatus = action.payload;
    },
    setBucketDetails: (state, action) => {
      state.bucketDetails = action.payload;
    },
    setBucketDetailsFetchStatus: (state, action) => {
      state.bucketDetailsFetchStatus = action.payload;
    },
    setBucketDetailsPatchStatus: (state, action) => {
      state.bucketDetailsPatchStatus = action.payload;
    },
    setBucketDetailsPostStatus: (state, action) => {
      state.bucketDetailsPostStatus = action.payload;
    },
    setBucketDetailsDeleteStatus: (state, action) => {
      state.bucketDetailsDeleteStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;

      if (payload.reducer === 'secureAlarmDIDs') {
        state.secureAlarmDIDsRouteParams = { ...state.secureAlarmDIDsRouteParams, ...payload.data };
      }

      if (payload.reducer === 'secureAlarmBuckets') {
        state.bucketsRouteParams = { ...state.bucketsRouteParams, ...payload.data };
      }
    });

    // SECURE ALARM DID -------------------------------------------------------

    // GET SECURE ALARM DID LIST
    builder.addCase(getSecureAlarmDIDsList.pending, state => {
      state.secureAlarmDIDsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getSecureAlarmDIDsList.rejected, (state, action) => {
      state.secureAlarmDIDsListFetchStatus = FETCH_STATUS.REJECTED;
      state.secureAlarmDIDsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getSecureAlarmDIDsList.fulfilled, (state, action) => {
      state.secureAlarmDIDsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.secureAlarmDIDsList = action.payload;
    });

    // POST SECURE ALARM DID BULK
    builder.addCase(postSecureAlarmDIDs.pending, state => {
      state.secureAlarmDIDsBulkPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postSecureAlarmDIDs.rejected, (state, action) => {
      state.secureAlarmDIDsBulkPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postSecureAlarmDIDs.fulfilled, state => {
      state.secureAlarmDIDsBulkPostStatus = FETCH_STATUS.FULFILLED;
    });

    // BUCKET -----------------------------------------------------------------

    // GET BUCKET LIST
    builder.addCase(getBucketsList.pending, state => {
      state.bucketsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getBucketsList.rejected, (state, action) => {
      state.bucketsListFetchStatus = FETCH_STATUS.REJECTED;
      state.bucketsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getBucketsList.fulfilled, (state, action) => {
      state.bucketsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.bucketsList = action.payload;
    });

    // GET BUCKET DETAILS
    builder.addCase(getBucketDetails.pending, state => {
      state.bucketDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getBucketDetails.rejected, (state, action) => {
      state.bucketDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.bucketDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getBucketDetails.fulfilled, (state, action) => {
      state.bucketDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.bucketDetails = action.payload;
    });

    // DELETE BUCKET DETAILS
    builder.addCase(deleteBucketDetails.pending, state => {
      state.bucketDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteBucketDetails.rejected, (state, action) => {
      state.bucketDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteBucketDetails.fulfilled, state => {
      state.bucketDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Bucket deleted successfully');
    });

    // POST BUCKET DETAILS
    builder.addCase(postBucketDetails.pending, state => {
      state.bucketDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postBucketDetails.rejected, (state, action) => {
      state.bucketDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postBucketDetails.fulfilled, state => {
      state.bucketDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Bucket created successfully');
    });

    // PATCH BUCKET DETAILS
    builder.addCase(patchBucketDetails.pending, state => {
      state.bucketDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchBucketDetails.rejected, (state, action) => {
      state.bucketDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchBucketDetails.fulfilled, state => {
      state.bucketDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Bucket updated successfully');
    });
  },
});

export const {
  setBucketsListFetchStatus,
  setBucketsList,
  setBucketDetailsFetchStatus,
  setBucketDetailsPatchStatus,
  setBucketDetailsPostStatus,
  setBucketDetailsDeleteStatus,
  setBucketDetails,
  setSecureAlarmDIDsListFetchStatus,
  setSecureAlarmDIDsList,
  setSecureAlarmDIDsBulk,
  setSecureAlarmDIDsBulkPostStatus,
} = secureAlarmSlice.actions;

export default secureAlarmSlice.reducer;
