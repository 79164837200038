import {
  DELETE_FILE_DATA_STATUS,
  DELETE_NOTES_DETAILS_STATUS,
  PATCH_NOTES_DETAILS_STATUS,
  POST_NOTES_DETAILS_STATUS,
  PATCH_FILE_DATA_STATUS,
  UPLOAD_FILE_DATA_STATUS,
} from '../constants';

const INIT_STATE = {
  uploadFileStatus: null,
  updateFileStatus: null,
  deleteFileStatus: null,
  uploadedFile: null,
  noteDetailsDeleteStatus: null,
  noteDetailsPatchStatus: null,
  noteDetailsPostStatus: null,
};

export const filesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPLOAD_FILE_DATA_STATUS:
      return { ...state, uploadFileStatus: action.payload };
    case PATCH_FILE_DATA_STATUS:
      return { ...state, updateFileStatus: action.payload };
    case DELETE_FILE_DATA_STATUS:
      return { ...state, deleteFileStatus: action.payload };
    case DELETE_NOTES_DETAILS_STATUS:
      return { ...state, noteDetailsDeleteStatus: action.payload };
    case POST_NOTES_DETAILS_STATUS:
      return { ...state, noteDetailsPostStatus: action.payload };
    case PATCH_NOTES_DETAILS_STATUS:
      return { ...state, noteDetailsPatchStatus: action.payload };
    default:
      return state;
  }
};
