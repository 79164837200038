import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import { additionalQueryParams } from '../../utils/utilities';
import axios from 'axios';
import { BaseCDRListItemType } from '../../types/cdr/base-cdr.type';

export const getCDRsList = createAsyncThunk<
  PaginationType<BaseCDRListItemType>,
  RouteParamsType,
  {
    state: RootState;
  }
>('cdrs/getCDRsList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<BaseCDRListItemType>>(
      `${process.env.REACT_APP_API_URL}/cdrs${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching cdrs!');
  }
});

export const getCDRMonitorLink = createAsyncThunk<
  PaginationType<BaseCDRListItemType>,
  { id: number; type?: string },
  {
    state: RootState;
  }
>('cdrs/getCDRMonitorLink', async ({ id, type }, thunkAPI) => {
  try {
    const response = await axios.get<PaginationType<BaseCDRListItemType>>(
      `${process.env.REACT_APP_API_URL}/cdrs/${id}/getMonitorDeeplink${type ? `?type=${type}` : ''}`
    );

    thunkAPI.dispatch(getCDRsList(thunkAPI.getState().cdrsReducer.cdrsRouteParams));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching cdr deep monitor link!');
  }
});
