import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/style.scss';
import Spinner from './components/Other/Spinner/Spinner';
import App from './app';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (process.env.NODE_ENV === 'development') {
  // whyDidYouRender(React, {
  //   trackAllPureComponents: true,
  //   trackHooks: true,
  //   trackExtraHooks: [[require("react-redux/lib"), "useSelector"]],
  // });
}

if (window.location.origin.indexOf('localhost') === -1) {
  Sentry.init({
    dsn: 'https://8d3b76f385e74312b29519b0ad55a00b@o494649.ingest.sentry.io/5584096',
    environment: process.env.NODE_ENV === 'development' ? 'develop' : 'production',
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampler: () => 0.1,
  });
}

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);
