import { FETCH_USER_ACCESS_LIST_STATUS, SET_USER_ACCESS_LIST } from '../constants';

const INIT_STATE = {
  userAccessList: [],
  userAccessListFetchStatus: null,
};

export const userAccessReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER_ACCESS_LIST:
      return { ...state, userAccessList: action.payload };
    case FETCH_USER_ACCESS_LIST_STATUS:
      return { ...state, userAccessListFetchStatus: action.payload };
    default:
      return state;
  }
};
