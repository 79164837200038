import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { DIDsListItemType } from '../../types/did/did.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { OrganizationDetailsType } from '../../types/organization/organization.type';
import { LocationListItemType } from '../../types/location/LocationListItem.type';
import { additionalQueryParams, getOrganizationOptions } from '../../utils/utilities';

export const getCustomerDashboardDIDsList = createAsyncThunk<
  PaginationType<DIDsListItemType>,
  RouteParamsType,
  { state: RootState }
>('shared/getCustomerDashboardDIDsList/no-loader', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<DIDsListItemType>>(
      `${process.env.REACT_APP_API_URL}/dids${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching DIDs!');
  }
});

export const getFullOrganizationsList = createAsyncThunk<
  Array<{
    bobo_enabled: boolean;
    hasChildren: boolean;
    id: number;
    level: number;
    name: string;
  }>,
  { org_id: number },
  { state: RootState }
>('shared/getFullOrganizationsList/no-loader', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<OrganizationDetailsType>(
      `${process.env.REACT_APP_API_URL}/organizations/tree${queryString ? `?${queryString}` : ''}`
    );
    return getOrganizationOptions(response.data, '');
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching Organizations!');
  }
});

export const getFullLocationsList = createAsyncThunk<
  PaginationType<LocationListItemType>,
  RouteParamsType,
  { state: RootState }
>('shared/getFullLocationsList/no-loader', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<LocationListItemType>>(
      `${process.env.REACT_APP_API_URL}/location${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching Locations!');
  }
});
