import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import { additionalQueryParams } from '../../utils/utilities';
import {
  CreateWarehouseDto,
  UpdateWarehouseDto,
  WarehouseDetailsType,
  WarehouseListItemType,
  WarehouseType,
} from '../../types/warehouse/warehouse.type';
import { AddressType } from '../../types/address/address.type';
import { changeModalState, setModalContent } from '../../redux/utils/utils.action';

export const postWarehouseDetails = createAsyncThunk<
  Pick<WarehouseType, 'id' | 'created_at' | 'modified_at' | 'name'> & {
    address: { id: number };
    organization: { id: number };
  },
  { data: CreateWarehouseDto },
  { state: RootState }
>('warehouses/postWarehouseDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<
      Pick<WarehouseType, 'id' | 'created_at' | 'modified_at' | 'name'> & {
        address: { id: number };
        organization: { id: number };
      }
    >(`${process.env.REACT_APP_API_URL}/warehouse`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating warehouse!');
  }
});

export const getWarehousesList = createAsyncThunk<
  PaginationType<WarehouseListItemType>,
  RouteParamsType,
  { state: RootState }
>('warehouses/getWarehousesList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      null,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<WarehouseListItemType>>(
      `${process.env.REACT_APP_API_URL}/warehouse${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching warehouses!');
  }
});

export const getWarehouseDetails = createAsyncThunk<WarehouseDetailsType, { id: number }, { state: RootState }>(
  'warehouses/getWarehouseDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<WarehouseDetailsType>(`${process.env.REACT_APP_API_URL}/warehouse/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching warehouse!');
    }
  }
);

export const patchWarehouseDetails = createAsyncThunk<
  Pick<WarehouseType, 'id' | 'created_at' | 'modified_at' | 'name'> & {
    address: AddressType;
  },
  { id: number; data: UpdateWarehouseDto },
  { state: RootState }
>('warehouses/patchWarehouseDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<
      Pick<WarehouseType, 'id' | 'created_at' | 'modified_at' | 'name'> & {
        address: AddressType;
      }
    >(`${process.env.REACT_APP_API_URL}/warehouse/${id}`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating warehouse!');
  }
});

export const deleteWarehouseDetails = createAsyncThunk<
  Pick<WarehouseType, 'id' | 'created_at' | 'modified_at'>,
  { id: number; fetchList: boolean },
  { state: RootState }
>('warehouses/deleteWarehouseDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<Pick<WarehouseType, 'id' | 'created_at' | 'modified_at'>>(
      `${process.env.REACT_APP_API_URL}/warehouse/${id}`
    );
    if (fetchList) thunkAPI.dispatch(getWarehousesList(thunkAPI.getState().warehousesReducer.warehousesRouteParams));
    thunkAPI.dispatch(changeModalState(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting warehouse!');
  }
});
