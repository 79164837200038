import {
  FETCH_USERS_DETAILS_STATUS,
  FETCH_USERS_LIST_STATUS,
  SET_USERS_DETAILS,
  SET_USERS_LIST,
  SET_ROUTE_PARAMS,
  SET_USER_ROLES_LIST,
  FETCH_USER_ROLES_LIST_STATUS,
  POST_AGREEMENT_STATUS,
  DELETE_AGREEMENT_STATUS,
} from '../constants';

export const initialUsersRouteParams = {
  page: 1,
  limit: 10,
  order: '-user.id',
  q: '',
  status: 'all',
  role: '',
};

const INIT_STATE = {
  userList: [],
  userRoles: [],
  userRolesFetchStatus: null,
  userListFetchStatus: null,
  agreementPostStatus: null,
  agreementDeleteStatus: null,
  usersDetails: null,
  usersDetailsFetchStatus: null,
  routeParams: initialUsersRouteParams,
};

export const usersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USERS_LIST:
      return { ...state, userList: action.payload };
    case POST_AGREEMENT_STATUS:
      return { ...state, agreementPostStatus: action.payload };
    case DELETE_AGREEMENT_STATUS:
      return { ...state, agreementDeleteStatus: action.payload };
    case FETCH_USERS_LIST_STATUS:
      return { ...state, userListFetchStatus: action.payload };
    case SET_USERS_DETAILS:
      return { ...state, usersDetails: action.payload };
    case FETCH_USERS_DETAILS_STATUS:
      return { ...state, usersDetailsFetchStatus: action.payload };
    case SET_USER_ROLES_LIST:
      return { ...state, userRoles: action.payload };
    case FETCH_USER_ROLES_LIST_STATUS:
      return { ...state, userRolesFetchStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'users') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
