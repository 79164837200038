import { SET_MODAL_CONTENT, SET_ROUTE_PARAMS, SHOW_MODAL } from '../constants/';

export function setRouteParams(payload) {
  return {
    type: SET_ROUTE_PARAMS,
    payload,
  };
}

export function changeModalState(payload) {
  return {
    type: SHOW_MODAL,
    payload,
  };
}

export function setModalContent(payload, size = 'md') {
  return {
    type: SET_MODAL_CONTENT,
    payload: {
      content: payload,
      size: size,
    },
  };
}
