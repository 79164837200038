import React, { JSXElementConstructor, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import NetworkService from '../utils/network.service';
import { AppDispatch, RootState, store } from '../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { socketService } from '../utils/socket.service';
import { getFullCountryCategoriesList } from '../redux/shared/shared.action';
import { getCurrentUser } from '../features/auth/auth.action';

const PrivateRoute = props => {
  const dispatch = useDispatch<AppDispatch>();
  const { user, tokenChecked } = useSelector((state: RootState) => state.authReducer);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      socketService.connectWithAuthToken(token);
      NetworkService.setupDefaultHeaders(token);
    } else {
      socketService.disconnect();
    }
    NetworkService.setupInterceptors(store);
    dispatch(getCurrentUser({ init: true }));

    return () => {
      socketService.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!user) return;
    if (tokenChecked) {
      setIsLoading(false);
      setIsAuthenticated(true);
      dispatch(getFullCountryCategoriesList());
    }
  }, [user]);

  const Component: JSXElementConstructor<any> = props.component;
  if (isLoading) return <></>;

  if (!isAuthenticated) return <Redirect to='/' />;

  return <Route {...props.rest} render={props => <Component {...props} />} />;
};
export default PrivateRoute;
