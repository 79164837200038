import { createSlice } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { BatteryDetailsType, BatteryListItemType } from '../../types/battery/battery.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deleteBatteryDetails,
  getBatteriesList,
  getBatteryDetails,
  patchBatteryDetails,
  postBatteries,
  postBatteryDetails,
} from './batteries.action';

export interface BatteriesState {
  batteriesList: null | PaginationType<BatteryListItemType>;
  batteriesListFetchStatus: FETCH_STATUS;
  batteriesRouteParams: RouteParamsType;
  batteryDetails: null | BatteryDetailsType;
  batteryDetailsFetchStatus: FETCH_STATUS;
  batteryDetailsPostStatus: FETCH_STATUS;
  batteryDetailsPatchStatus: FETCH_STATUS;
  batteryDetailsDeleteStatus: FETCH_STATUS;
  batteriesPostStatus: FETCH_STATUS;
  batteriesBulk: null | Array<{ brand: string; description: string; model: string; serial: string }>;
}

export const initialBatteriesRouteParams = {
  page: 1,
  limit: 10,
  order: '-battery.id',
  warehouse_id: '',
  q: '',
  equipment_status: 'all',
  in_service: 'all',
  country_id: '',
  country_category: '',
};

const INIT_STATE: BatteriesState = {
  batteriesRouteParams: initialBatteriesRouteParams,
  batteriesList: null,
  batteriesListFetchStatus: FETCH_STATUS.NULL,
  batteryDetails: null,
  batteryDetailsFetchStatus: FETCH_STATUS.NULL,
  batteryDetailsPostStatus: FETCH_STATUS.NULL,
  batteryDetailsPatchStatus: FETCH_STATUS.NULL,
  batteryDetailsDeleteStatus: FETCH_STATUS.NULL,
  batteriesPostStatus: FETCH_STATUS.NULL,
  batteriesBulk: null,
};

export const batteriesSlice = createSlice({
  name: 'batteries',
  initialState: INIT_STATE,
  reducers: {
    setBatteriesList: (state, action) => {
      state.batteriesList = action.payload;
    },
    setBatteriesListFetchStatus: (state, action) => {
      state.batteriesListFetchStatus = action.payload;
    },
    setBatteryDetails: (state, action) => {
      state.batteryDetails = action.payload;
    },
    setBatteryDetailsFetchStatus: (state, action) => {
      state.batteryDetailsFetchStatus = action.payload;
    },
    setBatteryDetailsPostStatus: (state, action) => {
      state.batteryDetailsPostStatus = action.payload;
    },
    setBatteryDetailsPatchStatus: (state, action) => {
      state.batteryDetailsPatchStatus = action.payload;
    },
    setBatteryDetailsDeleteStatus: (state, action) => {
      state.batteryDetailsDeleteStatus = action.payload;
    },
    setBatteriesBulk: (state, action) => {
      state.batteriesBulk = action.payload;
    },
    setBatteriesPostStatus: (state, action) => {
      state.batteriesPostStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'batteries') {
        state.batteriesRouteParams = { ...state.batteriesRouteParams, ...payload.data };
      }
    });

    // CREATE BATTERIES DETAILS
    builder.addCase(postBatteries.pending, state => {
      state.batteriesPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postBatteries.rejected, (state, action) => {
      state.batteriesPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postBatteries.fulfilled, state => {
      state.batteriesPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Batteries created successfully');
    });

    // CREATE BATTERIES DETAILS
    builder.addCase(postBatteryDetails.pending, state => {
      state.batteryDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postBatteryDetails.rejected, (state, action) => {
      state.batteryDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postBatteryDetails.fulfilled, state => {
      state.batteryDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Battery created successfully');
    });

    // GET BATTERIES LIST
    builder.addCase(getBatteriesList.pending, state => {
      state.batteriesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getBatteriesList.rejected, (state, action) => {
      state.batteriesListFetchStatus = FETCH_STATUS.REJECTED;
      state.batteriesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getBatteriesList.fulfilled, (state, action) => {
      state.batteriesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.batteriesList = action.payload;
    });

    // GET BATTERIES DETAILS
    builder.addCase(getBatteryDetails.pending, state => {
      state.batteryDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getBatteryDetails.rejected, (state, action) => {
      state.batteryDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.batteryDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getBatteryDetails.fulfilled, (state, action) => {
      state.batteryDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.batteryDetails = action.payload;
    });

    // PATCH BATTERIES DETAILS
    builder.addCase(patchBatteryDetails.pending, state => {
      state.batteryDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchBatteryDetails.rejected, (state, action) => {
      state.batteryDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchBatteryDetails.fulfilled, state => {
      state.batteryDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Battery updated successfully');
    });

    // DELETE BATTERIES DETAILS
    builder.addCase(deleteBatteryDetails.pending, state => {
      state.batteryDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteBatteryDetails.rejected, (state, action) => {
      state.batteryDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteBatteryDetails.fulfilled, state => {
      state.batteryDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Battery deleted successfully');
    });
  },
});

export const {
  setBatteriesList,
  setBatteriesListFetchStatus,
  setBatteryDetailsFetchStatus,
  setBatteryDetailsDeleteStatus,
  setBatteryDetailsPatchStatus,
  setBatteryDetailsPostStatus,
  setBatteryDetails,
  setBatteriesPostStatus,
  setBatteriesBulk,
} = batteriesSlice.actions;

export default batteriesSlice.reducer;
