import { createSlice } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import { PbxExtensionDetailsType, PbxExtensionListItemType } from '../../types/pbx-extension/pbx-extension.type';
import {
  deletePbxExtensionDetails,
  getPbxExtensionDetails,
  getPbxExtensionsList,
  patchPbxExtensionDetails,
  postPBXExtensionDetails,
} from './pbxExtensions.action';

export interface PbxExtensionsState {
  pbxExtensionsList: null | PaginationType<PbxExtensionListItemType>;
  pbxExtensionsListFetchStatus: FETCH_STATUS;
  pbxExtensionsRouteParams: RouteParamsType;
  pbxExtensionDetails: null | PbxExtensionDetailsType;
  pbxExtensionDetailsFetchStatus: FETCH_STATUS;
  pbxExtensionDetailsPatchStatus: FETCH_STATUS;
  pbxExtensionDetailsDeleteStatus: FETCH_STATUS;
  pbxExtensionDetailsPostStatus: FETCH_STATUS;
}

export const initialPbxExtensionsRouteParams = {
  page: 1,
  limit: 10,
  order: 'pbxExtension.id',
  q: '',
};

const INIT_STATE: PbxExtensionsState = {
  pbxExtensionsRouteParams: initialPbxExtensionsRouteParams,
  pbxExtensionsList: null,
  pbxExtensionsListFetchStatus: FETCH_STATUS.NULL,
  pbxExtensionDetails: null,
  pbxExtensionDetailsFetchStatus: FETCH_STATUS.NULL,
  pbxExtensionDetailsPatchStatus: FETCH_STATUS.NULL,
  pbxExtensionDetailsDeleteStatus: FETCH_STATUS.NULL,
  pbxExtensionDetailsPostStatus: FETCH_STATUS.NULL,
};

const pbxExtensionsSlice = createSlice({
  name: 'pbxExtensions',
  initialState: INIT_STATE,
  reducers: {
    setPbxExtensionsList: (state, action) => {
      state.pbxExtensionsList = action.payload;
    },
    setPbxExtensionsListFetchStatus: (state, action) => {
      state.pbxExtensionsListFetchStatus = action.payload;
    },
    setPbxExtensionDetails: (state, action) => {
      state.pbxExtensionDetails = action.payload;
    },
    setPbxExtensionDetailsFetchStatus: (state, action) => {
      state.pbxExtensionDetailsFetchStatus = action.payload;
    },
    setPbxExtensionDetailsPatchStatus: (state, action) => {
      state.pbxExtensionDetailsPatchStatus = action.payload;
    },
    setPbxExtensionDetailsDeleteStatus: (state, action) => {
      state.pbxExtensionDetailsDeleteStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'pbxExtensions') {
        state.pbxExtensionsRouteParams = { ...state.pbxExtensionsRouteParams, ...payload.data };
      }
    });

    // CREATE PBX EXTENSIONS DETAILS
    builder.addCase(postPBXExtensionDetails.pending, state => {
      state.pbxExtensionDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postPBXExtensionDetails.rejected, (state, action) => {
      state.pbxExtensionDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postPBXExtensionDetails.fulfilled, state => {
      state.pbxExtensionDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PBX Extension created successfully');
    });

    // GET PBX EXTENSIONS LIST
    builder.addCase(getPbxExtensionsList.pending, state => {
      state.pbxExtensionsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPbxExtensionsList.rejected, (state, action) => {
      state.pbxExtensionsListFetchStatus = FETCH_STATUS.REJECTED;
      state.pbxExtensionsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPbxExtensionsList.fulfilled, (state, action) => {
      state.pbxExtensionsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.pbxExtensionsList = action.payload;
    });

    // GET PBX EXTENSIONS DETAILS
    builder.addCase(getPbxExtensionDetails.pending, state => {
      state.pbxExtensionDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPbxExtensionDetails.rejected, (state, action) => {
      state.pbxExtensionDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.pbxExtensionDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPbxExtensionDetails.fulfilled, (state, action) => {
      state.pbxExtensionDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.pbxExtensionDetails = action.payload;
    });

    // PATCH PBX EXTENSIONS DETAILS
    builder.addCase(patchPbxExtensionDetails.pending, state => {
      state.pbxExtensionDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchPbxExtensionDetails.rejected, (state, action) => {
      state.pbxExtensionDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchPbxExtensionDetails.fulfilled, state => {
      state.pbxExtensionDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PbxExtensions updated successfully');
    });

    // DELETE PBX EXTENSIONS DETAILS
    builder.addCase(deletePbxExtensionDetails.pending, state => {
      state.pbxExtensionDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deletePbxExtensionDetails.rejected, (state, action) => {
      state.pbxExtensionDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deletePbxExtensionDetails.fulfilled, state => {
      state.pbxExtensionDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PbxExtensions deleted successfully');
    });
  },
});

export const {
  setPbxExtensionDetailsDeleteStatus,
  setPbxExtensionDetailsFetchStatus,
  setPbxExtensionDetailsPatchStatus,
  setPbxExtensionsListFetchStatus,
  setPbxExtensionsList,
  setPbxExtensionDetails,
} = pbxExtensionsSlice.actions;

export default pbxExtensionsSlice.reducer;
