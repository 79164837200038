import {
  FETCH_CHECK_DOMAIN_STATUS,
  SET_DOMAIN_DATA,
  SET_FULL_ASYNC_TASK_TYPES_LIST,
  SET_FULL_ASYNC_TASK_TYPES_LIST_FETCH_STATUS,
  SET_FULL_ATA_BASE_TEMPLATE_LIST,
  SET_FULL_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS,
  SET_FULL_ATA_ORG_TEMPLATE_LIST,
  SET_FULL_ATA_ORG_TEMPLATE_LIST_FETCH_STATUS,
  SET_FULL_ATA_TYPE_LIST,
  SET_FULL_ATA_TYPE_LIST_FETCH_STATUS,
  SET_FULL_DID_LIST_FETCH_STATUS,
  SET_FULL_EQUIPMENT_GROUP_LIST,
  SET_FULL_EQUIPMENT_GROUP_LIST_FETCH_STATUS,
  SET_FULL_LOCATION_LIST,
  SET_FULL_LOCATION_LIST_FETCH_STATUS,
  SET_FULL_ORGANIZATION_LIST,
  SET_FULL_ORGANIZATION_LIST_FETCH_STATUS,
  SET_FULL_PBX_LIST,
  SET_FULL_PBX_LIST_FETCH_STATUS,
  SET_FULL_ROUTER_LIST,
  SET_FULL_ROUTER_LIST_FETCH_STATUS,
  SET_FULL_SERVICE_LIST,
  SET_FULL_SERVICE_LIST_FETCH_STATUS,
  SET_FULL_SERVICE_TYPE_LIST,
  SET_FULL_SERVICE_TYPE_LIST_FETCH_STATUS,
  SET_SELECTED_COMPANY,
  SET_TIMEZONE,
  SET_SELECTED_LOCATION,
  SET_INCLUDE_SUBORGS,
  SET_FULL_DIALER_TYPES_LIST,
  SET_FULL_DIALER_TYPES_LIST_FETCH_STATUS,
  SET_FULL_ENCLOSURE_TYPES_LIST,
  SET_FULL_ENCLOSURE_TYPES_LIST_FETCH_STATUS,
  SET_FULL_WAREHOUSE_LIST,
  SET_FULL_WAREHOUSE_LIST_FETCH_STATUS,
  SET_FULL_ATAS_ACS_PROFILE_LIST,
  SET_FULL_ATAS_ACS_PROFILE_LIST_FETCH_STATUS,
  SET_FULL_TC_BLOCK_LIST_FETCH_STATUS,
  SET_FULL_TC_BLOCK_LIST,
  SET_FULL_LINE_TYPES_LIST,
  SET_FULL_LINE_TYPES_LIST_FETCH_STATUS,
  SET_FULL_SIM_LIST,
  SET_FULL_SIM_LIST_FETCH_STATUS,
  SET_FULL_DATA_PLAN_TYPE_LIST,
  SET_FULL_DATA_PLAN_TYPE_LIST_FETCH_STATUS,
  SET_JOT_FORM_URL_FETCH_STATUS,
  SET_JOT_FORM_URL,
  SET_FULL_SERVICE_TYPE_CATEGORY_LIST,
  SET_FULL_SERVICE_TYPE_CATEGORY_LIST_FETCH_STATUS,
  SET_FULL_ROUTER_PRODUCT_NAME_LIST,
  SET_FULL_ROUTER_PRODUCT_NAME_LIST_FETCH_STATUS,
  SET_FULL_VERIFIED_EMAILS_LIST,
  SET_FULL_VERIFIED_EMAILS_FETCH_STATUS,
  SET_FULL_VERIFIED_NUMBERS_FETCH_STATUS,
  SET_FULL_VERIFIED_NUMBERS_LIST,
  SET_FULL_ISSUES_TRACKER_CODES_LIST,
  SET_FULL_ISSUES_TRACKER_CODES_LIST_FETCH_STATUS,
  SET_SELECTED_USER_ACCESS,
  SET_FULL_SIM_CARRIER_LIST,
  SET_FULL_SIM_CARRIER_LIST_FETCH_STATUS,
  SET_SELECTED_COUNTRY,
  SET_FULL_COUNTRIES_LIST,
  SET_FULL_COUNTRIES_LIST_FETCH_STATUS,
  SET_FULL_COUNTRY_CATEGORIES_LIST,
  SET_FULL_COUNTRY_CATEGORIES_LIST_FETCH_STATUS,
  SET_FULL_GROUPED_COUNTRIES_LIST,
  SET_FULL_AUDIT_ENTITY_TYPES,
  SET_FULL_AUDIT_ENTITY_TYPES_FETCH_STATUS,
  SET_FULL_EXTERNAL_SYSTEM_LIST,
  SET_FULL_EXTERNAL_SYSTEM_LIST_FETCH_STATUS,
  SET_FULL_EXTERNAL_PROVIDER_LIST,
  SET_FULL_BUCKETS_LIST,
  SET_FULL_BUCKETS_LIST_FETCH_STATUS,
  SET_FULL_PROFILE_LIST,
  SET_CACHED_FULL_COUNTRIES_LIST,
  SET_CACHED_FULL_GROUPED_COUNTRIES_LIST,
} from '../constants/';
import SnackbarUtils from '../../utils/SnackbarUtils';
import axios from 'axios';
import { ROLECONSTANTS } from '../utils/utils.constants';
import { RequestStatus } from '../../utils/RequestStatus';
import { AppDispatch, DispatchAction, RootState } from '../Store';
import { groupBy } from '../../utils/utilities';
import { ORG_LEVEL } from '../../utils/constants';

const readTree = (company, y, parent_id, bobo_enabled?) => {
  let ar: any = [];
  if (company) {
    if (company.level === ORG_LEVEL.RESELLER) {
      bobo_enabled = company.bobo_enabled;
    }
    ar.push({
      id: company.id,
      name: `${y} ${company.name}`,
      level: company.level,
      parent_id,
      bobo_enabled,
    });
    if (company.children) {
      company.children.forEach(x => {
        ar = [...ar, ...readTree(x, y + '\t-', company.id, company.bobo_enabled || bobo_enabled)];
      });
    } else return ar;
  }
  return ar;
};

export const getFullOrganizationList = (
  orgId: number | null | string = null,
  parentFor: any = null,
  routerOrganizations = false,
  strictCondition = false
) => {
  return function (dispatch: AppDispatch, getState: () => RootState): void {
    const user = getState().authReducer.user;
    const isSuperAdmin = user.role === ROLECONSTANTS.superadmin;
    const params = { limit: 1000, page: 1, order: 'organization.id' };
    if (isSuperAdmin && !strictCondition) {
      params['org_id'] = user.organization.id;
    } else {
      if (orgId === 'all') {
        params['org_id'] = user.organization.id;
      } else {
        if (orgId) params['org_id'] = orgId;
      }
    }
    if (parentFor !== null) {
      params['parent_for'] = parentFor;
    }
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');

    let url;
    if (!routerOrganizations) {
      url = `${process.env.REACT_APP_API_URL}/organizations/tree${queryString ? `?${queryString}` : ''}`;
    } else {
      const routerDetails = getState().routersReducer.routersDetails;
      url = `${process.env.REACT_APP_API_URL}/router/tree?sn=${routerDetails?.serial_number}`;
    }

    dispatch(setFullOrganizationListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        let obj = [];
        if (response.data?.id) {
          obj = readTree(response.data, '', null);
        }
        dispatch(setFullOrganizationList(obj));
        dispatch(setFullOrganizationListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullOrganizationListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting full organization list!'
        );
      });
  };
};

export function setFullOrganizationList(payload) {
  return {
    type: SET_FULL_ORGANIZATION_LIST,
    payload,
  };
}

export function setFullOrganizationListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_ORGANIZATION_LIST_FETCH_STATUS,
    payload,
  };
}

// SETTINGS

export function setSelectedCompany(payload) {
  return {
    type: SET_SELECTED_COMPANY,
    payload,
  };
}

export function setSelectedUserAccess(payload) {
  return {
    type: SET_SELECTED_USER_ACCESS,
    payload,
  };
}

export function setSelectedLocation(payload) {
  return {
    type: SET_SELECTED_LOCATION,
    payload,
  };
}

export function setSelectedCountry(payload) {
  return {
    type: SET_SELECTED_COUNTRY,
    payload,
  };
}

export function setIncludeSuborgs(payload) {
  return {
    type: SET_INCLUDE_SUBORGS,
    payload,
  };
}

export function setTimezone(payload) {
  return {
    type: SET_TIMEZONE,
    payload,
  };
}

export const checkDomain = params => {
  const queryString = Object.keys(params || {})
    .map(key => key + '=' + params[key])
    .join('&');
  return function (dispatch: AppDispatch) {
    dispatch(checkDomainFetchStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/organizations/brand/${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setDomainData(response.data));
        dispatch(checkDomainFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting organization details!'
        );
        dispatch(checkDomainFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function checkDomainFetchStatus(payload) {
  return {
    type: FETCH_CHECK_DOMAIN_STATUS,
    payload,
  };
}

export function setDomainData(payload) {
  return {
    type: SET_DOMAIN_DATA,
    payload,
  };
}

export const getFullLocationList = (data = {}) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setFullLocationListFetchStatus(RequestStatus.status.FETCHING));
    const params = { limit: 1000, page: 1, order: 'id' };
    Object.keys(data).forEach(key => {
      params[key] = data[key];
    });
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/location${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullLocationList(response.data.items));
        dispatch(setFullLocationListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullLocationListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting full Location list!'
        );
      });
  };
};

export function setFullLocationList(payload) {
  return {
    type: SET_FULL_LOCATION_LIST,
    payload,
  };
}

export function setFullLocationListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_LOCATION_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullRouterList = (data = {}) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setFullRouterListFetchStatus(RequestStatus.status.FETCHING));
    const params = { limit: 1000, page: 1, order: 'id' };
    Object.keys(data).forEach(key => {
      params[key] = data[key];
    });
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/routers${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullRouterList(response.data.items));
        dispatch(setFullRouterListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullRouterListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting full router list!'
        );
      });
  };
};

export function setFullRouterList(payload) {
  return {
    type: SET_FULL_ROUTER_LIST,
    payload,
  };
}

export function setFullRouterListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_ROUTER_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullServiceList = (locationId: number) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setFullServiceListFetchStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/location/${locationId}/services`;
    axios
      .get(url)
      .then(response => {
        const services = response.data?.services;
        dispatch(setFullServiceList(services || []));
        dispatch(setFullServiceListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting services details!'
        );
        dispatch(setFullServiceListFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setFullServiceList(payload) {
  return {
    type: SET_FULL_SERVICE_LIST,
    payload,
  };
}

export function setFullServiceListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_SERVICE_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullSimsList = (data = {}) => {
  return function (dispatch: AppDispatch): void {
    const params = { limit: 1000, page: 1, order: 'id' };
    Object.keys(data).forEach(key => {
      params[key] = data[key];
    });
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/tc-sim${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullSimsList(response.data?.items || []));
        dispatch(setFullSimsListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting services details!'
        );
        dispatch(setFullSimsListFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setFullSimsList(payload) {
  return {
    type: SET_FULL_SIM_LIST,
    payload,
  };
}

export function setFullSimsListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_SIM_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullDataPlanTypeList = (data = {}) => {
  return function (dispatch: AppDispatch): void {
    const params = { limit: 1000, page: 1, order: 'id' };
    Object.keys(data).forEach(key => {
      params[key] = data[key];
    });
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/data-plans/types${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullDataPlanTypeList(response.data?.items || []));
        dispatch(setFullDataPlanTypeListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting services details!'
        );
        dispatch(setFullDataPlanTypeListFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setFullDataPlanTypeList(payload) {
  return {
    type: SET_FULL_DATA_PLAN_TYPE_LIST,
    payload,
  };
}

export function setFullDataPlanTypeListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_DATA_PLAN_TYPE_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullServiceTypeList = (data = {}) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setFullServiceTypeListFetchStatus(RequestStatus.status.FETCHING));
    const params = { limit: 1000, page: 1, order: 'id' };
    Object.keys(data).forEach(key => {
      params[key] = data[key];
    });
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/tc-service-type${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullServiceTypeList(response.data.items));
        dispatch(setFullServiceTypeListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullServiceTypeListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting full Service Type list!'
        );
      });
  };
};

export function setFullServiceTypeList(payload) {
  return {
    type: SET_FULL_SERVICE_TYPE_LIST,
    payload,
  };
}

export function setFullServiceTypeListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_SERVICE_TYPE_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullServiceTypeCategoryList = (data = {}) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setFullServiceTypeListCategoryFetchStatus(RequestStatus.status.FETCHING));
    const params = { limit: 1000, page: 1, order: 'id' };
    Object.keys(data).forEach(key => {
      params[key] = data[key];
    });
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/tc-service-type/categories${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        const items: any = [];
        response.data.forEach(x => {
          items.push({ id: x, name: x });
        });
        dispatch(setFullServiceTypeCategoryList(items));
        dispatch(setFullServiceTypeListCategoryFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullServiceTypeListCategoryFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting full Service Type list!'
        );
      });
  };
};

export function setFullServiceTypeCategoryList(payload) {
  return {
    type: SET_FULL_SERVICE_TYPE_CATEGORY_LIST,
    payload,
  };
}

export function setFullServiceTypeListCategoryFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_SERVICE_TYPE_CATEGORY_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullEquipmentGroupList = (data = {}) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setFullEquipmentGroupListFetchStatus(RequestStatus.status.FETCHING));
    const params = { limit: 1000, page: 1, order: 'id' };
    Object.keys(data).forEach(key => {
      params[key] = data[key];
    });
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/equipment-group${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullEquipmentGroupList(response.data.items));
        dispatch(setFullEquipmentGroupListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullEquipmentGroupListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting full Equipment Group list!'
        );
      });
  };
};

export function setFullEquipmentGroupList(payload) {
  return {
    type: SET_FULL_EQUIPMENT_GROUP_LIST,
    payload,
  };
}

export function setFullEquipmentGroupListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_EQUIPMENT_GROUP_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullPBXList = (data = {}) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setFullPBXListFetchStatus(RequestStatus.status.FETCHING));
    const params = { limit: 1000, page: 1, order: 'id' };
    Object.keys(data).forEach(key => {
      if (data[key] !== undefined) {
        params[key] = data[key];
      }
    });
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/pbxs${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullPBXList(response.data.items));
        dispatch(setFullPBXListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullPBXListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting full PBX list!'
        );
      });
  };
};

export function setFullPBXList(payload) {
  return {
    type: SET_FULL_PBX_LIST,
    payload,
  };
}

export function setFullPBXListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_PBX_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullATATypeList = (data = {}) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setFullATATypeListFetchStatus(RequestStatus.status.FETCHING));
    const params = { limit: 1000 };
    Object.keys(data).forEach(key => {
      params[key] = data[key];
    });
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/atas/types${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullATATypeList(response.data.items));
        dispatch(setFullATATypeListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullATATypeListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting full ATA Type list!'
        );
      });
  };
};

export function setFullATATypeList(payload) {
  return {
    type: SET_FULL_ATA_TYPE_LIST,
    payload,
  };
}

export function setFullATATypeListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_ATA_TYPE_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullDialerTypesList = (params = {}) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setFullDialerTypesListFetchStatus(RequestStatus.status.FETCHING));
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/dialer-type${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullDialerTypesList(response.data.items));
        dispatch(setFullDialerTypesListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting full Dialer Types list!'
        );
        dispatch(setFullDialerTypesListFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setFullDialerTypesList(payload) {
  return {
    type: SET_FULL_DIALER_TYPES_LIST,
    payload,
  };
}

export function setFullDialerTypesListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_DIALER_TYPES_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullATAOrgTemplateList = (data = {}) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setFullATAOrgTemplateListFetchStatus(RequestStatus.status.FETCHING));
    const params = { limit: 10000, page: 1, order: 'ata_org_template.id' };
    Object.keys(data).forEach(key => {
      params[key] = data[key];
    });
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/ata-org-templates${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullATAOrgTemplateList(response.data.items));
        dispatch(setFullATAOrgTemplateListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullATAOrgTemplateListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting full ATA Type list!'
        );
      });
  };
};

export function setFullATAOrgTemplateList(payload) {
  return {
    type: SET_FULL_ATA_ORG_TEMPLATE_LIST,
    payload,
  };
}

export function setFullATAOrgTemplateListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_ATA_ORG_TEMPLATE_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullATABaseTemplateList = () => {
  return function (dispatch: AppDispatch): void {
    const params = { limit: 1000, page: 1, order: 'ata_base_template.id' };
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/ata-base-templates${queryString ? `?${queryString}` : ''}`;
    dispatch(setFullATABaseTemplateListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullATABaseTemplateList(response.data.items));
        dispatch(setFullATABaseTemplateListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting full ATA Base Templates list!'
        );
        dispatch(setFullATABaseTemplateListFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setFullATABaseTemplateList(payload) {
  return {
    type: SET_FULL_ATA_BASE_TEMPLATE_LIST,
    payload,
  };
}

export function setFullATABaseTemplateListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS,
    payload,
  };
}

export function setFullDIDListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_DID_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullAsyncTaskTypesList = () => {
  return function (dispatch: AppDispatch): void {
    const params = { limit: 1000, page: 1 };
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/async-task/types${queryString ? `?${queryString}` : ''}`;
    dispatch(setFullAsyncTaskTypesListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        const items: any = [];
        response.data.forEach(x => {
          items.push({ name: x, id: x });
        });
        dispatch(setFullAsyncTaskTypesList(items));
        dispatch(setFullAsyncTaskTypesListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting async task types list!'
        );
        dispatch(setFullAsyncTaskTypesListFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setFullAsyncTaskTypesList(payload) {
  return {
    type: SET_FULL_ASYNC_TASK_TYPES_LIST,
    payload,
  };
}

export function setFullAsyncTaskTypesListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_ASYNC_TASK_TYPES_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullBucketsList = () => {
  return function (dispatch: AppDispatch): void {
    const params = { limit: 1000, page: 1 };
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/secure-alarm/bucket${queryString ? '?' + queryString : ''}`;
    dispatch(setFullBucketsListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullBucketsList(response.data?.items || []));
        dispatch(setFullBucketsListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting buckets list!'
        );
        dispatch(setFullBucketsListFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setFullBucketsList(payload) {
  return {
    type: SET_FULL_BUCKETS_LIST,
    payload,
  };
}

export function setFullBucketsListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_BUCKETS_LIST_FETCH_STATUS,
    payload,
  };
}

export function getFullEnclosureTypesList() {
  return function (dispatch: AppDispatch): void {
    const url = `${process.env.REACT_APP_API_URL}/enclosure-types?order=enclosure_type.id`;
    dispatch(setFullEnclosureTypesListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullEnclosureTypesList(response.data?.items || []));
        dispatch(setFullEnclosureTypesListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullEnclosureTypesListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting Full Enclosure List!'
        );
      });
  };
}

export function setFullEnclosureTypesList(payload) {
  return {
    type: SET_FULL_ENCLOSURE_TYPES_LIST,
    payload,
  };
}

export function setFullEnclosureTypesListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_ENCLOSURE_TYPES_LIST_FETCH_STATUS,
    payload,
  };
}

export function getFullCountryCategoriesList() {
  return function (dispatch: AppDispatch): void {
    const url = `${process.env.REACT_APP_API_URL}/countries/categories`;
    dispatch(setFullCountryCategoriesListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get<Array<string>>(url)
      .then(response => {
        dispatch(setFullCountryCategoriesList(response.data.map(s => ({ name: s, id: s })) || []));
        dispatch(setFullCountryCategoriesListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullCountryCategoriesListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting Full country categories List!'
        );
      });
  };
}

export function setFullCountryCategoriesList(
  payload: Array<{ id: string; name: string }>
): DispatchAction<Array<{ id: string; name: string }>> {
  return {
    type: SET_FULL_COUNTRY_CATEGORIES_LIST,
    payload,
  };
}

export function setFullCountryCategoriesListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_COUNTRY_CATEGORIES_LIST_FETCH_STATUS,
    payload,
  };
}

export function getFullWarehouseList(params = {}) {
  const mergedParams = { ...params, limit: 1000, inc_sub: false };
  const queryString = Object.keys(mergedParams || {})
    .map(key => key + '=' + mergedParams[key])
    .join('&');

  return function (dispatch: AppDispatch): void {
    const url = `${process.env.REACT_APP_API_URL}/warehouse${queryString ? `?${queryString}` : ''}`;
    dispatch(setFullWarehouseListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullWarehouseList(response.data?.items || []));
        dispatch(setFullWarehouseListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullWarehouseListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting Full Warehouse List!'
        );
      });
  };
}

export function setFullWarehouseList(payload) {
  return {
    type: SET_FULL_WAREHOUSE_LIST,
    payload,
  };
}

export function setFullWarehouseListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_WAREHOUSE_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullATAACSProfileList = (ataId: number) => {
  return function (dispatch: AppDispatch): void {
    const url = `${process.env.REACT_APP_API_URL}/acs/profiles?ataId=${ataId}`;
    dispatch(setFullATAACSProfileListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullATAACSProfileList(response.data || []));
        dispatch(setFullATAACSProfileListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullATAACSProfileListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting Profile List!'
        );
      });
  };
};

export function setFullATAACSProfileList(payload) {
  return {
    type: SET_FULL_ATAS_ACS_PROFILE_LIST,
    payload,
  };
}

export function setFullATAACSProfileListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_ATAS_ACS_PROFILE_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullTCBlockList = () => {
  return function (dispatch: AppDispatch): void {
    const url = `${process.env.REACT_APP_API_URL}/tc-block`;
    dispatch(setFullTCBlockListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullTCBlockList(response.data.items || []));
        dispatch(setFullTCBlockListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullTCBlockListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting TC Block List!'
        );
      });
  };
};

export function setFullTCBlockList(payload) {
  return {
    type: SET_FULL_TC_BLOCK_LIST,
    payload,
  };
}

export function setFullTCBlockListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_TC_BLOCK_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullLineTypesList = () => {
  return function (dispatch: AppDispatch): void {
    const url = `${process.env.REACT_APP_API_URL}/line-types`;
    dispatch(setFullLineTypesListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullLineTypesList(response.data.items || []));
        dispatch(setFullLineTypesListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullLineTypesListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting Line Types List!'
        );
      });
  };
};

export function setFullLineTypesList(payload) {
  return {
    type: SET_FULL_LINE_TYPES_LIST,
    payload,
  };
}

export function setFullLineTypesListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_LINE_TYPES_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullVerifiedNumbersList = (params = {}) => {
  return function (dispatch: AppDispatch): void {
    const mergedParams = { ...params, limit: 1000 };
    const queryString = Object.keys(mergedParams || {})
      .map(key => key + '=' + mergedParams[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/verification/number${queryString ? `?${queryString}` : ''}`;

    dispatch(setFullVerifiedNumbersListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullVerifiedNumbersList(response.data.items || []));
        dispatch(setFullVerifiedNumbersListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullVerifiedNumbersListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting emails List!'
        );
      });
  };
};

export function setFullVerifiedNumbersList(payload) {
  return {
    type: SET_FULL_VERIFIED_NUMBERS_LIST,
    payload,
  };
}

export function setFullVerifiedNumbersListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_VERIFIED_NUMBERS_FETCH_STATUS,
    payload,
  };
}

export const getFullVerifiedEmailsList = (params = {}) => {
  return function (dispatch: AppDispatch): void {
    const mergedParams = { ...params, limit: 1000 };
    const queryString = Object.keys(mergedParams || {})
      .map(key => key + '=' + mergedParams[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/verification/email${queryString ? `?${queryString}` : ''}`;

    dispatch(setFullVerifiedEmailsListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullVerifiedEmailsList(response.data.items || []));
        dispatch(setFullVerifiedEmailsListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullVerifiedEmailsListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting emails List!'
        );
      });
  };
};

export function setFullVerifiedEmailsList(payload) {
  return {
    type: SET_FULL_VERIFIED_EMAILS_LIST,
    payload,
  };
}

export function setFullVerifiedEmailsListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_VERIFIED_EMAILS_FETCH_STATUS,
    payload,
  };
}

export const getFullIssuesTrackerCodesList = () => {
  return function (dispatch: AppDispatch): void {
    const url = `${process.env.REACT_APP_API_URL}/issue-tracker/codes`;
    dispatch(setFullIssuesTrackerCodesFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullIssuesTrackerCodes(response.data));
        dispatch(setFullIssuesTrackerCodesFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullIssuesTrackerCodesFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting Issues Codes List!'
        );
      });
  };
};

export function setFullIssuesTrackerCodes(payload) {
  return {
    type: SET_FULL_ISSUES_TRACKER_CODES_LIST,
    payload,
  };
}

export function setFullIssuesTrackerCodesFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_ISSUES_TRACKER_CODES_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullCountriesList = (params: any = {}) => {
  return function (dispatch: AppDispatch, getState: () => RootState): void {
    const mergedParams = { ...params };
    if (!mergedParams.org_id) {
      mergedParams.org_id =
        getState().sharedReducer.selectedCompany.id === 'all'
          ? getState().authReducer.user.organization.id
          : getState().sharedReducer.selectedCompany.id;
    }
    const queryString = Object.keys(mergedParams || {})
      .map(key => key + '=' + mergedParams[key])
      .join('&');
    const cachedCountriesByOrganizationID = getState().sharedReducer.cachedCountriesByOrganizationID;
    const cachedGroupedCountriesByOrganizationID = getState().sharedReducer.cachedGroupedCountriesByOrganizationID;
    if (Object.keys(cachedCountriesByOrganizationID || {}).find(key => +key === +mergedParams.org_id)) {
      dispatch(setFullGroupedCountries(cachedGroupedCountriesByOrganizationID[mergedParams.org_id] || []));
      dispatch(setFullCountries(cachedCountriesByOrganizationID[mergedParams.org_id] || []));
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/countries/short${queryString ? `?${queryString}` : ''}`;
    dispatch(setFullCountriesFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        const groupedCountries = groupBy(response.data.items, 'category');
        const fullCountriesList = Object.keys(groupedCountries).map(key => ({
          name: key,
          options: groupedCountries[key],
        }));
        dispatch(setFullGroupedCountries(fullCountriesList || []));
        dispatch(setFullCountries(response.data.items || []));
        dispatch(setCachedFullCountries({ [mergedParams.org_id]: response.data.items || [] }));
        dispatch(setCachedFullGroupedCountries({ [mergedParams.org_id]: fullCountriesList || [] }));
        dispatch(setFullCountriesFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullCountriesFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting Countries List!'
        );
      });
  };
};

export function setCachedFullCountries(payload) {
  return {
    type: SET_CACHED_FULL_COUNTRIES_LIST,
    payload,
  };
}

export function setCachedFullGroupedCountries(payload) {
  return {
    type: SET_CACHED_FULL_GROUPED_COUNTRIES_LIST,
    payload,
  };
}

export function setFullCountries(payload) {
  return {
    type: SET_FULL_COUNTRIES_LIST,
    payload,
  };
}

export function setFullGroupedCountries(payload) {
  return {
    type: SET_FULL_GROUPED_COUNTRIES_LIST,
    payload,
  };
}

export function setFullCountriesFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_COUNTRIES_LIST_FETCH_STATUS,
    payload,
  };
}

export const getJotFormURL = () => {
  return function (dispatch: AppDispatch, getState: () => RootState): void {
    const user = getState().authReducer.user;
    const selectedCompany = getState().sharedReducer.selectedCompany;
    const url = `${process.env.REACT_APP_API_URL}/organizations/${
      selectedCompany?.id === 'all' ? user?.organization?.id : selectedCompany?.id
    }/jot-form`;
    dispatch(setJotFormURLFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setJotFormURL(response.data || ''));
        dispatch(setJotFormURLFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setJotFormURLFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting jot form URL!'
        );
      });
  };
};

export function setJotFormURL(payload) {
  return {
    type: SET_JOT_FORM_URL,
    payload,
  };
}

export function setJotFormURLFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_JOT_FORM_URL_FETCH_STATUS,
    payload,
  };
}

export const getRouterProductNameTypesList = () => {
  return function (dispatch: AppDispatch): void {
    const url = `${process.env.REACT_APP_API_URL}/routers/product-names`;
    dispatch(setFullRouterProductNameListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullRouterProductNameList(response.data?.items || []));
        dispatch(setFullRouterProductNameListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullRouterProductNameListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting Product Names List!'
        );
      });
  };
};

export function setFullRouterProductNameList(payload) {
  return {
    type: SET_FULL_ROUTER_PRODUCT_NAME_LIST,
    payload,
  };
}

export function setFullRouterProductNameListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_ROUTER_PRODUCT_NAME_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullAuditEntityTypes = () => {
  return function (dispatch: AppDispatch): void {
    const url = `${process.env.REACT_APP_API_URL}/tools/audit-logs/entities`;
    dispatch(setFullAuditEntityTypesFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullAuditEntityTypes(response.data || []));
        dispatch(setFullAuditEntityTypesFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullAuditEntityTypesFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting audit entity types!'
        );
      });
  };
};

export function setFullAuditEntityTypes(payload) {
  return {
    type: SET_FULL_AUDIT_ENTITY_TYPES,
    payload,
  };
}

export function setFullAuditEntityTypesFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_AUDIT_ENTITY_TYPES_FETCH_STATUS,
    payload,
  };
}

export const getFullExternalSystemList = (type: 'TELCO' | 'PBX', organizationID: number | null, telcoID?: number) => {
  return function (dispatch: AppDispatch): void {
    const orgParam = !organizationID ? '' : `&org_id=${organizationID}`;
    const telcoParam = telcoID ? `&telcoID=${telcoID}` : '';
    const url = `${process.env.REACT_APP_API_URL}/external-systems?type=${type || ''}${orgParam}${telcoParam}`;
    dispatch(setFullExternalSystemListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        if (type === 'PBX') dispatch(setFullExternalSystemList(response.data || []));
        if (type === 'TELCO') dispatch(setFullExternalProviderList(response.data || []));
        dispatch(setFullExternalSystemListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullExternalSystemListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting external systems list!'
        );
      });
  };
};

export function setFullExternalSystemList(payload) {
  return {
    type: SET_FULL_EXTERNAL_SYSTEM_LIST,
    payload,
  };
}

export function setFullExternalProviderList(payload) {
  return {
    type: SET_FULL_EXTERNAL_PROVIDER_LIST,
    payload,
  };
}

export function setFullExternalSystemListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_EXTERNAL_SYSTEM_LIST_FETCH_STATUS,
    payload,
  };
}

export const getSIMCarrierList = () => {
  return function (dispatch: AppDispatch): void {
    const url = `${process.env.REACT_APP_API_URL}/tc-sim/carrier`;
    dispatch(setFullSIMCarrierListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullSIMCarrierList(response.data?.items || []));
        dispatch(setFullSIMCarrierListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullSIMCarrierListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting Carriers List!'
        );
      });
  };
};

export function setFullSIMCarrierList(payload) {
  return {
    type: SET_FULL_SIM_CARRIER_LIST,
    payload,
  };
}

export function setFullSIMCarrierListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_SIM_CARRIER_LIST_FETCH_STATUS,
    payload,
  };
}

export const getFullProfileList = () => {
  return function (dispatch: AppDispatch) {
    const url = `${process.env.REACT_APP_API_URL}/atas/profiles/`;
    axios
      .get(url)
      .then(response => {
        dispatch(setFullProfileList(response.data || []));
      })
      .catch(() => {
        dispatch(setFullProfileList([]));
      });
  };
};

export function setFullProfileList(payload) {
  return {
    type: SET_FULL_PROFILE_LIST,
    payload,
  };
}
