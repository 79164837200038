import React from 'react';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/Store';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './utils/SnackbarUtils';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Spinner from './components/Other/Spinner/Spinner';
import Router from './routes/Router/Router';

const App = (): JSX.Element => {
  return (
    <SnackbarProvider preventDuplicate={true} maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
      <SnackbarUtilsConfigurator />
      <Provider store={store}>
        <PersistGate loading={<Spinner />} persistor={persistor}>
          <Router />
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  );
};

export default App;
