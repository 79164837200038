import {
  SET_XML_REQUEST_LOGS_LIST,
  FETCH_XML_REQUEST_LOGS_LIST_STATUS,
  SET_ROUTE_PARAMS,
  SET_TASK_ATA_LOGS_LIST,
  FETCH_TASK_ATA_LOGS_LIST_STATUS,
  SET_GDMS_CALLBACK_LOGS_LIST,
  FETCH_GDMS_CALLBACK_LOGS_LIST_STATUS,
} from '../constants';

export const initialRouteParams = {
  xmlRequestLogs: {
    order: '-xml_request_logs.created_at',
    limit: 10,
    page: 1,
  },
  taskLogs: {
    order: '-ata_logs.created_at',
    limit: 10,
    page: 1,
  },
  gdmsCallbackLogs: {
    order: '-gdms_callback_messagess.created_at',
    limit: 10,
    page: 1,
  },
};

const INIT_STATE = {
  xmlRequestLogsList: [],
  xmlRequestLogsListFetchStatus: null,
  taskLogsList: [],
  taskLogsListFetchStatus: null,
  gdmsCallbackLogsList: [],
  gdmsCallbackLogsListFetchStatus: null,
  routeParams: initialRouteParams,
};

export const ataLogsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_XML_REQUEST_LOGS_LIST:
      return { ...state, xmlRequestLogsList: action.payload };
    case FETCH_XML_REQUEST_LOGS_LIST_STATUS:
      return { ...state, xmlRequestLogsListFetchStatus: action.payload };
    case SET_TASK_ATA_LOGS_LIST:
      return { ...state, taskLogsList: action.payload };
    case FETCH_TASK_ATA_LOGS_LIST_STATUS:
      return { ...state, taskLogsListFetchStatus: action.payload };
    case SET_GDMS_CALLBACK_LOGS_LIST:
      return { ...state, gdmsCallbackLogsList: action.payload };
    case FETCH_GDMS_CALLBACK_LOGS_LIST_STATUS:
      return { ...state, gdmsCallbackLogsListFetchStatus: action.payload };

    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'xmlRequestLogs') {
        return {
          ...state,
          routeParams: {
            ...state.routeParams,
            xmlRequestLogs: { ...state.routeParams.xmlRequestLogs, ...action.payload.data },
          },
        };
      }
      if (action.payload.reducer === 'taskLogs') {
        return {
          ...state,
          routeParams: { ...state.routeParams, taskLogs: { ...state.routeParams.taskLogs, ...action.payload.data } },
        };
      }
      if (action.payload.reducer === 'gdmsCallbackLogs') {
        return {
          ...state,
          routeParams: {
            ...state.routeParams,
            gdmsCallbackLogs: { ...state.routeParams.taskLogs, ...action.payload.data },
          },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
