import {
  FETCH_TICKET_USERS_LIST_STATUS,
  FETCH_TICKETS_DETAILS_STATUS,
  FETCH_TICKETS_LIST_STATUS,
  SET_ROUTE_PARAMS,
  SET_TICKET_USERS_LIST,
  SET_TICKETS_DETAILS,
  SET_TICKETS_LIST,
} from '../constants';

export const initialTicketsRouteParams = {
  page: 1,
  limit: 10,
  order: '-tickets.id',
  q: '',
  priority: '',
  type: '',
  status: '',
};

const INIT_STATE = {
  ticketsList: [],
  ticketsListFetchStatus: null,
  ticketDetails: null,
  ticketDetailsFetchStatus: null,
  ticketUsersList: [],
  ticketUsersListFetchStatus: null,
  routeParams: initialTicketsRouteParams,
};

export const ticketsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TICKETS_LIST:
      return { ...state, ticketsList: action.payload };
    case FETCH_TICKETS_LIST_STATUS:
      return { ...state, ticketsListFetchStatus: action.payload };
    case SET_TICKETS_DETAILS:
      return { ...state, ticketDetails: action.payload };
    case FETCH_TICKETS_DETAILS_STATUS:
      return { ...state, ticketDetailsFetchStatus: action.payload };
    case FETCH_TICKET_USERS_LIST_STATUS:
      return { ...state, ticketUsersListFetchStatus: action.payload };
    case SET_TICKET_USERS_LIST:
      return { ...state, ticketUsersList: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'tickets') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
