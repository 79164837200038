import {
  SET_ATA_TYPES_LIST,
  FETCH_ATA_TYPES_LIST_STATUS,
  SET_ATA_TYPES_DETAILS,
  FETCH_ATA_TYPES_DETAILS_STATUS,
  POST_ATA_TYPES_DETAILS_STATUS,
  PATCH_ATAS_DETAILS_STATUS,
  DELETE_ATAS_DETAILS_STATUS,
} from '../constants/';
import { RequestStatus } from '../../utils/RequestStatus';
import SnackbarUtils from '../../utils/SnackbarUtils';
import axios from 'axios';
import { getATATypeListPath } from '../../routes/Router';
import { additionalQueryParams, history } from '../../utils/utilities';
import { changeModalState, setModalContent } from '../utils/utils.action';

export const getATATypeList = params => {
  return function (dispatch, getState) {
    dispatch(setATATypeListFetchStatus(RequestStatus.status.FETCHING));
    let additionalParams = additionalQueryParams(
      getState().sharedReducer.selectedCompany,
      getState().sharedReducer.selectedLocation,
      getState().sharedReducer.includeSuborgs
    );
    let mergedParams = { ...params, ...additionalParams };
    let queryString = Object.keys(mergedParams || {})
      .map(key => key + '=' + mergedParams[key])
      .join('&');
    let url = `${process.env.REACT_APP_API_URL}/atas/types${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        if (JSON.stringify(response.data.items) !== JSON.stringify(getState().ataTypesReducer.ataTypesList)) {
          dispatch(setATATypeList(response.data));
        }
        dispatch(setATATypeListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setATATypeListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting ATA type list!'
        );
      });
  };
};

export function setATATypeList(payload) {
  return {
    type: SET_ATA_TYPES_LIST,
    payload,
  };
}

export function setATATypeListFetchStatus(payload) {
  return {
    type: FETCH_ATA_TYPES_LIST_STATUS,
    payload,
  };
}

export const getATATypeDetails = id => {
  return function (dispatch, getState) {
    dispatch(setATATypeDetailsFetchStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/atas/types/${id}/`;
    axios
      .get(url)
      .then(response => {
        dispatch(setATATypeDetails(response.data));
        dispatch(setATATypeDetailsFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting ATA type details!'
        );
        dispatch(setATATypeDetailsFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setATATypeDetails(payload) {
  return {
    type: SET_ATA_TYPES_DETAILS,
    payload,
  };
}

export function setATATypeDetailsFetchStatus(payload) {
  return {
    type: FETCH_ATA_TYPES_DETAILS_STATUS,
    payload,
  };
}

export const patchATATypeDetails = (id, data) => {
  return function (dispatch, getState) {
    dispatch(setATATypeDetailsPatchStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/atas/types/${id}`;
    axios
      .patch(url, data)
      .then(data => {
        dispatch(setATATypeDetailsPatchStatus(RequestStatus.status.DONE));
        // dispatch(getUserData());
        SnackbarUtils.success('ATAType has been changed!');
        let params = getState().ataTypesReducer.routeParams;
        let qs = Object.keys(getState().ataTypesReducer.routeParams)
          .map(key => key + '=' + params[key])
          .join('&');
        history.push(`${getATATypeListPath}?${qs}`);
      })
      .catch(error => {
        dispatch(setATATypeDetailsPatchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during changing ATA!'
        );
      });
  };
};

export function setATATypeDetailsPatchStatus(payload) {
  return {
    type: PATCH_ATAS_DETAILS_STATUS,
    payload,
  };
}

export const postATATypeDetails = data => {
  return function (dispatch, getState) {
    dispatch(setATATypeDetailsPostStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/atas/types/`;
    axios
      .post(url, data)
      .then(response => {
        dispatch(setATATypeDetailsPostStatus(RequestStatus.status.DONE));
        // dispatch(getUserData());
        history.push(getATATypeListPath);
        SnackbarUtils.success('ATA Type has been added!');
      })
      .catch(error => {
        dispatch(setATATypeDetailsPostStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during creating ATA!'
        );
      });
  };
};

export function setATATypeDetailsPostStatus(payload) {
  return {
    type: POST_ATA_TYPES_DETAILS_STATUS,
    payload,
  };
}

export const deleteATATypeDetails = id => {
  return function (dispatch, getState) {
    dispatch(setDeleteATATypeDetailsFetchStatus(RequestStatus.status.FETCHING));
    let url = `${process.env.REACT_APP_API_URL}/atas/types/${id}`;
    axios
      .delete(url)
      .then(response => {
        dispatch(setDeleteATATypeDetailsFetchStatus(RequestStatus.status.DONE));
        SnackbarUtils.success('ATA Type has been removed');
        history.push(getATATypeListPath);
        dispatch(getATATypeList(getState().ataTypesReducer.routeParams));
        dispatch(changeModalState(false));
        dispatch(setModalContent(null));
      })
      .catch(error => {
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during removing ATA Type'
        );
        dispatch(setDeleteATATypeDetailsFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setDeleteATATypeDetailsFetchStatus(payload) {
  return {
    type: DELETE_ATAS_DETAILS_STATUS,
    payload,
  };
}
