import { SET_STATS_DATA } from '../constants';

const INIT_STATE = {
  statsData: null,
};

export const statsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_STATS_DATA:
      return { ...state, statsData: action.payload };
    default:
      return state;
  }
};
