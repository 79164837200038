import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import { additionalQueryParams } from '../../utils/utilities';
import { changeModalState, setModalContent } from '../../redux/utils/utils.action';
import {
  CreateOrganizationDto,
  OrganizationDetailsType,
  OrganizationListItemType,
  OrganizationMapStatsType,
  OrganizationType,
  UpdateOrganizationDto,
} from '../../types/organization/organization.type';
import { checkDomain } from '../../redux/shared/shared.action';
import { CREDENTIAL_TYPES } from '../../utils/constants';
import { getCurrentUser } from '../auth/auth.action';

export const postOrganizationDetails = createAsyncThunk<
  OrganizationType,
  { data: CreateOrganizationDto },
  { state: RootState }
>('organizations/postOrganizationDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<OrganizationType>(`${process.env.REACT_APP_API_URL}/organizations`, data);
    thunkAPI.dispatch(getCurrentUser({ init: false }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating organization!');
  }
});

export const getOrganizationsList = createAsyncThunk<
  PaginationType<OrganizationListItemType>,
  RouteParamsType,
  { state: RootState }
>('organizations/getOrganizationsList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      null,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<OrganizationListItemType>>(
      `${process.env.REACT_APP_API_URL}/organizations/short-list${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching organizations!');
  }
});

export const getOrganizationDetails = createAsyncThunk<OrganizationDetailsType, { id: number }, { state: RootState }>(
  'organizations/getOrganizationDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<OrganizationDetailsType>(`${process.env.REACT_APP_API_URL}/organizations/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching organization!');
    }
  }
);

export const patchOrganizationDetails = createAsyncThunk<
  {
    org: OrganizationType;
    alerts: {
      parentHasChanged: boolean;
    };
  },
  { id: number; data: UpdateOrganizationDto },
  { state: RootState }
>('organizations/patchOrganizationDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      org: OrganizationType;
      alerts: {
        parentHasChanged: boolean;
      };
    }>(`${process.env.REACT_APP_API_URL}/organizations/${id}`, data);
    const domainData = thunkAPI.getState().sharedReducer.domainData.id;
    if (+domainData.id === +id) {
      thunkAPI.dispatch(checkDomain({ domain: domainData.domain }));
    }
    thunkAPI.dispatch(getCurrentUser({ init: false }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating organization!');
  }
});

export const deleteOrganizationDetails = createAsyncThunk<
  OrganizationType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('organizations/deleteOrganizationDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<OrganizationType>(`${process.env.REACT_APP_API_URL}/organizations/${id}`);
    if (fetchList)
      thunkAPI.dispatch(getOrganizationsList(thunkAPI.getState().organizationsReducer.organizationsRouteParams));
    thunkAPI.dispatch(getCurrentUser({ init: false }));
    thunkAPI.dispatch(changeModalState(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting organization!');
  }
});

export const getOrganizationMapStats = createAsyncThunk<OrganizationMapStatsType, undefined, { state: RootState }>(
  'organizations/getOrganizationMapStats',
  async (params, thunkAPI) => {
    try {
      let queryString = '';
      const selectedCompany = thunkAPI.getState().sharedReducer.selectedCompany;
      if (selectedCompany.id !== 'all') {
        queryString += `?org_id=${selectedCompany.id}`;
      } else {
        queryString += '?inc_sub=true';
      }

      const response = await axios.get<OrganizationMapStatsType>(
        `${process.env.REACT_APP_API_URL}/organizations/stats${queryString ? queryString : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching organization map stats!');
    }
  }
);

export const patchOrganizationBobo = createAsyncThunk<
  void,
  { id: number; data: { bobo_enabled: boolean } },
  { state: RootState }
>('organizations/patchOrganizationBobo', async ({ id, data }, thunkAPI) => {
  try {
    const response = await axios.patch<void>(`${process.env.REACT_APP_API_URL}/organizations/${id}/bobo`, data);
    thunkAPI.dispatch(getOrganizationDetails({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing organization!');
  }
});

export const patchRemoveOrganizationCredentials = createAsyncThunk<
  void,
  { id: number | string; type: string },
  { state: RootState }
>('organizations/patchRemoveOrganizationCredentials', async ({ id, type }, thunkAPI) => {
  try {
    const response = await axios.patch<void>(`${process.env.REACT_APP_API_URL}/organizations/${id}/removeCredentials`, {
      type,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during removing credentials!');
  }
});

export const postVerifyOrganizationCredentials = createAsyncThunk<
  void,
  { id: number; type: string; data: unknown },
  { state: RootState }
>('organizations/postVerifyOrganizationCredentials', async ({ id, data, type }, thunkAPI) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}`;
    if (type === CREDENTIAL_TYPES.InHand) {
      url += `/organizations/${id}/verifyInhandCredentials`;
    } else if (type === CREDENTIAL_TYPES.Cradlepoint) {
      url += `/organizations/${id}/verifyCradlepointCredentials`;
    } else if (type === CREDENTIAL_TYPES.Pepwave) {
      url += `/organizations/${id}/verifyPepwaveCredentials`;
    } else if (type === CREDENTIAL_TYPES.SierraWireless) {
      url += `/organizations/${id}/verifySierraWirelessCredentials`;
    }
    const response = await axios.patch<void>(url, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Credentials are invalid!');
  }
});

export const postFixOrganizationRevIo = createAsyncThunk<{ success: boolean }, { id: number }, { state: RootState }>(
  'organizations/postFixOrganizationRevIo',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.post<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/organizations/${id}/fix-revio`, undefined);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fixing organization Rev.io!');
    }
  }
);
