import axios from 'axios';
import { history } from './utilities';
import SnackbarUtils from './SnackbarUtils';
import * as queryString from 'querystring';

export default class NetworkService {
  static setupDefaultHeaders(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  static setupInterceptors(store) {
    // Add a request interceptor
    axios.interceptors.request.use(config => {
      if (config.method === 'patch' && config.data) {
        ['created_at', 'modified_at'].forEach(key => {
          if (config.data[key]) delete config.data[key];
        });
      }
      return config;
    });
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const url = error.response.request.responseURL;
        if (+error.response?.status === 401 && !url?.includes('/createCDRCallSubscription')) {
          SnackbarUtils.info('Your session has been expired. Please log in again', { key: 'session-expired' });
          localStorage.removeItem('token');
          delete axios.defaults.headers.common['Authorization'];

          if (history.location.pathname !== '/authentication/login') {
            const query = queryString.parse(history.location.search.replace('?', ''));
            delete query['ref'];
            history.push(
              `/authentication/login?ref=${encodeURIComponent(
                `${history.location.pathname}?` +
                  Object.keys(query || {})
                    .map(key => key + '=' + query[key])
                    .join('&')
              )}`
            );
          }
        }
        return Promise.reject(error);
      }
    );
  }
}
