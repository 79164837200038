import {
  FETCH_DATA_PLAN_TYPES_DETAILS_STATUS,
  FETCH_DATA_PLAN_TYPES_LIST_STATUS,
  SET_DATA_PLAN_TYPES_DETAILS,
  SET_DATA_PLAN_TYPES_LIST,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialDataPlanTypesRouteParams = {
  page: 1,
  limit: 10,
  order: '-data_plan_type.id',
  q: '',
};

const INIT_STATE = {
  dataPlanTypesList: [],
  dataPlanTypesListFetchStatus: null,
  dataPlanTypesDetails: null,
  dataPlanTypesDetailsFetchStatus: null,
  routeParams: initialDataPlanTypesRouteParams,
};

export const dataPlanTypesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DATA_PLAN_TYPES_LIST:
      return { ...state, dataPlanTypesList: action.payload };
    case FETCH_DATA_PLAN_TYPES_LIST_STATUS:
      return { ...state, dataPlanTypesListFetchStatus: action.payload };
    case SET_DATA_PLAN_TYPES_DETAILS:
      return { ...state, dataPlanTypesDetails: action.payload };
    case FETCH_DATA_PLAN_TYPES_DETAILS_STATUS:
      return { ...state, dataPlanTypesDetailsFetchStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'dataPlanTypes') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
