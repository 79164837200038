import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import {
  RevioFieldIdDetailsType,
  RevioFieldIdListItemType,
} from '../../types/tc-service/tc-service-type/revio-field-id/revio-field-id.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import {
  deleteRevIoFieldIdDetails,
  getRevIoFieldIdDetails,
  getRevIoFieldIdsList,
  patchRevIoFieldIdDetails,
  postRevIoFieldIdDetails,
} from './tcServiceTypes.action';
import snackbarUtils from '../../utils/SnackbarUtils';

export interface TcServiceTypeState {
  revIoFieldIdsList: null | PaginationType<RevioFieldIdListItemType>;
  revIoFieldIdsListFetchStatus: FETCH_STATUS;
  revIoFieldIdsRouteParams: RouteParamsType;
  revIoFieldIdDetails: null | RevioFieldIdDetailsType;
  revIoFieldIdDetailsFetchStatus: FETCH_STATUS;
  revIoFieldIdDetailsPostStatus: FETCH_STATUS;
  revIoFieldIdDetailsPatchStatus: FETCH_STATUS;
  revIoFieldIdDetailsDeleteStatus: FETCH_STATUS;
}

export const initialrevIoFieldIdsRouteParams = {
  page: 1,
  limit: 10,
  order: '-revio_field_id.id',
  q: '',
  isBobo: 'all',
  service_type_id: '',
};

const INIT_STATE: TcServiceTypeState = {
  revIoFieldIdDetails: null,
  revIoFieldIdDetailsFetchStatus: FETCH_STATUS.NULL,
  revIoFieldIdDetailsDeleteStatus: FETCH_STATUS.NULL,
  revIoFieldIdDetailsPatchStatus: FETCH_STATUS.NULL,
  revIoFieldIdDetailsPostStatus: FETCH_STATUS.NULL,

  revIoFieldIdsListFetchStatus: FETCH_STATUS.NULL,
  revIoFieldIdsList: null,
  revIoFieldIdsRouteParams: initialrevIoFieldIdsRouteParams,
};

export const tcServiceTypesSlice = createSlice({
  name: 'tcServiceType',
  initialState: INIT_STATE,
  reducers: {
    setRevIoFieldIdsList: (state, action) => {
      state.revIoFieldIdsList = action.payload;
    },
    setRevIoFieldIdsListFetchStatus: (state, action) => {
      state.revIoFieldIdsListFetchStatus = action.payload;
    },
    setRevIoFieldIdDetails: (state, action) => {
      state.revIoFieldIdDetails = action.payload;
    },
    setRevIoFieldIdDetailsFetchStatus: (state, action) => {
      state.revIoFieldIdDetailsFetchStatus = action.payload;
    },
    setRevIoFieldIdDetailsPostStatus: (state, action) => {
      state.revIoFieldIdDetailsPostStatus = action.payload;
    },
    setRevIoFieldIdDetailsPatchStatus: (state, action) => {
      state.revIoFieldIdDetailsPatchStatus = action.payload;
    },
    setRevIoFieldIdDetailsDeleteStatus: (state, action) => {
      state.revIoFieldIdDetailsDeleteStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'revIoFiledIds') {
        state.revIoFieldIdsRouteParams = { ...state.revIoFieldIdsRouteParams, ...payload.data };
      }
    });

    // CREATE REV.IO FIELD ID DETAILS
    builder.addCase(postRevIoFieldIdDetails.pending, state => {
      state.revIoFieldIdDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postRevIoFieldIdDetails.rejected, (state, action) => {
      state.revIoFieldIdDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postRevIoFieldIdDetails.fulfilled, state => {
      state.revIoFieldIdDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Rev.io field id created successfully');
    });

    // GET REV.IO FIELD ID LIST
    builder.addCase(getRevIoFieldIdsList.pending, state => {
      state.revIoFieldIdsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRevIoFieldIdsList.rejected, (state, action) => {
      state.revIoFieldIdsListFetchStatus = FETCH_STATUS.REJECTED;
      state.revIoFieldIdsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getRevIoFieldIdsList.fulfilled, (state, action) => {
      state.revIoFieldIdsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.revIoFieldIdsList = action.payload;
    });

    // GET REV.IO FIELD ID DETAILS
    builder.addCase(getRevIoFieldIdDetails.pending, state => {
      state.revIoFieldIdDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRevIoFieldIdDetails.rejected, (state, action) => {
      state.revIoFieldIdDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.revIoFieldIdDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getRevIoFieldIdDetails.fulfilled, (state, action) => {
      state.revIoFieldIdDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.revIoFieldIdDetails = action.payload;
    });

    // PATCH REV.IO FIELD ID DETAILS
    builder.addCase(patchRevIoFieldIdDetails.pending, state => {
      state.revIoFieldIdDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchRevIoFieldIdDetails.rejected, (state, action) => {
      state.revIoFieldIdDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchRevIoFieldIdDetails.fulfilled, state => {
      state.revIoFieldIdDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Rev.io field id updated successfully');
    });

    // DELETE REV.IO FIELD ID DETAILS
    builder.addCase(deleteRevIoFieldIdDetails.pending, state => {
      state.revIoFieldIdDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteRevIoFieldIdDetails.rejected, (state, action) => {
      state.revIoFieldIdDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteRevIoFieldIdDetails.fulfilled, state => {
      state.revIoFieldIdDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Rev.io field id deleted successfully');
    });
  },
});

export const {
  setRevIoFieldIdsList,
  setRevIoFieldIdsListFetchStatus,
  setRevIoFieldIdDetails,
  setRevIoFieldIdDetailsFetchStatus,
  setRevIoFieldIdDetailsPostStatus,
  setRevIoFieldIdDetailsPatchStatus,
  setRevIoFieldIdDetailsDeleteStatus,
} = tcServiceTypesSlice.actions;

export default tcServiceTypesSlice.reducer;
