import React from 'react';

const DomainError = () => {
  return (
    <div className={'w-100 h-100 d-flex justify-content-center align-items-center'}>
      <div>
        <h1 className={'font-bold'}>DOMAIN NOT AVAILABLE</h1>
        <p className={'m-1'}>Oops, something went wrong.</p>
        <p className={'m-1'}>Please try again later.</p>
      </div>
    </div>
  );
};

export default DomainError;
