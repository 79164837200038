import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { checkDomain } from '../redux/shared/shared.action';
import axios from 'axios';
import DomainError from '../views/Main/DomainError/DomainError';
import { RequestStatus } from '../utils/RequestStatus';

const DomainChecker = WrappedComponent => {
  const MyComponent = props => {
    useEffect(() => {
      let params: any = null;
      if (process.env.NODE_ENV === 'development') {
        params = { domain: 'dev' };
        axios.defaults.headers.common['tc-domain'] = `dev`;
      }
      // else {
      //   axios.defaults.headers.common["tc-domain"] = domain;
      // }
      setTimeout(() => {
        props.checkDomain(params);
      });
    }, []);

    return (
      <>
        {!!props.domainData && <WrappedComponent {...props} />}
        {props.domainChecked === RequestStatus.status.ERROR && <DomainError />}
      </>
    );
  };

  const mapStateToProps = (state, otherProps) => {
    return {
      domainData: state.sharedReducer.domainData,
      domainChecked: state.sharedReducer.domainChecked,
    };
  };
  const mapDispatchToProps = dispatch => ({
    checkDomain: domain => {
      dispatch(checkDomain(domain));
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(MyComponent);
};

export default DomainChecker;
