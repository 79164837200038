import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import {
  IssueTrackerListItemType,
  IssueTrackerType,
  UpdateIssueTrackerDto,
} from '../../types/issue-tracker/issue-tracker.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import SnackbarUtils from '../../utils/SnackbarUtils';
import { ErrorLogListItemType } from '../../types/error-log/error-log.type';
import { AsyncTaskListItemType, AsyncTaskType } from '../../types/async-task/async-task.type';
import { additionalQueryParams } from '../../utils/utilities';
import { NotificationListItemType } from '../../types/notification/notification.type';
import { MasterChangeLogType } from '../../types/master-change-log/master-change-log.type';
import { QueueLogType } from '../../types/log/log.type';

export const getIssueTrackerList = createAsyncThunk<
  PaginationType<IssueTrackerListItemType>,
  RouteParamsType,
  { state: RootState }
>('logs/getIssueTrackerList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<IssueTrackerListItemType>>(
      `${process.env.REACT_APP_API_URL}/issue-tracker${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching issues!');
  }
});

export const getIssueTrackerDetails = createAsyncThunk<IssueTrackerType, { id: number }, { state: RootState }>(
  'logs/getIssueTrackerDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<IssueTrackerType>(`${process.env.REACT_APP_API_URL}/issue-tracker/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching issue details!');
    }
  }
);

export const patchIssueTrackerDetails = createAsyncThunk<
  IssueTrackerType,
  { payload: UpdateIssueTrackerDto; id: number },
  { state: RootState }
>('logs/patchIssueTrackerDetails', async ({ payload, id }, thunkAPI) => {
  try {
    const response = await axios.patch<IssueTrackerType>(
      `${process.env.REACT_APP_API_URL}/issue-tracker/${id}`,
      payload
    );
    SnackbarUtils.success('Issue info has been changed!');
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating issues!');
  }
});

export const getErrorLogsList = createAsyncThunk<
  PaginationType<ErrorLogListItemType>,
  RouteParamsType,
  { state: RootState }
>('logs/getErrorLogsList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<ErrorLogListItemType>>(
      `${process.env.REACT_APP_API_URL}/error-log${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching error logs!');
  }
});

export const getAsyncTaskList = createAsyncThunk<
  PaginationType<AsyncTaskListItemType>,
  RouteParamsType,
  { state: RootState }
>('logs/getAsyncTaskList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      null,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<AsyncTaskListItemType>>(
      `${process.env.REACT_APP_API_URL}/async-task${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching async tasks!');
  }
});

export const getRouterSyncAsyncTaskList = createAsyncThunk<
  {
    in_progress: Array<AsyncTaskType>;
    last_failed: AsyncTaskType;
    last_success: AsyncTaskType;
  },
  { type: string; connectionID: number },
  { state: RootState }
>('logs/getRouterSyncAsyncTaskList', async ({ type, connectionID }, thunkAPI) => {
  try {
    const response = await axios.get<{
      in_progress: Array<AsyncTaskType>;
      last_failed: AsyncTaskType;
      last_success: AsyncTaskType;
    }>(`${process.env.REACT_APP_API_URL}/async-task/latest?type=${type} Sync&connection_id=${connectionID}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching async tasks!');
  }
});

export const getRouterUpdateAsyncTaskList = createAsyncThunk<
  {
    in_progress: Array<AsyncTaskType>;
    last_failed: AsyncTaskType;
    last_success: AsyncTaskType;
  },
  { type: string; connectionID: number },
  { state: RootState }
>('logs/getRouterUpdateAsyncTaskList', async ({ type, connectionID }, thunkAPI) => {
  try {
    const response = await axios.get<{
      in_progress: Array<AsyncTaskType>;
      last_failed: AsyncTaskType;
      last_success: AsyncTaskType;
    }>(`${process.env.REACT_APP_API_URL}/async-task/latest?type=${type} Update&connection_id=${connectionID}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching async tasks!');
  }
});

export const getRevioReportsList = createAsyncThunk<
  PaginationType<NotificationListItemType>,
  { org_id: number },
  { state: RootState }
>('logs/getRevioReportsList/no-loader', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<NotificationListItemType>>(
      `${process.env.REACT_APP_API_URL}/tools/revio-report${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching Rev.io Reports!');
  }
});

export const getAuditLogsList = createAsyncThunk<
  PaginationType<NotificationListItemType>,
  RouteParamsType,
  { state: RootState }
>('logs/getAuditLogsList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      null,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<NotificationListItemType>>(
      `${process.env.REACT_APP_API_URL}/tools/audit-logs${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching Audit Logs!');
  }
});

export const getMasterLogsList = createAsyncThunk<
  PaginationType<MasterChangeLogType>,
  RouteParamsType & { entity_id: number },
  { state: RootState }
>('logs/getMasterLogsList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<MasterChangeLogType>>(
      `${process.env.REACT_APP_API_URL}/tools/audit-logs${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching master logs!');
  }
});

export const getRevIoQueueList = createAsyncThunk<
  PaginationType<QueueLogType>,
  RouteParamsType & { entity_id: number },
  { state: RootState }
>('logs/getRevIoQueueList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<QueueLogType>>(
      `${process.env.REACT_APP_API_URL}/tools/revio-queue${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching queue list!');
  }
});

export const getBoBoRevIoQueueList = createAsyncThunk<
  PaginationType<QueueLogType>,
  RouteParamsType & { entity_id: number },
  { state: RootState }
>('logs/getBoBoRevIoQueueList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<QueueLogType>>(
      `${process.env.REACT_APP_API_URL}/tools/revio-queue${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching queue list!');
  }
});
