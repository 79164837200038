import {
  DELETE_ATA_BASE_TEMPLATES_DETAILS_STATUS,
  FETCH_ATA_BASE_TEMPLATES_DETAILS_STATUS,
  FETCH_ATA_BASE_TEMPLATES_LIST_STATUS,
  SET_ATA_BASE_TEMPLATES_DETAILS,
  SET_ATA_BASE_TEMPLATES_LIST,
  SET_ATA_BASE_TEMPLATES_ROUTE_PARAMS,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialATABaseTemplatesRouteParams = {
  page: 1,
  limit: 10,
  order: '-ata_base_template.id',
  q: '',
};

const initialState = {
  ataBaseTemplateList: [],
  ataBaseTemplateListFetchStatus: null,
  ataBaseTemplateDetails: null,
  ataBaseTemplateDetailsFetchStatus: null,
  ataBaseTemplateDetailsDeleteStatus: null,
  routeParams: initialATABaseTemplatesRouteParams,
};

export const ataBaseTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ATA_BASE_TEMPLATES_LIST:
      return { ...state, ataBaseTemplateList: action.payload };
    case FETCH_ATA_BASE_TEMPLATES_LIST_STATUS:
      return { ...state, ataBaseTemplateListFetchStatus: action.payload };
    case SET_ATA_BASE_TEMPLATES_DETAILS:
      return { ...state, ataBaseTemplateDetails: action.payload };
    case FETCH_ATA_BASE_TEMPLATES_DETAILS_STATUS:
      return { ...state, ataBaseTemplateDetailsFetchStatus: action.payload };
    case DELETE_ATA_BASE_TEMPLATES_DETAILS_STATUS:
      return { ...state, ataBaseTemplateDetailsDeleteStatus: action.payload };
    case SET_ATA_BASE_TEMPLATES_ROUTE_PARAMS:
      return {
        ...state,
        routeParams: { ...state.routeParams, ...action.payload },
      };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'ataBaseTemplates') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return { ...state };
  }
};
