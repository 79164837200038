import { lazy } from 'react';

const SMSLoggerPublic = lazy(() => import('../views/SMSLogger/SMSLogger'));
const SMSLoggerSIMDetails = lazy(() => import('../views/SMSLogger/SIM/SMSLoggerSIMDetails/SMSLoggerSIMDetails'));
const SMSLoggerSIMsList = lazy(() => import('../views/SMSLogger/SIM/SMSLoggerSIMsList/SMSLoggerSIMsList'));

export const SMSLoggerRoutes = [
  {
    path: '/sms-logger/sims/:id',
    name: 'SIM Details',
    icon: 'mdi mdi-account-key',
    component: SMSLoggerSIMDetails,
  },
  {
    path: '/sms-logger/sims',
    name: 'SIM Details',
    icon: 'mdi mdi-account-key',
    component: SMSLoggerSIMsList,
  },
  {
    path: '/sms-logger',
    name: 'SMS Logger',
    icon: 'mdi mdi-account-key',
    component: SMSLoggerPublic,
  },
];
