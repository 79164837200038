import {
  DELETE_API_CREDENTIALS_STATUS,
  FETCH_API_CREDENTIALS_STATUS,
  PATCH_API_CREDENTIALS_STATUS,
  POST_API_CREDENTIALS_STATUS,
  SET_API_CREDENTIALS,
} from '../constants';

const initialState = {
  apiCredentialsFetchStatus: null,
  apiCredentialsPostStatus: null,
  apiCredentialsPatchStatus: null,
  apiCredentialsDeleteStatus: null,
  apiCredentials: [],
};

export const apiCredentialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_API_CREDENTIALS:
      return { ...state, apiCredentials: action.payload };
    case FETCH_API_CREDENTIALS_STATUS:
      return { ...state, apiCredentialsFetchStatus: action.payload };
    case POST_API_CREDENTIALS_STATUS:
      return { ...state, apiCredentialsPostStatus: action.payload };
    case PATCH_API_CREDENTIALS_STATUS:
      return { ...state, apiCredentialsPatchStatus: action.payload };
    case DELETE_API_CREDENTIALS_STATUS:
      return { ...state, apiCredentialsDeleteStatus: action.payload };
    default:
      return { ...state };
  }
};
