import React from 'react';
import { Route, Switch } from 'react-router-dom';
import publicRoutes from '../../routes/PublicRoutes';

const PublicLayout = (): JSX.Element => {
  return (
    <div className='public' id='main-wrapper'>
      <Switch>
        {publicRoutes.map(
          (
            prop: {
              path: string;
              name: string;
              icon: string;
              component: React.ComponentType;
            },
            key
          ) => {
            return <Route path={prop.path} component={prop.component} key={key} />;
          }
        )}
      </Switch>
    </div>
  );
};

export default PublicLayout;
