import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthRoutes from '../../routes/AuthRoutes';

const BlankLayout = (): JSX.Element => {
  return (
    <div className='authentications'>
      <Switch>
        {AuthRoutes.map(
          (
            prop: {
              path: string;
              name: string;
              icon: string;
              component: React.ComponentType;
            },
            key
          ) => {
            return <Route path={prop.path} component={prop.component} key={key} />;
          }
        )}
      </Switch>
    </div>
  );
};

export default BlankLayout;
