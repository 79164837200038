import { RequestStatus } from '../../utils/RequestStatus';
import axios from 'axios';
import SnackbarUtils from '../../utils/SnackbarUtils';
import {
  DELETE_SMS_SIM_DETAILS_STATUS,
  FETCH_SMS_LOGGER_DEVICE_NAMES_STATUS,
  FETCH_SMS_LOGGER_LIST_STATUS,
  FETCH_SMS_LOGGER_SUMMARY_STATUS,
  FETCH_SMS_SIM_DETAILS_STATUS,
  FETCH_SMS_SIMS_LIST_STATUS,
  PATCH_SMS_SIM_DETAILS_STATUS,
  POST_SMS_SIM_DETAILS_STATUS,
  SET_FULL_SMS_PLANS_FETCH_STATUS,
  SET_FULL_SMS_PLANS_LIST,
  SET_SMS_LOGGER_ACCESS_TOKEN,
  SET_SMS_LOGGER_DEVICE_NAMES,
  SET_SMS_LOGGER_LIST,
  SET_SMS_LOGGER_SUMMARY,
  SET_SMS_SIM_DETAILS,
  SET_SMS_SIMS_LIST,
} from '../constants';
import { AppDispatch, DispatchAction } from '../Store';
import { history } from '../../utils/utilities';
import { changeModalState, setModalContent } from '../utils/utils.action';

export const getSMSLoggerList = params => {
  return function (dispatch: AppDispatch): void {
    dispatch(setSMSLoggerListFetchStatus(RequestStatus.status.FETCHING));
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/sms-logger${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setSMSLoggerList(response.data));
        dispatch(setSMSLoggerListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        if (+error.response?.status === 401) {
          if (localStorage.getItem('sms-logger-token'))
            SnackbarUtils.info('Your session has expired. Please login again!');
          localStorage.removeItem('sms-logger-token');
          dispatch(setSMSLoggerAccessToken(null));
        } else {
          SnackbarUtils.error(
            (error && error.response && error.response.data && error.response.data.message) ||
              'Error during getting sms logger list!'
          );
        }
        dispatch(setSMSLoggerListFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setSMSLoggerList(payload) {
  return {
    type: SET_SMS_LOGGER_LIST,
    payload,
  };
}

export function setSMSLoggerListFetchStatus(payload) {
  return {
    type: FETCH_SMS_LOGGER_LIST_STATUS,
    payload,
  };
}

export const getSMSSIMsList = params => {
  return function (dispatch: AppDispatch): void {
    dispatch(setSMSSIMsListFetchStatus(RequestStatus.status.FETCHING));
    const queryString = Object.keys(params || {})
      .map(key => key + '=' + params[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/sms-sims${queryString ? `?${queryString}` : ''}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setSMSSIMsList(response.data));
        dispatch(setSMSSIMsListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        if (+error.response?.status === 401) {
          if (localStorage.getItem('sms-logger-token'))
            SnackbarUtils.info('Your session has expired. Please login again!');
          localStorage.removeItem('sms-logger-token');
          dispatch(setSMSLoggerAccessToken(null));
        } else {
          SnackbarUtils.error(
            (error && error.response && error.response.data && error.response.data.message) ||
              'Error during getting sms sims list!'
          );
        }
        dispatch(setSMSSIMsListFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setSMSSIMsList(payload) {
  return {
    type: SET_SMS_SIMS_LIST,
    payload,
  };
}

export function setSMSSIMsListFetchStatus(payload) {
  return {
    type: FETCH_SMS_SIMS_LIST_STATUS,
    payload,
  };
}

export const getSMSLoggerSummary = () => {
  return function (dispatch: AppDispatch): void {
    dispatch(setSMSLoggerSummaryFetchStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/sms-logger/summary`;
    axios
      .get(url)
      .then(response => {
        dispatch(setSMSLoggerSummary(response.data));
        dispatch(setSMSLoggerSummaryFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        if (+error.response?.status === 401 && localStorage.getItem('sms-logger-token')) {
          if (localStorage.getItem('sms-logger-token'))
            SnackbarUtils.info('Your session has expired. Please login again!');
          localStorage.removeItem('sms-logger-token');
          dispatch(setSMSLoggerAccessToken(null));
        } else {
          SnackbarUtils.error(
            (error && error.response && error.response.data && error.response.data.message) ||
              'Error during getting sms logger summary!'
          );
        }
        dispatch(setSMSLoggerSummaryFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setSMSLoggerSummary(payload) {
  return {
    type: SET_SMS_LOGGER_SUMMARY,
    payload,
  };
}

export function setSMSLoggerSummaryFetchStatus(payload) {
  return {
    type: FETCH_SMS_LOGGER_SUMMARY_STATUS,
    payload,
  };
}

export const getSMSLoggerDeviceNames = () => {
  return function (dispatch: AppDispatch): void {
    dispatch(setSMSLoggerDeviceNamesFetchStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/sms-devices/names`;
    axios
      .get(url)
      .then(response => {
        dispatch(setSMSLoggerDeviceNames(response.data?.map(device => ({ name: device.name, id: device.name })) || []));
        dispatch(setSMSLoggerDeviceNamesFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        if (+error.response?.status === 401 && localStorage.getItem('sms-logger-token')) {
          if (localStorage.getItem('sms-logger-token'))
            SnackbarUtils.info('Your session has expired. Please login again!');
          localStorage.removeItem('sms-logger-token');
          dispatch(setSMSLoggerAccessToken(null));
        } else {
          SnackbarUtils.error(
            (error && error.response && error.response.data && error.response.data.message) ||
              'Error during getting sms logger device names!'
          );
        }
        dispatch(setSMSLoggerDeviceNamesFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setSMSLoggerDeviceNames(payload) {
  return {
    type: SET_SMS_LOGGER_DEVICE_NAMES,
    payload,
  };
}

export function setSMSLoggerDeviceNamesFetchStatus(payload) {
  return {
    type: FETCH_SMS_LOGGER_DEVICE_NAMES_STATUS,
    payload,
  };
}

export function setSMSLoggerAccessToken(payload) {
  return {
    type: SET_SMS_LOGGER_ACCESS_TOKEN,
    payload,
  };
}

export const getSMSSIM = (id: number) => {
  return function (dispatch: AppDispatch): void {
    dispatch(setSMSSIMFetchStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/sms-sims/${id}`;
    axios
      .get(url)
      .then(response => {
        dispatch(setSMSSIM(response.data));
        dispatch(setSMSSIMFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        if (+error.response?.status === 401 && localStorage.getItem('sms-logger-token')) {
          if (localStorage.getItem('sms-logger-token'))
            SnackbarUtils.info('Your session has expired. Please login again!');
          localStorage.removeItem('sms-logger-token');
          dispatch(setSMSLoggerAccessToken(null));
        } else {
          SnackbarUtils.error(
            (error && error.response && error.response.data && error.response.data.message) ||
              'Error during getting sms sim!'
          );
        }
        dispatch(setSMSSIMFetchStatus(RequestStatus.status.ERROR));
      });
  };
};

export function setSMSSIM(payload) {
  return {
    type: SET_SMS_SIM_DETAILS,
    payload,
  };
}

export function setSMSSIMFetchStatus(payload) {
  return {
    type: FETCH_SMS_SIM_DETAILS_STATUS,
    payload,
  };
}

export const patchSMSsimDetails = (id, data) => {
  return function (dispatch, getState) {
    dispatch(setSMSsimDetailsPatchStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/sms-sims/${id}`;
    axios
      .patch(url, data)
      .then(() => {
        dispatch(setSMSsimDetailsPatchStatus(RequestStatus.status.DONE));
        SnackbarUtils.success('SIM has been changed!');
        const params = getState().smsLoggerReducer.routeParams.smsSims;
        const qs = Object.keys(params)
          .map(key => key + '=' + params[key])
          .join('&');
        history.push(`/sms-logger/sims?${qs}`);
      })
      .catch(error => {
        dispatch(setSMSsimDetailsPatchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during changing SIM!'
        );
      });
  };
};

export function setSMSsimDetailsPatchStatus(payload) {
  return {
    type: PATCH_SMS_SIM_DETAILS_STATUS,
    payload,
  };
}

export const postSMSsimDetails = data => {
  return function (dispatch, getState) {
    dispatch(setSMSsimDetailsPostStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/sms-sims`;
    axios
      .post(url, data)
      .then(() => {
        dispatch(setSMSsimDetailsPostStatus(RequestStatus.status.DONE));
        SnackbarUtils.success('SIM has been created!');
        const params = getState().smsLoggerReducer.routeParams.smsSims;
        const qs = Object.keys(params)
          .map(key => key + '=' + params[key])
          .join('&');
        history.push(`/sms-logger/sims?${qs}`);
      })
      .catch(error => {
        dispatch(setSMSsimDetailsPostStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during creating SIM!'
        );
      });
  };
};

export function setSMSsimDetailsPostStatus(payload) {
  return {
    type: POST_SMS_SIM_DETAILS_STATUS,
    payload,
  };
}

export const deleteSMSsimDetails = (id: number) => {
  return function (dispatch, getState) {
    dispatch(setSMSsimDetailsDeleteStatus(RequestStatus.status.FETCHING));
    const url = `${process.env.REACT_APP_API_URL}/sms-sims/${id}`;
    axios
      .delete(url)
      .then(() => {
        dispatch(setSMSsimDetailsDeleteStatus(RequestStatus.status.DONE));
        SnackbarUtils.success('SIM has been deleted!');
        const params = getState().smsLoggerReducer.routeParams.smsSims;
        const qs = Object.keys(params)
          .map(key => key + '=' + params[key])
          .join('&');
        history.push(`/sms-logger/sims?${qs}`);
        dispatch(changeModalState(false));
        dispatch(setModalContent(null));
      })
      .catch(error => {
        dispatch(setSMSsimDetailsDeleteStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during deleting SIM!'
        );
      });
  };
};

export function setSMSsimDetailsDeleteStatus(payload) {
  return {
    type: DELETE_SMS_SIM_DETAILS_STATUS,
    payload,
  };
}

// SHARED

export const getFullSMSPlansList = (params = {}) => {
  return function (dispatch: AppDispatch): void {
    const mergedParams = { ...params, limit: 1000 };
    const queryString = Object.keys(mergedParams || {})
      .map(key => key + '=' + mergedParams[key])
      .join('&');
    const url = `${process.env.REACT_APP_API_URL}/sms-plans${queryString ? `?${queryString}` : ''}`;

    dispatch(setFullSMSPlansListFetchStatus(RequestStatus.status.FETCHING));
    axios
      .get(url)
      .then(response => {
        dispatch(setFullSMSPlansList(response.data.items || []));
        dispatch(setFullSMSPlansListFetchStatus(RequestStatus.status.DONE));
      })
      .catch(error => {
        dispatch(setFullSMSPlansListFetchStatus(RequestStatus.status.ERROR));
        SnackbarUtils.error(
          (error && error.response && error.response.data && error.response.data.message) ||
            'Error during getting SMS plans List!'
        );
      });
  };
};

export function setFullSMSPlansList(payload) {
  return {
    type: SET_FULL_SMS_PLANS_LIST,
    payload,
  };
}

export function setFullSMSPlansListFetchStatus(payload: string | null): DispatchAction<string | null> {
  return {
    type: SET_FULL_SMS_PLANS_FETCH_STATUS,
    payload,
  };
}
