export enum ROUTER_TYPE {
  CUSTOM = 'Custom',
  PENDING = 'Pending',
  CRADLE_POINT = 'CradlePoint',
  PEP_WAVE = 'PepWave',
  IN_HAND_NETWORKS = 'InHandNetworks',
  SIERRA_WIRELESS = 'SierraWireless',
  CSG = 'CSG',
  BECENTRAL = 'BeCentral',
  TELTONIKA_NETWORKS = 'TeltonikaNetworks',
  ATEL = 'ATEL',
}
