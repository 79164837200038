import {
  FETCH_SIMS_DETAILS_STATUS,
  FETCH_SIMS_LIST_STATUS,
  SET_ROUTE_PARAMS,
  SET_SIMS_BULK,
  SET_SIMS_DETAILS,
  SET_SIMS_LIST,
} from '../constants';

export const initialSIMsRouteParams = {
  page: 1,
  limit: 10,
  order: '-tc_sim.id',
  warehouse_id: '',
  q: '',
  in_service: 'all',
  carrier: '',
  country_id: '',
  country_category: '',
};

const INIT_STATE = {
  simsList: [],
  simsListFetchStatus: null,
  simsDetails: null,
  simsDetailsFetchStatus: null,
  simsBulk: null,
  routeParams: initialSIMsRouteParams,
};

export const simsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SIMS_LIST:
      return { ...state, simsList: action.payload };
    case FETCH_SIMS_LIST_STATUS:
      return { ...state, simsListFetchStatus: action.payload };
    case SET_SIMS_DETAILS:
      return { ...state, simsDetails: action.payload };
    case FETCH_SIMS_DETAILS_STATUS:
      return { ...state, simsDetailsFetchStatus: action.payload };
    case SET_SIMS_BULK:
      return { ...state, simsBulk: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'sims') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
