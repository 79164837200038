import {
  LOGO_BG,
  NAVBAR_BG,
  SIDEBAR_BG,
  THEME,
  DIRECTION,
  SIDEBAR_POSITION,
  HEADER_POSITION,
  LAYOUT,
  SIDEBAR_TYPE,
  SET_SIDEBAR_IS_OPEN,
  SET_SHOW_SELECT_BOXES,
} from '../constants/';

const INIT_STATE = {
  activeDir: 'ltr',
  activeThemeLayout: 'vertical',
  activeTheme: 'light',
  activeSidebarType: 'full',
  activeLogoBg: 'skin8',
  activeNavbarBg: 'skin8',
  activeSidebarBg: 'skin7',
  activeSidebarPos: 'fixed',
  activeHeaderPos: 'fixed',
  activeLayout: 'full',
  sidebarIsOpen: false,
  showSelectBoxes: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGO_BG:
      return {
        ...state,
        activeLogoBg: action.payload,
      };
    case NAVBAR_BG:
      return {
        ...state,
        activeNavbarBg: action.payload,
      };
    case SIDEBAR_BG:
      return {
        ...state,
        activeSidebarBg: action.payload,
      };
    case THEME:
      return {
        ...state,
        activeTheme: action.payload,
      };
    case DIRECTION:
      return {
        ...state,
        activeDir: action.payload,
      };
    case SIDEBAR_POSITION:
      return {
        ...state,
        activeSidebarPos: action.payload,
      };
    case HEADER_POSITION:
      return {
        ...state,
        activeHeaderPos: action.payload,
      };
    case LAYOUT:
      return {
        ...state,
        activeLayout: action.payload,
      };
    case SIDEBAR_TYPE:
      return {
        ...state,
        activeSidebarType: action.payload,
      };
    case SET_SIDEBAR_IS_OPEN:
      return {
        ...state,
        sidebarIsOpen: action.payload,
      };
    case SET_SHOW_SELECT_BOXES:
      return {
        ...state,
        showSelectBoxes: action.payload,
      };
    default:
      return state;
  }
};
