import { DELETE_ATTACHMENTS_STATUS, POST_ATTACHMENT_STATUS, SET_POST_ATTACHMENT_DETAILS } from '../constants';

const INIT_STATE = {
  attachmentPostDetails: null,
  attachmentPostFetchStatus: null,
  attachmentDeleteFetchStatus: null,
};

export const attachmentsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DELETE_ATTACHMENTS_STATUS:
      return { ...state, attachmentDeleteFetchStatus: action.payload };
    case SET_POST_ATTACHMENT_DETAILS:
      return { ...state, attachmentPostDetails: action.payload };
    case POST_ATTACHMENT_STATUS:
      return { ...state, attachmentPostFetchStatus: action.payload };
    default:
      return state;
  }
};
