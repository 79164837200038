import { lazy } from 'react';

const MainLayout = lazy(() => import('../layouts/MainLayout/MainLayout'));
const BlankLayout = lazy(() => import('../layouts/BlankLayout/BlankLayout'));
const PublicLayout = lazy(() => import('../layouts/PublicLayout/PublicLayout'));

const indexRoutes = [
  { path: '/authentication', name: 'Authentication', component: BlankLayout },
  { path: '/public', name: 'Public', component: PublicLayout },
  { path: '/', name: 'Dashboard', component: MainLayout },
];

export default indexRoutes;
