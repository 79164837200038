import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { changeModalState, setModalContent } from '../../redux/utils/utils.action';
import {
  CreateRevioFieldIdDto,
  RevioFieldIdDetailsType,
  RevioFieldIdListItemType,
  UpdateRevioFieldIdDto,
} from '../../types/tc-service/tc-service-type/revio-field-id/revio-field-id.type';

export const postRevIoFieldIdDetails = createAsyncThunk<
  RevioFieldIdDetailsType,
  { data: CreateRevioFieldIdDto },
  { state: RootState }
>('tcServiceType/postRevIoFieldIdDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<RevioFieldIdDetailsType>(
      `${process.env.REACT_APP_API_URL}/tc-service-type/revioField`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating rev.io field id!');
  }
});

export const getRevIoFieldIdsList = createAsyncThunk<
  PaginationType<RevioFieldIdListItemType>,
  RouteParamsType,
  { state: RootState }
>('tcServiceType/getRevIoFieldIdsList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<RevioFieldIdListItemType>>(
      `${process.env.REACT_APP_API_URL}/tc-service-type/revioField${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching rev.io field ids!');
  }
});

export const getRevIoFieldIdDetails = createAsyncThunk<RevioFieldIdDetailsType, { id: number }, { state: RootState }>(
  'tcServiceType/getRevIoFieldIdDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<RevioFieldIdDetailsType>(
        `${process.env.REACT_APP_API_URL}/tc-service-type/revioField/${id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching rev.io field id!');
    }
  }
);

export const patchRevIoFieldIdDetails = createAsyncThunk<
  RevioFieldIdDetailsType,
  { id: number; data: UpdateRevioFieldIdDto },
  { state: RootState }
>('tcServiceType/patchRevIoFieldIdDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<RevioFieldIdDetailsType>(
      `${process.env.REACT_APP_API_URL}/tc-service-type/revioField/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating rev.io field id!');
  }
});

export const deleteRevIoFieldIdDetails = createAsyncThunk<
  RevioFieldIdDetailsType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('tcServiceType/deleteRevIoFieldIdDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<RevioFieldIdDetailsType>(
      `${process.env.REACT_APP_API_URL}/tc-service-type/revioField/${id}`
    );
    if (fetchList)
      thunkAPI.dispatch(getRevIoFieldIdsList(thunkAPI.getState().tcServiceTypesReducer.revIoFieldIdsRouteParams));
    thunkAPI.dispatch(changeModalState(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting rev.io field id!');
  }
});
