import React, { useState } from 'react';
import { Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { Tooltip } from '@material-ui/core';

const PasswordInput = React.forwardRef((props: any, ref: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(pass => !pass);
  };
  return (
    <InputGroup className={props.invalid ? 'is-invalid' : ''}>
      <Input type={showPassword ? 'text' : 'password'} innerRef={ref} {...props} />
      <Tooltip title='Toggle password' placement='top' arrow>
        <div>
          <InputGroupAddon addonType='prepend'>
            <Button
              onClick={toggleShowPassword}
              style={{
                minWidth: '45px',
                color: 'black',
                backgroundColor: 'white',
                boxShadow: 'none',
                border: '1px solid #e9ecef',
                zIndex: 0,
              }}
            >
              {showPassword ? <i className={'fa fa-eye'} /> : <i className={'fa fa-eye-slash'} />}
            </Button>
          </InputGroupAddon>
        </div>
      </Tooltip>
    </InputGroup>
  );
});

export default PasswordInput;
