import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import {
  AtaPortContactIdOverrideType,
  ContactIDMessageListItemType,
} from '../../types/contact-id-message/contact-id-message.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  getATAContactIDMessages,
  getContactIDMessages,
  getContactIDOverrides,
  patchContactIDOverrides,
} from './contactIDMessages.action';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';

export interface ContactIDMessagesState {
  contactIDMessagesRouteParams: RouteParamsType;
  contactIDMessagesList: null | PaginationType<ContactIDMessageListItemType>;
  contactIDMessagesListFetchStatus: FETCH_STATUS;
  ataContactIDMessagesList: null | Array<ContactIDMessageListItemType>;
  ataContactIDMessagesListFetchStatus: FETCH_STATUS;
  contactIDOverridesPatchStatus: FETCH_STATUS;
  contactIDOverridesFetchStatus: FETCH_STATUS;
  contactIDOverrides: null | Array<AtaPortContactIdOverrideType>;
}

export const initialContactIDMessagesRouteParams = {
  page: 1,
  limit: 10,
  order: '-contactIDMessage.id',
  q: '',
};

const INIT_STATE: ContactIDMessagesState = {
  contactIDMessagesRouteParams: initialContactIDMessagesRouteParams,
  contactIDMessagesList: null,
  contactIDMessagesListFetchStatus: FETCH_STATUS.NULL,
  ataContactIDMessagesList: null,
  ataContactIDMessagesListFetchStatus: FETCH_STATUS.NULL,
  contactIDOverridesPatchStatus: FETCH_STATUS.NULL,
  contactIDOverridesFetchStatus: FETCH_STATUS.NULL,
  contactIDOverrides: null,
};

const contactIDMessagesReducer = createSlice({
  name: 'contactIDMessages',
  initialState: INIT_STATE,
  reducers: {
    setContactIDMessagesList: (state, action) => {
      state.contactIDMessagesList = action.payload;
    },
    setContactIDMessagesListFetchStatus: (state, action) => {
      state.contactIDMessagesListFetchStatus = action.payload;
    },
    setATAContactIDMessagesList: (state, action) => {
      state.ataContactIDMessagesList = action.payload;
    },
    setATAContactIDMessagesListFetchStatus: (state, action) => {
      state.ataContactIDMessagesListFetchStatus = action.payload;
    },
    setContactIDOverridesPatchStatus: (state, action) => {
      state.contactIDOverridesPatchStatus = action.payload;
    },
    setContactIDOverridesFetchStatus: (state, action) => {
      state.contactIDOverridesFetchStatus = action.payload;
    },
    setContactIDOverrides: (state, action) => {
      state.contactIDOverrides = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'contactIDMessages') {
        state.contactIDMessagesRouteParams = { ...state.contactIDMessagesRouteParams, ...payload.data };
      }
    });

    // GET CONTACT ID MESSAGES
    builder.addCase(getContactIDMessages.pending, state => {
      state.contactIDMessagesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getContactIDMessages.rejected, (state, action) => {
      state.contactIDMessagesListFetchStatus = FETCH_STATUS.REJECTED;
      state.contactIDMessagesList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getContactIDMessages.fulfilled, (state, action) => {
      state.contactIDMessagesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.contactIDMessagesList = action.payload;
    });

    // GET CONTACT ID MESSAGES FOR ATA
    builder.addCase(getATAContactIDMessages.pending, state => {
      state.ataContactIDMessagesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATAContactIDMessages.rejected, (state, action) => {
      state.ataContactIDMessagesListFetchStatus = FETCH_STATUS.REJECTED;
      state.ataContactIDMessagesList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATAContactIDMessages.fulfilled, (state, action) => {
      state.ataContactIDMessagesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataContactIDMessagesList = action.payload;
    });

    // PATCH CONTACT ID OVERRIDES
    builder.addCase(patchContactIDOverrides.pending, state => {
      state.contactIDOverridesPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchContactIDOverrides.rejected, (state, action) => {
      state.contactIDOverridesPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchContactIDOverrides.fulfilled, state => {
      state.contactIDOverridesPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Contact ID overrides updated successfully!');
      });
    });

    // GET CONTACT ID OVERRIDES
    builder.addCase(getContactIDOverrides.pending, state => {
      state.contactIDOverridesFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getContactIDOverrides.rejected, (state, action) => {
      state.contactIDOverridesFetchStatus = FETCH_STATUS.REJECTED;
      state.contactIDOverrides = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getContactIDOverrides.fulfilled, (state, action) => {
      state.contactIDOverridesFetchStatus = FETCH_STATUS.FULFILLED;
      state.contactIDOverrides = action.payload;
    });
  },
});

export const {
  setContactIDMessagesList,
  setContactIDMessagesListFetchStatus,
  setATAContactIDMessagesList,
  setATAContactIDMessagesListFetchStatus,
  setContactIDOverridesPatchStatus,
  setContactIDOverridesFetchStatus,
  setContactIDOverrides,
} = contactIDMessagesReducer.actions;

export default contactIDMessagesReducer.reducer;
