import {
  FETCH_ORDERS_DETAILS_STATUS,
  FETCH_ORDERS_LIST_STATUS,
  SET_ORDERS_DETAILS,
  SET_ORDERS_LIST,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialOrdersRouteParams = {
  page: 1,
  limit: 10,
  order: '-orders.id',
  q: '',
  status: '',
};

const INIT_STATE = {
  ordersList: [],
  ordersListFetchStatus: null,
  ordersDetails: null,
  ordersDetailsFetchStatus: null,
  routeParams: initialOrdersRouteParams,
};

export const ordersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ORDERS_LIST:
      return { ...state, ordersList: action.payload };
    case FETCH_ORDERS_LIST_STATUS:
      return { ...state, ordersListFetchStatus: action.payload };
    case SET_ORDERS_DETAILS:
      return { ...state, ordersDetails: action.payload };
    case FETCH_ORDERS_DETAILS_STATUS:
      return { ...state, ordersDetailsFetchStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'orders') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
