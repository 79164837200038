import { POST_TICKET_NOTES_DETAILS_STATUS } from '../constants';


const INIT_STATE = {
  ticketNotesPostStatus: null,
};

export const ticketNotesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_TICKET_NOTES_DETAILS_STATUS:
      return { ...state, ticketNotesPostStatus: action.payload };
    default:
      return state;
  }
};
