import { PermissionArrayType } from '../../../types/other';
import { getAllPermissionRolesForUserLevel, PERMISSIONROLECONSTANTS } from '../../../redux/utils/utils.constants';

export const equipmentGroupPermissions: PermissionArrayType = {
  CREATE: [
    PERMISSIONROLECONSTANTS.superadmin,
    ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 'user', 't3_admin', 'support']),
    ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin', 'user']),
  ],
  READ: [
    PERMISSIONROLECONSTANTS.superadmin,
    ...getAllPermissionRolesForUserLevel('telcloud'),
    ...getAllPermissionRolesForUserLevel('reseller'),
    ...getAllPermissionRolesForUserLevel('customer'),
  ],
  UPDATE: [
    PERMISSIONROLECONSTANTS.superadmin,
    ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 'user', 't3_admin', 'support']),
    ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin', 'user']),
    ...getAllPermissionRolesForUserLevel('customer', ['owner', 'admin']),
  ],
  DELETE: [PERMISSIONROLECONSTANTS.superadmin],
};
