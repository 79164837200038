import { POST_COMMENTS_DETAILS_STATUS } from '../constants';

const INIT_STATE = {
  commentsPostStatus: null,
};

export const commentsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_COMMENTS_DETAILS_STATUS:
      return { ...state, commentsPostStatus: action.payload };
    default:
      return state;
  }
};
