import {
  FETCH_ATAS_ACS_DETAILS_STATUS,
  FETCH_ATAS_ACS_ADVANCED_SETTINGS_STATUS,
  FETCH_ATAS_DETAILS_STATUS,
  FETCH_ATAS_LIST_STATUS,
  FETCH_FACTORY_RESET_ATA_STATUS,
  FETCH_REBOOT_ATA_STATUS,
  SET_ATA_BULK,
  SET_ATAS_ACS_DETAILS,
  SET_ATAS_ACS_ADVANCED_SETTINGS,
  SET_ATAS_DETAILS,
  SET_ATAS_LIST,
  SET_ROUTE_PARAMS,
  FETCH_ATAS_ACS_ADVANCED_PORT_SETTINGS_STATUS,
  SET_ATAS_ACS_ADVANCED_PORT_SETTINGS,
  POST_ATAS_ACS_ADVANCED_PORT_SETTINGS_DETAILS_STATUS,
  SET_ATA_ACS_CONTACT_IDS,
  FETCH_ATA_ACS_CONTACT_IDS_STATUS,
} from '../constants';

export const initialATAsRouteParams = {
  page: 1,
  limit: 10,
  order: '-ata.id',
  q: '',
  status: '',
  type: '',
  port_status: '',
  equipment_status: 'all',
  warehouse_id: '',
  in_service: 'all',
  country_id: '',
  country_category: '',
};

const INIT_STATE = {
  atasList: [],
  atasListFetchStatus: null,
  atasDetails: null,
  atasDetailsFetchStatus: null,
  atasACSDetails: null,
  atasACSDetailsFetchStatus: null,
  atasACSAdvancedSettings: null,
  atasACSAdvancedSettingsFetchStatus: null,
  atasACSAdvancedPortSettings: null,
  atasACSAdvancedPortSettingsFetchStatus: null,
  atasACSAdvancedPortSettingsPostFetchStatus: null,
  ataBulk: null,
  factoryResetAtaFetchStatus: null,
  rebootFetchStatus: null,
  ataACSContactIDs: [],
  ataACSContactIDsFetchStatus: null,
  routeParams: initialATAsRouteParams,
};

export const atasReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ATAS_LIST:
      return { ...state, atasList: action.payload };
    case FETCH_ATAS_LIST_STATUS:
      return { ...state, atasListFetchStatus: action.payload };
    case SET_ATAS_DETAILS:
      return { ...state, atasDetails: action.payload };
    case FETCH_ATAS_DETAILS_STATUS:
      return { ...state, atasDetailsFetchStatus: action.payload };
    case SET_ATAS_ACS_DETAILS:
      return { ...state, atasACSDetails: action.payload };
    case FETCH_ATAS_ACS_DETAILS_STATUS:
      return { ...state, atasACSDetailsFetchStatus: action.payload };
    case SET_ATA_BULK:
      return { ...state, ataBulk: action.payload };
    case FETCH_FACTORY_RESET_ATA_STATUS:
      return { ...state, factoryResetAtaFetchStatus: action.payload };
    case FETCH_REBOOT_ATA_STATUS:
      return { ...state, rebootFetchStatus: action.payload };
    case SET_ATAS_ACS_ADVANCED_SETTINGS:
      return { ...state, atasACSAdvancedSettings: action.payload };
    case FETCH_ATAS_ACS_ADVANCED_SETTINGS_STATUS:
      return { ...state, atasACSAdvancedSettingsFetchStatus: action.payload };
    case SET_ATAS_ACS_ADVANCED_PORT_SETTINGS:
      return { ...state, atasACSAdvancedPortSettings: action.payload };
    case FETCH_ATA_ACS_CONTACT_IDS_STATUS:
      return { ...state, ataACSContactIDsFetchStatus: action.payload };
    case SET_ATA_ACS_CONTACT_IDS:
      return { ...state, ataACSContactIDs: action.payload };
    case POST_ATAS_ACS_ADVANCED_PORT_SETTINGS_DETAILS_STATUS:
      return {
        ...state,
        atasACSAdvancedPortSettingsPostFetchStatus: action.payload,
      };
    case FETCH_ATAS_ACS_ADVANCED_PORT_SETTINGS_STATUS:
      return {
        ...state,
        atasACSAdvancedPortSettingsFetchStatus: action.payload,
      };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'atas') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
