import {
  FETCH_PBXS_DETAILS_STATUS,
  FETCH_PBXS_FIX_DIAL_RULE_STATUS,
  FETCH_PBXS_LIST_STATUS,
  SET_PBXS_DETAILS,
  SET_PBXS_LIST,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialPBXsRouteParams = {
  page: 1,
  limit: 10,
  order: '-pbx.id',
  q: '',
  country_id: '',
  country_category: '',
};

const INIT_STATE = {
  pbxsList: [],
  pbxsListFetchStatus: null,
  pbxsDetails: null,
  pbxsDetailsFetchStatus: null,
  routeParams: initialPBXsRouteParams,
};

export const pbxsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PBXS_LIST:
      return { ...state, pbxsList: action.payload };
    case FETCH_PBXS_LIST_STATUS:
      return { ...state, pbxsListFetchStatus: action.payload };
    case SET_PBXS_DETAILS:
      return { ...state, pbxsDetails: action.payload };
    case FETCH_PBXS_DETAILS_STATUS:
      return { ...state, pbxsDetailsFetchStatus: action.payload };
    case FETCH_PBXS_FIX_DIAL_RULE_STATUS:
      return { ...state, pbxsDetailsFetchStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'pbxs') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
