import {
  SET_PUBLIC_EQUIPMENT_GROUPS_DETAILS,
  FETCH_PUBLIC_EQUIPMENT_GROUPS_DETAILS_STATUS,
  SET_PUBLIC_LOCATION_DETAILS,
  FETCH_PUBLIC_LOCATION_DETAILS_STATUS,
} from '../constants';

export const INIT_STATE = {
  publicEquipmentGroupDetails: null,
  publicEquipmentGroupDetailsFetchStatus: null,
  publicLocationDetails: null,
  publicLocationDetailsFetchStatus: null,
};

export const publicReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PUBLIC_EQUIPMENT_GROUPS_DETAILS:
      return { ...state, publicEquipmentGroupDetails: action.payload };
    case FETCH_PUBLIC_EQUIPMENT_GROUPS_DETAILS_STATUS:
      return { ...state, publicEquipmentGroupDetailsFetchStatus: action.payload };
    case SET_PUBLIC_LOCATION_DETAILS:
      return { ...state, publicLocationDetails: action.payload };
    case FETCH_PUBLIC_LOCATION_DETAILS_STATUS:
      return { ...state, publicLocationDetailsFetchStatus: action.payload };
    default:
      return state;
  }
};
