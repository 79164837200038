import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { flattenRoutes } from './utilities';
import ThemeRoutes from '../routes/Router';
import publicRoutes from '../routes/PublicRoutes';
import authRoutes from '../routes/AuthRoutes';

const SEO = props => {
  const location = useLocation();
  const routes: any = useMemo(
    () => flattenRoutes([...authRoutes, ...publicRoutes, ...ThemeRoutes]),
    [ThemeRoutes, authRoutes, publicRoutes]
  );
  const [routeName, setRouteName] = useState('');

  useEffect(() => {
    let routeName = routes.find(route => {
      return route.path.includes(location.pathname);
    })?.name;
    if (!routeName) {
      routeName = routes.find(route => {
        if (route.path.includes(':id')) {
          return location.pathname.includes(route.path.replace(':id', ''));
        }
        if (route.path.includes(':token')) {
          return location.pathname.includes(route.path.replace(':token', ''));
        }
        if (route.path.includes(':hash')) {
          return location.pathname.includes(route.path.replace(':hash', ''));
        }
        return null;
      })?.name;
    }
    setRouteName(routeName ? `${props.domain?.name} - ${routeName}` : `${props.domain?.name}`);
  }, [location]);

  if (!routeName) return <></>;
  return (
    <Helmet>
      <title>{routeName}</title>
    </Helmet>
  );
};

const mapStateToProps = (state, otherProps) => {
  return {
    domain: state.sharedReducer.domainData,
  };
};
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SEO);
