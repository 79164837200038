import {
  FETCH_INVENTORIES_LIST_STATUS,
  FETCH_INVENTORY_STATS_STATUS,
  POST_INVENTORY_MOVE_STATUS,
  SET_INVENTORIES_LIST,
  SET_INVENTORY_STATS,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialInventoryRouteParams = {
  page: 1,
  limit: 10,
  order: '-inventory.id',
  q: '',
  type: '',
  warehouse_id: '',
  country_id: '',
  country_category: '',
};

const INIT_STATE = {
  inventoriesList: [],
  inventoriesListFetchStatus: null,
  inventoryStats: [],
  inventoryStatsFetchStatus: null,
  inventoriesMovePostStatus: null,
  routeParams: initialInventoryRouteParams,
};

export const inventoriesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_INVENTORIES_LIST:
      return { ...state, inventoriesList: action.payload };
    case FETCH_INVENTORIES_LIST_STATUS:
      return { ...state, inventoriesListFetchStatus: action.payload };
    case SET_INVENTORY_STATS:
      return { ...state, inventoryStats: action.payload };
    case FETCH_INVENTORY_STATS_STATUS:
      return { ...state, inventoryStatsFetchStatus: action.payload };
    case POST_INVENTORY_MOVE_STATUS:
      return { ...state, inventoriesMovePostStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'inventories') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
