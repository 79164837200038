import {
  FETCH_ENCLOSURE_TYPES_DETAILS_STATUS,
  FETCH_ENCLOSURE_TYPES_LIST_STATUS,
  SET_ENCLOSURE_TYPES_DETAILS,
  SET_ENCLOSURE_TYPES_LIST,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialEnclosureTypesRouteParams = {
  page: 1,
  limit: 10,
  order: '-enclosure_type.id',
  q: '',
};

const INIT_STATE = {
  enclosureTypesList: [],
  enclosureTypesListFetchStatus: null,
  enclosureTypesDetails: null,
  enclosureTypesDetailsFetchStatus: null,
  routeParams: initialEnclosureTypesRouteParams,
};

export const enclosureTypesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ENCLOSURE_TYPES_LIST:
      return { ...state, enclosureTypesList: action.payload };
    case FETCH_ENCLOSURE_TYPES_LIST_STATUS:
      return { ...state, enclosureTypesListFetchStatus: action.payload };
    case SET_ENCLOSURE_TYPES_DETAILS:
      return { ...state, enclosureTypesDetails: action.payload };
    case FETCH_ENCLOSURE_TYPES_DETAILS_STATUS:
      return { ...state, enclosureTypesDetailsFetchStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'enclosureTypes') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
