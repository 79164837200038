import { lazy } from 'react';

const ForgotPassword = lazy(() => import('../views/Authentication/ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('../views/Authentication/ResetPassword/ResetPassword'));
const SetupPassword = lazy(() => import('../views/Authentication/SetupPassword/SetupPassword'));
const Login = lazy(() => import('../views/Authentication/Login/Login'));
const Redirect = lazy(() => import('../views/Authentication/Redirect/Redirect'));
const VerifyEmail = lazy(() => import('../views/Authentication/VerifyEmail/VerifyEmail'));

const authRoutes = [
  {
    path: '/authentication/login',
    name: 'Login',
    icon: 'mdi mdi-account-key',
    component: Login,
  },
  {
    path: '/authentication/redirect',
    name: 'Redirect',
    icon: 'mdi mdi-account-key',
    component: Redirect,
  },
  {
    path: '/authentication/forgot-password',
    name: 'Forgot Password',
    icon: 'mdi mdi-account-key',
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:token',
    name: 'Reset Password',
    icon: 'mdi mdi-account-key',
    component: ResetPassword,
  },
  {
    path: '/setup-password/:token',
    name: 'Setup Password',
    icon: 'mdi mdi-account-key',
    component: SetupPassword,
  },
  {
    path: '/verify/email/:token',
    name: 'Verify Email',
    icon: 'mdi mdi-account-key',
    component: VerifyEmail,
  },
];
export default authRoutes;
