import React, { useEffect } from 'react';
import { history } from '../../utils/utilities';
import SEO from '../../utils/SEO';
import { Modal } from 'reactstrap';
import { Route, Switch, Router as ReactRouter } from 'react-router-dom';
import SMSLoggerLayout from '../../layouts/SMSLoggerLayout/SMSLoggerLayout';
import BlankLayout from '../../layouts/BlankLayout/BlankLayout';
import PublicLayout from '../../layouts/PublicLayout/PublicLayout';
import indexRoutes from '../index';
import PrivateRoute from '../PrivateRoutes';
import Loader from '../../components/Other/Loader';
import moment from 'moment-timezone';
import { client as Elevio } from 'elevio';
import crypto from 'crypto-js';
import { AppDispatch, RootState } from '../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { setTimezone } from '../../redux/shared/shared.action';
import DomainChecker from '../../hocs/DomainChecker';

const Router = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.authReducer);
  const { showModal, modalContent } = useSelector((state: RootState) => state.utilsReducer);

  useEffect(() => {
    dispatch(setTimezone(moment.tz.guess()));
  }, []);

  useEffect(() => {
    if (user) {
      Elevio.load('6228cafe7b475').then(data => {
        const user: any = {
          email: 'dmrugalski@i2asolutions.com',
          firstName: 'Dominik',
          lastName: 'Mrugalski',
        };
        const elevioAccountSecret = '6228cafe7c267';
        const hmac: any = crypto.HmacSHA256('sha256', elevioAccountSecret);
        user.hash = hmac.toString(crypto.enc.Hex);

        data.setSettings({ enabled: true });
        data.setUser(user);
      });
      if (user.timezone) {
        dispatch(setTimezone(user.timezone));
        moment.tz.setDefault(user.timezone);
      } else {
        dispatch(setTimezone(moment.tz.guess(true)));
        moment.tz.setDefault(moment.tz.guess(true));
      }
    } else {
      Elevio.disable();
    }
  }, [user]);

  return (
    <>
      <ReactRouter history={history}>
        <SEO />
        <Modal isOpen={showModal} size={modalContent ? modalContent.size : 'md'}>
          {modalContent ? modalContent.content : null}
        </Modal>
        <Switch>
          <Route exact path='/sms-logger' component={SMSLoggerLayout} />;
          <Route exact path='/sms-logger/sims/:id' component={SMSLoggerLayout} />;
          <Route exact path='/sms-logger/sims' component={SMSLoggerLayout} />;
          <Route exact path='/authentication/login' component={BlankLayout} />;
          <Route exact path='/authentication/redirect' component={BlankLayout} />;
          <Route exact path='/authentication/forgot-password' component={BlankLayout} />;
          <Route exact path='/reset-password/:token' component={BlankLayout} />;
          <Route exact path='/setup-password/:token' component={BlankLayout} />;
          <Route exact path='/verify/email/:token' component={BlankLayout} />;
          <Route exact path='/public/equipment-group/:hash' component={PublicLayout} />;
          <Route exact path='/public/router/:hash' component={PublicLayout} />;
          <Route exact path='/public/power-cycles/:hash' component={PublicLayout} />;
          <Route exact path='/public/tc-sim/:hash' component={PublicLayout} />;
          <Route exact path='/public/atas/:hash' component={PublicLayout} />;
          <Route exact path='/public/dialer/:hash' component={PublicLayout} />;
          <Route exact path='/public/acs/:hash' component={PublicLayout} />;
          <Route exact path='/public/battery/:hash' component={PublicLayout} />;
          <Route exact path='/public/location/:hash' component={PublicLayout} />;
          <Route exact path='/public/enclosures/:hash' component={PublicLayout} />;
          {indexRoutes.map((prop, key) => {
            return <PrivateRoute path={prop.path} key={key} component={prop.component} />;
          })}
        </Switch>
      </ReactRouter>
      <Loader />
    </>
  );
};

export default React.memo(DomainChecker(Router));
