import {
  FETCH_COUNTRIES_DETAILS_STATUS,
  FETCH_COUNTRIES_LIST_STATUS,
  SET_COUNTRIES_DETAILS,
  SET_COUNTRIES_LIST,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialCountriesRouteParams = {
  page: 1,
  limit: 10,
  order: '-country.id',
  q: '',
  category: '',
  status: 'all',
};

const INIT_STATE = {
  countriesList: [],
  countriesListFetchStatus: null,
  countriesDetails: null,
  countriesDetailsFetchStatus: null,
  routeParams: initialCountriesRouteParams,
};

export const countriesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_COUNTRIES_LIST:
      return { ...state, countriesList: action.payload };
    case FETCH_COUNTRIES_LIST_STATUS:
      return { ...state, countriesListFetchStatus: action.payload };
    case SET_COUNTRIES_DETAILS:
      return { ...state, countriesDetails: action.payload };
    case FETCH_COUNTRIES_DETAILS_STATUS:
      return { ...state, countriesDetailsFetchStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'countries') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
