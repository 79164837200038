import { createSlice } from '@reduxjs/toolkit';
import { ExternalSystemInstanceType } from '../../types/external-system-instance/external-system-instance.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import {
  deleteExternalSystemDetails,
  deleteRouteConfig,
  getExternalSystemInstanceDetails,
  getExternalSystemMapperList,
  getExternalSystems,
  getExternalSystemsPBX,
  getExternalSystemsProviders,
  getPBXDomainsList,
  patchExternalSystemDetails,
  patchGlobalExternalSystemDetails,
  patchRouteConfig,
  postExternalSystemDetails,
  postPBXDomains,
  postRouteConfig,
} from './externalSystems.action';
import snackbarUtils from '../../utils/SnackbarUtils';

export interface ExternalSystemsState {
  externalSystemsList: Array<ExternalSystemInstanceType> | null;
  externalSystemsListFetchStatus: FETCH_STATUS;
  externalSystemInstanceDetails: ExternalSystemInstanceType | null;
  externalSystemInstanceDetailsFetchStatus: FETCH_STATUS;
  globalExternalSystemsList: Array<ExternalSystemInstanceType> | null;
  globalExternalSystemsListFetchStatus: FETCH_STATUS;
  externalSystemDetailsPostStatus: FETCH_STATUS;
  externalSystemDetailsDeleteStatus: FETCH_STATUS;
  externalSystemDetailsPatchStatus: FETCH_STATUS;
  globalExternalSystemDetailsPatchStatus: FETCH_STATUS;
  systemMapperList: Array<ExternalSystemInstanceType> | null;
  systemMapperListFetchStatus: FETCH_STATUS;
  externalSystemProvidersList: Array<ExternalSystemInstanceType> | null;
  externalSystemProvidersListFetchStatus: FETCH_STATUS;
  externalSystemPBXList: Array<ExternalSystemInstanceType> | null;
  externalSystemPBXListFetchStatus: FETCH_STATUS;
  routingConfigPostStatus: FETCH_STATUS;
  routingConfigDeleteStatus: FETCH_STATUS;
  routingConfigPatchStatus: FETCH_STATUS;
  pbxDomainsList: Array<{
    description: string;
    domain: string;
  }> | null;
  pbxDomainsListFetchStatus: FETCH_STATUS;
  pbxDomainsPostStatus: FETCH_STATUS;
}

const INIT_STATE: ExternalSystemsState = {
  externalSystemsList: null,
  externalSystemsListFetchStatus: FETCH_STATUS.NULL,
  externalSystemInstanceDetails: null,
  externalSystemInstanceDetailsFetchStatus: FETCH_STATUS.NULL,
  globalExternalSystemsList: null,
  globalExternalSystemsListFetchStatus: FETCH_STATUS.NULL,
  systemMapperList: null,
  globalExternalSystemDetailsPatchStatus: FETCH_STATUS.NULL,
  systemMapperListFetchStatus: FETCH_STATUS.NULL,
  externalSystemDetailsPostStatus: FETCH_STATUS.NULL,
  externalSystemDetailsDeleteStatus: FETCH_STATUS.NULL,
  externalSystemDetailsPatchStatus: FETCH_STATUS.NULL,
  externalSystemProvidersList: null,
  externalSystemProvidersListFetchStatus: FETCH_STATUS.NULL,
  externalSystemPBXList: null,
  externalSystemPBXListFetchStatus: FETCH_STATUS.NULL,
  routingConfigPostStatus: FETCH_STATUS.NULL,
  routingConfigDeleteStatus: FETCH_STATUS.NULL,
  routingConfigPatchStatus: FETCH_STATUS.NULL,
  pbxDomainsList: null,
  pbxDomainsListFetchStatus: FETCH_STATUS.NULL,
  pbxDomainsPostStatus: FETCH_STATUS.NULL,
};

const externalSystemsSlice = createSlice({
  name: 'externalSystems',
  initialState: INIT_STATE,
  reducers: {
    setExternalSystemsList: (state, action) => {
      state.externalSystemsList = action.payload;
    },
    setExternalSystemsListFetchStatus: (state, action) => {
      state.externalSystemsListFetchStatus = action.payload;
    },
    setExternalSystemInstanceDetails: (state, action) => {
      state.externalSystemInstanceDetails = action.payload;
    },
    setExternalSystemInstanceDetailsFetchStatus: (state, action) => {
      state.externalSystemInstanceDetailsFetchStatus = action.payload;
    },
    setGlobalExternalSystemsList: (state, action) => {
      state.globalExternalSystemsList = action.payload;
    },
    setGlobalExternalSystemsListFetchStatus: (state, action) => {
      state.globalExternalSystemsListFetchStatus = action.payload;
    },
    setSystemMapperList: (state, action) => {
      state.systemMapperList = action.payload;
    },
    setSystemMapperListFetchStatus: (state, action) => {
      state.systemMapperListFetchStatus = action.payload;
    },
    setExternalSystemDetailsPostStatus: (state, action) => {
      state.externalSystemDetailsPostStatus = action.payload;
    },
    setExternalSystemDetailsDeleteStatus: (state, action) => {
      state.externalSystemDetailsDeleteStatus = action.payload;
    },
    setExternalSystemProvidersList: (state, action) => {
      state.externalSystemProvidersList = action.payload;
    },
    setExternalSystemProvidersListFetchStatus: (state, action) => {
      state.externalSystemProvidersListFetchStatus = action.payload;
    },
    setExternalSystemPBXList: (state, action) => {
      state.externalSystemPBXList = action.payload;
    },
    setExternalSystemPBXListFetchStatus: (state, action) => {
      state.externalSystemPBXListFetchStatus = action.payload;
    },
    setRoutingConfigPostStatus: (state, action) => {
      state.routingConfigPostStatus = action.payload;
    },
    setRoutingConfigDeleteStatus: (state, action) => {
      state.routingConfigDeleteStatus = action.payload;
    },
    setRoutingConfigPatchStatus: (state, action) => {
      state.routingConfigPatchStatus = action.payload;
    },
    setExternalSystemDetailsPatchStatus: (state, action) => {
      state.externalSystemDetailsPatchStatus = action.payload;
    },
    setGlobalExternalSystemDetailsPatchStatus: (state, action) => {
      state.globalExternalSystemDetailsPatchStatus = action.payload;
    },
    setPbxDomainsList: (state, action) => {
      state.pbxDomainsList = action.payload;
    },
    setPbxDomainsListFetchStatus: (state, action) => {
      state.pbxDomainsListFetchStatus = action.payload;
    },
    setPbxDomainsPostStatus: (state, action) => {
      state.pbxDomainsPostStatus = action.payload;
    },
  },
  extraReducers: builder => {
    // GET EXTERNAL SYSTEMS
    builder.addCase(getExternalSystems.pending, (state, action) => {
      if (action.meta.arg.isGlobal) {
        state.globalExternalSystemsListFetchStatus = FETCH_STATUS.PENDING;
      } else {
        state.externalSystemsListFetchStatus = FETCH_STATUS.PENDING;
      }
    });
    builder.addCase(getExternalSystems.rejected, (state, action) => {
      if (action.meta.arg.isGlobal) {
        state.globalExternalSystemsListFetchStatus = FETCH_STATUS.REJECTED;
        state.globalExternalSystemsList = null;
      } else {
        state.externalSystemsListFetchStatus = FETCH_STATUS.REJECTED;
        state.externalSystemsList = null;
      }
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getExternalSystems.fulfilled, (state, action) => {
      if (action.meta.arg.isGlobal) {
        state.globalExternalSystemsListFetchStatus = FETCH_STATUS.FULFILLED;
        state.globalExternalSystemsList = action.payload;
      } else {
        state.externalSystemsListFetchStatus = FETCH_STATUS.FULFILLED;
        state.externalSystemsList = action.payload;
      }
    });

    // GET EXTERNAL SYSTEM INSTANCE DETAILS
    builder.addCase(getExternalSystemInstanceDetails.pending, state => {
      state.externalSystemInstanceDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getExternalSystemInstanceDetails.rejected, (state, action) => {
      state.externalSystemInstanceDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.externalSystemInstanceDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getExternalSystemInstanceDetails.fulfilled, (state, action) => {
      state.externalSystemInstanceDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.externalSystemInstanceDetails = action.payload;
    });

    // POST EXTERNAL SYSTEM
    builder.addCase(postExternalSystemDetails.pending, state => {
      state.externalSystemDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postExternalSystemDetails.rejected, (state, action) => {
      state.externalSystemDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postExternalSystemDetails.fulfilled, state => {
      state.externalSystemDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('External system created successfully');
      });
    });

    // DELETE EXTERNAL SYSTEM
    builder.addCase(deleteExternalSystemDetails.pending, state => {
      state.externalSystemDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteExternalSystemDetails.rejected, (state, action) => {
      state.externalSystemDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteExternalSystemDetails.fulfilled, state => {
      state.externalSystemDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('External system deleted successfully');
      });
    });

    // PATCH EXTERNAL SYSTEM
    builder.addCase(patchExternalSystemDetails.pending, state => {
      state.externalSystemDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchExternalSystemDetails.rejected, (state, action) => {
      state.externalSystemDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchExternalSystemDetails.fulfilled, state => {
      state.externalSystemDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('External system updated successfully');
      });
    });

    // PATCH GLOBAL EXTERNAL SYSTEM
    builder.addCase(patchGlobalExternalSystemDetails.pending, state => {
      state.globalExternalSystemDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchGlobalExternalSystemDetails.rejected, (state, action) => {
      state.globalExternalSystemDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchGlobalExternalSystemDetails.fulfilled, state => {
      state.globalExternalSystemDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Global external system updated successfully');
      });
    });

    // GET SYSTEM MAPPERS
    builder.addCase(getExternalSystemMapperList.pending, state => {
      state.systemMapperListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getExternalSystemMapperList.rejected, (state, action) => {
      state.systemMapperListFetchStatus = FETCH_STATUS.REJECTED;
      state.systemMapperList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getExternalSystemMapperList.fulfilled, (state, action) => {
      state.systemMapperListFetchStatus = FETCH_STATUS.FULFILLED;
      state.systemMapperList = action.payload;
    });

    // GET EXTERNAL SYSTEM PROVIDERS
    builder.addCase(getExternalSystemsProviders.pending, state => {
      state.externalSystemProvidersListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getExternalSystemsProviders.rejected, (state, action) => {
      state.externalSystemProvidersListFetchStatus = FETCH_STATUS.REJECTED;
      state.externalSystemProvidersList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getExternalSystemsProviders.fulfilled, (state, action) => {
      state.externalSystemProvidersListFetchStatus = FETCH_STATUS.FULFILLED;
      state.externalSystemProvidersList = action.payload;
    });

    // GET EXTERNAL SYSTEM PBX
    builder.addCase(getExternalSystemsPBX.pending, state => {
      state.externalSystemPBXListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getExternalSystemsPBX.rejected, (state, action) => {
      state.externalSystemPBXListFetchStatus = FETCH_STATUS.REJECTED;
      state.externalSystemPBXList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getExternalSystemsPBX.fulfilled, (state, action) => {
      state.externalSystemPBXListFetchStatus = FETCH_STATUS.FULFILLED;
      state.externalSystemPBXList = action.payload;
    });

    // POST ROUTING CONFIG
    builder.addCase(postRouteConfig.pending, state => {
      state.routingConfigPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postRouteConfig.rejected, (state, action) => {
      state.routingConfigPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postRouteConfig.fulfilled, state => {
      state.routingConfigPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Routing config created successfully');
      });
    });

    // DELETE ROUTING CONFIG
    builder.addCase(deleteRouteConfig.pending, state => {
      state.routingConfigDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteRouteConfig.rejected, (state, action) => {
      state.routingConfigDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteRouteConfig.fulfilled, state => {
      state.routingConfigDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Routing config deleted successfully');
      });
    });

    // PATCH ROUTING CONFIG STATUS
    builder.addCase(patchRouteConfig.pending, state => {
      state.routingConfigPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchRouteConfig.rejected, (state, action) => {
      state.routingConfigPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchRouteConfig.fulfilled, state => {
      state.routingConfigPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Routing config status updated successfully');
      });
    });

    // GET PBX DOMAINS
    builder.addCase(getPBXDomainsList.pending, state => {
      state.pbxDomainsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPBXDomainsList.rejected, (state, action) => {
      state.pbxDomainsListFetchStatus = FETCH_STATUS.REJECTED;
      state.pbxDomainsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getPBXDomainsList.fulfilled, (state, action) => {
      state.pbxDomainsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.pbxDomainsList = action.payload;
    });

    // POST PBX DOMAIN
    builder.addCase(postPBXDomains.pending, state => {
      state.pbxDomainsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postPBXDomains.rejected, (state, action) => {
      state.pbxDomainsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postPBXDomains.fulfilled, state => {
      state.pbxDomainsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('PBX domains imported successfully');
      });
    });
  },
});

export const {
  setExternalSystemDetailsPostStatus,
  setExternalSystemDetailsDeleteStatus,
  setExternalSystemPBXListFetchStatus,
  setExternalSystemProvidersList,
  setExternalSystemProvidersListFetchStatus,
  setExternalSystemPBXList,
  setExternalSystemsListFetchStatus,
  setGlobalExternalSystemsList,
  setRoutingConfigPostStatus,
  setGlobalExternalSystemsListFetchStatus,
  setRoutingConfigDeleteStatus,
  setRoutingConfigPatchStatus,
  setSystemMapperListFetchStatus,
  setSystemMapperList,
  setExternalSystemsList,
  setExternalSystemDetailsPatchStatus,
  setGlobalExternalSystemDetailsPatchStatus,
  setPbxDomainsListFetchStatus,
  setPbxDomainsList,
  setPbxDomainsPostStatus,
  setExternalSystemInstanceDetailsFetchStatus,
  setExternalSystemInstanceDetails,
} = externalSystemsSlice.actions;

export default externalSystemsSlice.reducer;
