import {
  FETCH_SMS_LOGGER_DEVICE_NAMES_STATUS,
  FETCH_SMS_LOGGER_LIST_STATUS,
  FETCH_SMS_LOGGER_SUMMARY_STATUS,
  FETCH_SMS_SIM_DETAILS_STATUS,
  FETCH_SMS_SIMS_LIST_STATUS,
  SET_FULL_SMS_PLANS_FETCH_STATUS,
  SET_FULL_SMS_PLANS_LIST,
  SET_ROUTE_PARAMS,
  SET_SMS_LOGGER_ACCESS_TOKEN,
  SET_SMS_LOGGER_DEVICE_NAMES,
  SET_SMS_LOGGER_LIST,
  SET_SMS_LOGGER_SUMMARY,
  SET_SMS_SIM_DETAILS,
  SET_SMS_SIMS_LIST,
} from '../constants';

export const smsLoggerInitialRouteParams = {
  page: 1,
  limit: 10,
  order: 'obj.id',
  q: '',
  status: 'all',
  active: 'all',
  hr_test: 'all',
  device: '',
};

export const smsSimsInitialRouteParams = {
  page: 1,
  limit: 10,
  order: 'obj.id',
  q: '',
};

const INIT_STATE = {
  smsLoggerList: [],
  smsLoggerListFetchStatus: null,
  smsSIMsList: [],
  smsSIMsListFetchStatus: null,
  smsLoggerSummary: [],
  smsLoggerSummaryFetchStatus: null,
  smsLoggerDeviceNames: [],
  smsLoggerDeviceNamesFetchStatus: null,
  smsFullPlanList: [],
  smsFullPlanListFetchStatus: null,
  routeParams: {
    smsLogger: smsLoggerInitialRouteParams,
    smsSims: smsSimsInitialRouteParams,
  },
  accessToken: null,
  smsSimDetails: null,
  smsSimDetailsFetchStatus: null,
};

export const smsLoggerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SMS_LOGGER_LIST:
      return { ...state, smsLoggerList: action.payload };
    case FETCH_SMS_LOGGER_LIST_STATUS:
      return { ...state, smsLoggerListFetchStatus: action.payload };
    case SET_FULL_SMS_PLANS_LIST:
      return { ...state, smsFullPlanList: action.payload };
    case SET_FULL_SMS_PLANS_FETCH_STATUS:
      return { ...state, smsFullPlanListFetchStatus: action.payload };
    case SET_SMS_SIMS_LIST:
      return { ...state, smsSIMsList: action.payload };
    case FETCH_SMS_SIMS_LIST_STATUS:
      return { ...state, smsSIMsListFetchStatus: action.payload };
    case SET_SMS_LOGGER_SUMMARY:
      return { ...state, smsLoggerSummary: action.payload };
    case FETCH_SMS_LOGGER_SUMMARY_STATUS:
      return { ...state, smsLoggerSummaryFetchStatus: action.payload };
    case SET_SMS_LOGGER_DEVICE_NAMES:
      return { ...state, smsLoggerDeviceNames: action.payload };
    case FETCH_SMS_LOGGER_DEVICE_NAMES_STATUS:
      return { ...state, smsLoggerDeviceNamesFetchStatus: action.payload };
    case SET_SMS_SIM_DETAILS:
      return { ...state, smsSimDetails: action.payload };
    case FETCH_SMS_SIM_DETAILS_STATUS:
      return { ...state, smsSimDetailsFetchStatus: action.payload };
    case SET_SMS_LOGGER_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'smsLogger') {
        return {
          ...state,
          routeParams: { ...state.routeParams, smsLogger: { ...state.routeParams.smsLogger, ...action.payload.data } },
        };
      }
      if (action.payload.reducer === 'smsSims') {
        return {
          ...state,
          routeParams: { ...state.routeParams, smsSims: { ...state.routeParams.smsSims, ...action.payload.data } },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
