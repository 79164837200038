import {
  FETCH_ATA_TYPES_LIST_STATUS,
  FETCH_ATA_TYPES_DETAILS_STATUS,
  SET_ATA_TYPES_LIST,
  SET_ATA_TYPES_DETAILS,
  SET_ROUTE_PARAMS,
} from '../constants';

export const initialATATypesRouteParams = {
  page: 1,
  limit: 10,
  order: '-ata_type.id',
  q: '',
};

const INIT_STATE = {
  ataTypesList: [],
  ataTypesListFetchStatus: null,
  ataTypesDetails: null,
  ataTypesDetailsFetchStatus: null,
  routeParams: initialATATypesRouteParams,
};

export const ataTypesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ATA_TYPES_LIST:
      return { ...state, ataTypesList: action.payload };
    case FETCH_ATA_TYPES_LIST_STATUS:
      return { ...state, ataTypesListFetchStatus: action.payload };
    case SET_ATA_TYPES_DETAILS:
      return { ...state, ataTypesDetails: action.payload };
    case FETCH_ATA_TYPES_DETAILS_STATUS:
      return { ...state, ataTypesDetailsFetchStatus: action.payload };

    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'ataTypes') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };

    default:
      return state;
  }
};
