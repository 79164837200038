import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import reducers from './Reducers';
import { persistReducer, persistStore } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';

const saveSharedFilters = createFilter('sharedReducer', [
  'selectedCompany',
  'includeSuborgs',
  'selectedLocation',
  'selectedCountry',
  'selectedUserAccess',
]);

const persistConfig = {
  key: 'shared',
  storage: storage,
  whitelist: ['sharedReducer'],
  transforms: [saveSharedFilters],
};

const pReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: pReducer,
  devTools: false,
  middleware: [thunkMiddleware],
});
export const persistor = persistStore(store);

export type DispatchAction<T> = {
  type: string;
  payload: T;
};
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
