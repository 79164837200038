import { createAsyncThunk } from '@reduxjs/toolkit';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PBXType } from '../../types/pbx/pbx.type';
import { BuyDIDsDto, CreateBYODIDDto } from '../../types/did/did.type';
import { changeModalState, setModalContent } from '../../redux/utils/utils.action';

export const getStatesList = createAsyncThunk<
  Array<{ name: string; options: Array<{ name: string; id: string | number }> }>,
  { telco: number },
  { state: RootState }
>('didManager/getStatesList', async ({ telco }, thunkAPI) => {
  try {
    const response = await axios.get<Array<unknown>>(
      `${process.env.REACT_APP_API_URL}/did-manager/states?telco=${telco}`
    );

    const list: Array<{ name: string; options: Array<{ name: string; id: string | number }> }> = [];
    Object.keys(response.data).forEach(country => {
      list.push({
        name: country,
        options: Object.keys(response.data[country]).map((key: string) => ({
          name: response.data[country][key],
          id: key,
        })),
      });
    });

    return list;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching states list!');
  }
});

export const getRateCentersList = createAsyncThunk<
  Array<{ name: string; id: string }>,
  { telco: number; state: string },
  { state: RootState }
>('didManager/getRateCentersList', async ({ telco, state }, thunkAPI) => {
  try {
    const response = await axios.get<Array<string>>(
      `${process.env.REACT_APP_API_URL}/did-manager/rateCenters?telco=${telco}&state=${state}`
    );
    return response.data.map(v => ({ name: v, id: v }));
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching rate centers list!');
  }
});

export const getAvailableDIDList = createAsyncThunk<Array<{ did: string }>, RouteParamsType, { state: RootState }>(
  'didManager/getAvailableDIDList',
  async (params, thunkAPI) => {
    try {
      const mergedParams = { ...params };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<Array<{ did: string }>>(
        `${process.env.REACT_APP_API_URL}/did-manager/available?${queryString}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting available DIDs list!');
    }
  }
);

export const getActiveDIDList = createAsyncThunk<Array<{ did: string }>, RouteParamsType, { state: RootState }>(
  'didManager/getActiveDIDList',
  async (params, thunkAPI) => {
    try {
      const mergedParams = { ...params };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<Array<{ did: string }>>(
        `${process.env.REACT_APP_API_URL}/did-manager/active?${queryString}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting available DIDs list!');
    }
  }
);

export const getAvailablePBXs = createAsyncThunk<
  Array<
    Pick<PBXType, 'id' | 'description' | 'sip_server1'> & {
      system_pbx_location: { id: number };
      telco_instance: { id: number };
      pbx_system_instance: { id: number };
      default?: boolean;
    }
  >,
  RouteParamsType,
  { state: RootState }
>('didManager/getAvailablePBXs', async (params, thunkAPI) => {
  try {
    const mergedParams = { ...params };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<
      Array<
        Pick<PBXType, 'id' | 'description' | 'sip_server1'> & {
          system_pbx_location: { id: number };
          telco_instance: { id: number };
          pbx_system_instance: { id: number };
          default?: boolean;
        }
      >
    >(`${process.env.REACT_APP_API_URL}/did-manager/getPbxs?${queryString}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting available PBXs list!');
  }
});

export const postBuyDIDs = createAsyncThunk<{ success: boolean }, { data: BuyDIDsDto }, { state: RootState }>(
  'didManager/postBuyDIDs',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<{ success: boolean }>(`${process.env.REACT_APP_API_URL}/did-manager/buy`, data);
      thunkAPI.dispatch(changeModalState(false));
      thunkAPI.dispatch(setModalContent(null));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during buying DIDs!');
    }
  }
);

export const postBYOPBX = createAsyncThunk<{ success: boolean }, { data: CreateBYODIDDto }, { state: RootState }>(
  'didManager/postBYOPBX',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<{ success: boolean }>(`${process.env.REACT_APP_API_URL}/did-manager/byo`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating BYO PBX!');
    }
  }
);
