import {
  FETCH_SERVICES_DETAILS_STATUS,
  FETCH_SERVICES_LIST_STATUS,
  SET_SERVICES_DETAILS,
  SET_SERVICES_LIST,
  SET_ROUTE_PARAMS,
  SET_CHECK_REVIO_SERVICE_DATA,
} from '../constants';

export const initialServicesRouteParams = {
  page: 1,
  limit: 10,
  order: '-tc_service.id',
  service_type_id: '',
  is_active: 'true',
  q: '',
  category: '',
};

const INIT_STATE = {
  servicesList: [],
  servicesListFetchStatus: null,
  servicesDetails: null,
  servicesDetailsFetchStatus: null,
  revIoServiceStatus: null,
  routeParams: initialServicesRouteParams,
};

export const servicesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SERVICES_LIST:
      return { ...state, servicesList: action.payload };
    case FETCH_SERVICES_LIST_STATUS:
      return { ...state, servicesListFetchStatus: action.payload };
    case SET_SERVICES_DETAILS:
      return { ...state, servicesDetails: action.payload };
    case FETCH_SERVICES_DETAILS_STATUS:
      return { ...state, servicesDetailsFetchStatus: action.payload };
    case SET_CHECK_REVIO_SERVICE_DATA:
      return { ...state, revIoServiceStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'services') {
        return {
          ...state,
          routeParams: { ...state.routeParams, ...action.payload.data },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
