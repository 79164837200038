import { SET_PAYMENT_METHOD_LIST, FETCH_PAYMENT_METHOD_LIST_STATUS } from '../constants';

// export const initialRouteParams = {
//   page: 1,
//   limit: 10,
//   order: 'user.lastname',
//   q: "",
// };

const INIT_STATE = {
  paymentMethodList: [],
  paymentMethodListFetchStatus: null,
};

export const paymentMethodsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAYMENT_METHOD_LIST:
      return { ...state, paymentMethodList: action.payload };
    case FETCH_PAYMENT_METHOD_LIST_STATUS:
      return { ...state, paymentMethodListFetchStatus: action.payload };
    default:
      return state;
  }
};
