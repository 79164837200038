import { POST_VERIFIED_NUMBER_DETAILS_STATUS, SET_VERIFIED_NUMBER_DETAILS } from '../constants';

const INIT_STATE = {
  verifiedNumberPostStatus: null,
  verifiedNumberDetails: null,
};

export const verificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_VERIFIED_NUMBER_DETAILS_STATUS:
      return { ...state, verifiedNumberPostStatus: action.payload };
    case SET_VERIFIED_NUMBER_DETAILS:
      return { ...state, verifiedNumberDetails: action.payload };
    default:
      return state;
  }
};
