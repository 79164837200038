import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import { additionalQueryParams } from '../../utils/utilities';
import { changeModalState, setModalContent } from '../../redux/utils/utils.action';
import {
  BatteryDetailsType,
  BatteryListItemType,
  BatteryType,
  CreateBatteryDto,
  UpdateBatteryDto,
} from '../../types/battery/battery.type';
import { WarehouseLocationType } from '../../types/warehouse/warehouse-location/warehouse-location.type';
import { AsyncTaskType } from '../../types/async-task/async-task.type';

export const postBatteries = createAsyncThunk<
  AsyncTaskType,
  {
    data: {
      warehouse: { id: number };
      equipment_group: { id: number };
      batteries: Array<{
        brand: string;
        description: string;
        model: string;
        serial: string;
        is_inventory: boolean;
        warehouse_location?: { rack: string; shelf: string };
      }>;
    };
  },
  { state: RootState }
>('batteries/postBatteries', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<AsyncTaskType>(`${process.env.REACT_APP_API_URL}/battery/bulk`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating batteries!');
  }
});

export const postBatteryDetails = createAsyncThunk<
  Pick<BatteryType, 'brand' | 'description' | 'id' | 'is_inventory' | 'model' | 'serial'> & {
    warehouse_location: Pick<WarehouseLocationType, 'id' | 'rack' | 'shelf'> & { warehouse: { id: number } };
  },
  { data: CreateBatteryDto },
  { state: RootState }
>('batteries/postBatteryDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<
      Pick<BatteryType, 'brand' | 'description' | 'id' | 'is_inventory' | 'model' | 'serial'> & {
        warehouse_location: Pick<WarehouseLocationType, 'id' | 'rack' | 'shelf'> & { warehouse: { id: number } };
      }
    >(`${process.env.REACT_APP_API_URL}/battery`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating battery!');
  }
});

export const getBatteriesList = createAsyncThunk<
  PaginationType<BatteryListItemType>,
  RouteParamsType,
  { state: RootState }
>('batteries/getBatteriesList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<BatteryListItemType>>(
      `${process.env.REACT_APP_API_URL}/battery${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching batteries!');
  }
});

export const getBatteryDetails = createAsyncThunk<BatteryDetailsType, { id: number }, { state: RootState }>(
  'batteries/getBatteryDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<BatteryDetailsType>(`${process.env.REACT_APP_API_URL}/battery/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching battery!');
    }
  }
);

export const patchBatteryDetails = createAsyncThunk<
  Pick<BatteryType, 'brand' | 'description' | 'id' | 'is_inventory' | 'model' | 'serial'> & {
    warehouse_location: Pick<WarehouseLocationType, 'id' | 'rack' | 'shelf'> & { warehouse: { id: number } };
  },
  { id: number; data: UpdateBatteryDto },
  { state: RootState }
>('batteries/patchBatteryDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<
      Pick<BatteryType, 'brand' | 'description' | 'id' | 'is_inventory' | 'model' | 'serial'> & {
        warehouse_location: Pick<WarehouseLocationType, 'id' | 'rack' | 'shelf'> & { warehouse: { id: number } };
      }
    >(`${process.env.REACT_APP_API_URL}/battery/${id}`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating battery!');
  }
});

export const deleteBatteryDetails = createAsyncThunk<
  Pick<
    BatteryType,
    | 'brand'
    | 'created_at'
    | 'description'
    | 'equipment_group'
    | 'hash'
    | 'is_inventory'
    | 'model'
    | 'modified_at'
    | 'serial'
    | 'warehouse_location'
  >,
  { id: number; fetchList: boolean },
  { state: RootState }
>('batteries/deleteBatteryDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<
      Pick<
        BatteryType,
        | 'brand'
        | 'created_at'
        | 'description'
        | 'equipment_group'
        | 'hash'
        | 'is_inventory'
        | 'model'
        | 'modified_at'
        | 'serial'
        | 'warehouse_location'
      >
    >(`${process.env.REACT_APP_API_URL}/battery/${id}`);
    if (fetchList) thunkAPI.dispatch(getBatteriesList(thunkAPI.getState().batteriesReducer.batteriesRouteParams));
    thunkAPI.dispatch(changeModalState(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting battery!');
  }
});
