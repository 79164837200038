import { SET_MODAL_CONTENT, SHOW_MODAL } from '../constants';

const INIT_STATE = {
  showModal: false,
  modalContent: null,
};

export const utilsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return { ...state, showModal: action.payload };
    case SET_MODAL_CONTENT:
      return { ...state, modalContent: action.payload };
    default:
      return state;
  }
};
